.table-view .tabbed-picker {
  display: flex;
  width: 100%;
  > .column {
    flex: 1;
    text-align: center;
    padding: @5px @5px;
    border-width: @1px;
    border-style: solid;
    cursor: pointer;
    font-size: @18px;
    .theme-colours(border-color);
    .theme-colours(color);
    + .column {
      margin-left: -@1px;
    }
    &:first-child {
      border-radius: @3px 0 0 @3px;
    }
    &:last-child {
      border-radius: 0 @3px @3px 0;
      //padding-right: @1px;
    }
    &.active,
    &:hover {
      .theme-colours(background-color) !important;
      .theme-light & {
        color: @white-1;
      }
    }
  }
}

.activation-group {
  display: flex;
  justify-content: center;
  width: 100%;
  .h1;
  text-align: center;
  font-size: @26px;

  .column:not(label) {
    .h1;
    height: @60px;
    line-height: @60px;
    width: @25px;
    text-align: center;
    padding: 0 !important;
    @media (min-width: 1281px) {
      font-size: @51px;
      height: @90px;
      line-height: @90px;
      width: @37px;
    }
  }
  label {
    padding: 0 !important;
    flex: 0;
    box-shadow: inset 0 0 0 @1px @grey-3;
    position: relative;
    z-index: 0;
    margin: 0 -@1px 0 0;
    &:first-child {
      margin-left: 0;
    }
    /*&:nth-child(3) {
      &:after {
        content: "-";
      }
    }*/
    &:first-child,
    &:nth-child(5) {
      border-radius: @5px 0 0 @5px;
    }
    &:last-child,
    &:nth-child(3) {
      border-radius: 0 @5px @5px 0;
    }
    &.current-focus {
      z-index: 1;
      .theme-colour-blue & {
        box-shadow: inset 0 0 0 @1px @blue;
      }
      .theme-colour-green & {
        box-shadow: inset 0 0 0 @1px @green;
      }
      .theme-colour-red & {
        box-shadow: inset 0 0 0 @1px @red;
      }
      .theme-colour-orange & {
        box-shadow: inset 0 0 0 @1px @orange;
      }
      .theme-colour-yellow & {
        box-shadow: inset 0 0 0 @1px @yellow;
      }
    }
  }
}

.radio-button {
  min-height: @44px;
  width: @44px;
  padding: 0 !important;
  align-items: center;
  justify-content: center;

  //quick override for the theme style buttons
  #view-user-preferences & {
    //default opacity, overridden with active class
    opacity: 0.6;
    .fill {
      width: 100%;
      height: 100%;
    }
    &.active {
      opacity: 1;
    }
  }

  .border {
    display: flex;
    width: @26px;
    height: @26px;
    margin: 0 auto;
    border-radius: 999px;
    border-width: @2px;
    border-color: @grey-3;
    border-style: solid;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    @media (min-width: 1025px) {
      width: 32px;
      height: 32px;
      border-width: 3px;
    }
  }
  .fill {
    width: @14px;
    height: @14px;
    border-radius: 999px;
    .theme-colours(background-color);
    @media (min-width: 1025px) {
      width: 18px;
      height: 18px;
    }
  }
  &.blue,
  &.orange,
  &.red {
    .border {
      background-color: @white-1;
    }
  }
  &.green,
  &.yellow {
    .border {
      background-color: @grey-5;
      border-color: @grey-1;
    }
  }
  &.blue {
    .fill {
      background-color: @blue;
    }
    &.active {
      .border {
        border-color: @blue;
      }
    }
  }
  &.green {
    .fill {
      background-color: @green;
    }
    &.active {
      .border {
        border-color: @green;
      }
    }
  }
  &.red {
    .fill {
      background-color: @red;
    }
    &.active {
      .border {
        border-color: @red;
      }
    }
  }
  &.orange {
    .fill {
      background-color: @orange;
    }
    &.active {
      .border {
        border-color: @orange;
      }
    }
  }
  &.yellow {
    .fill {
      background-color: @yellow;
    }
    &.active {
      .border {
        border-color: @yellow;
      }
    }
  }
  &.dark__green {
    .fill {
      background-image: linear-gradient(125deg, @grey-5 50%, @green 50%);
    }
  }

  &.dark__yellow {
    .fill {
      background-image: linear-gradient(125deg, @grey-5 50%, @yellow 50%);
    }
  }

  &.dark__blue {
    .fill {
      background-image: linear-gradient(125deg, @grey-5 50%, @blue 50%);
    }
  }
  &.dark__red {
    .fill {
      background-image: linear-gradient(125deg, @grey-5 50%, @red 50%);
    }
  }
  &.dark__orange {
    .fill {
      background-image: linear-gradient(125deg, @grey-5 50%, @orange 50%);
    }
  }
  &.light__blue {
    .fill {
      background-image: linear-gradient(125deg, @white-1 50%, @blue 50%);
    }
  }
  &.light__red {
    .fill {
      background-image: linear-gradient(125deg, @white-1 50%, @red 50%);
    }
  }
  &.light__orange {
    .fill {
      background-image: linear-gradient(125deg, @white-1 50%, @orange 50%);
    }
  }
}
.switch {
  position: relative;
  z-index: 1;
  display: inline-block;
  //width: @51px;
  //height: @31px;
  width: 60px;
  height: @30px;
  background: none !important;
  input {
    display: none;
    &:checked + .slider {
      background-color: @ios-green;
      border-color: @ios-green;
      &:before {
        transform: translateX(30px);
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @grey-4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-style: solid;
    //border-width: @1px;
    border-width: 1px;
    border-color: @grey-4;
    box-sizing: border-box;
    &:before {
      position: absolute;
      content: '';
      height: @30px;
      width: @30px;
      //height: 36px;
      //width: 36px;
      top: -@1px;
      left: -@1px;
      bottom: 0;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      box-shadow: 0 @2px @4px rgba(0, 0, 0, 0.2);
    }
    &.round {
      border-radius: 999px;
      &:before {
        border-radius: 999px;
      }
    }
  }
}
.cta-button {
  display: block;
  font-size: @21px;
  font-weight: 500;
  .px(padding;17px;20px;19px);
  width: 86%;
  max-width: @400px;
  margin: @19px auto 0;
  .px(margin;22px;auto;0);
  border-radius: 999px;
  box-sizing: border-box;
  border-style: solid;
  border-width: @1point5px;
  color: @grey-1;
  .theme-colours(background-color);
  .theme-colours(border-color);
  transition: background-color 200ms ease, color 200ms ease;
  // .theme-light & {
  //   color: @white-1;
  // }
  @media screen and (max-width: 380px) {
    width: calc(100% - (@10px * 2));
  }
  &:hover:not(.disabled) {
    background-color: transparent;
    .theme-colours(color);
  }
  &.text-button {
    margin: 0;
    &.column {
      padding: @17px 0 @19px;
    }
  }

  &.danger {
    .theme-dark & {
      background-color: @red;
      border-color: @red;
      color: @white-1 !important;
      &:hover:not(.disabled) {
        background-color: transparent;
        color: @red;
      }
    }
  }
}

.icn-button-med,
.icn-button-lrg {
  + .column {
    display: flex;
    align-items: center;
  }
}

.icn-button {
  &.highlight {
    .label {
      .theme-colours(color);
    }
  }
}
//Icon Button hover effect

@media (hover: hover) {
  .icn-button:not(.icn-button-lrg) {
    &:before {
      #login-list &,
      #groups-list &,
      #pane-2 .section-pane:not(#view-profile-account, .suggested-add-button) & {
        content: '';
        display: block;
        .px(width,72px);
        .px(height,72px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        background: @grey-11;
        border-radius: 999px;
        opacity: 0;
        transition: transform 150ms ease~ ',' opacity 150ms ease;
        pointer-events: none;
        .theme-light & {
          background: darken(@grey-3, 3%);
        }
      }

      #login-list .has-label&,
      #groups-list .has-label&,
      #pane-2 .section-pane:not(#view-profile-account) .has-label& {
        top: calc(50% + @1px);
      }
      #login-list .login-item-inner & {
        .px(width,60px);
        .px(height,60px);
        @media (max-width: 1024px) {
          .px(width,44px);
          .px(height,44px);
        }
      }
      .cta &,
      .loading-password & {
        display: none !important;
      }
    }
    &:hover:before {
      #login-list &,
      #groups-list &,
      #pane-2 .section-pane:not(#view-profile-account, #suggested-add-button) & {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    * {
      position: relative;
    }

    .icon-accessory-dot {
      width: @8px;
      height: @8px;
      background-color: @red;
      border-radius: 50%;
      position: absolute;
      top: @8px;
      right: @8px;
    }
  }
}
