button {
  border: 0;
  background-color: transparent;
  text-align: center;
  outline: 0;
  .loading-password & {
    cursor: default;
    pointer-events: none;
  }
}
.icn-button {
  //width: @44px;
  //height: @44px;
  position: relative;
  text-align: center;
  transition: transform 300ms ease;
  &.nav-link {
    width: auto;
  }
  &.icn-button-has-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &.column {
    .px(min-height,59px);
    .px(max-height,70px);
    &.icn-button-xlrg {
      max-height: none;
    }
  }
  .column-group.button-group & {
    margin: 0;
    width: auto;
    height: auto;
    padding: 0 @18px;
  }
  .f-icn + .label {
    margin-top: -0.5em;
    margin-bottom: 1em;
  }
}
.icn-button-label {
  display: block;
  font-size: @10px;
  line-height: @13px;
}
.f-icn {
  //position: absolute;
  //top: @6px;
  //right: @6px;
  display: inline-block;
  vertical-align: middle;
  width: @32px;
  height: @32px;
  padding: @6px;
  line-height: @32px;
  background-size: @32px @32px;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  .icn-button-sm & {
    width: @45px;
    height: @45px;
    line-height: @45px;
    background-size: @45px @45px;
    padding: 0;
  }

  .icn-button-med & {
    width: @60px;
    height: @60px;
    line-height: @60px;
    background-size: @60px @60px;
    padding: 0;
  }

  .icn-button-lrg & {
    width: @80px;
    height: @80px;
    line-height: @80px;
    background-size: @80px @80px;
    padding: 0;
  }
  .icn-button-xlrg & {
    width: @128px;
    height: @128px;
    line-height: @128px;
    background-size: @128px @128px;
    padding: 0;
  }
}
.text-button {
  font-size: @19px;
  line-height: @24px;
  min-width: @44px;
  min-height: @44px;
}

//Light/Dark icons
.background-image(@image,@colour:false) {
  .theme-light & {
    background-image: url('../img/icons/colour-1/forghetti-ui_@{image}_normal.svg');
  }
  .theme-dark & {
    background-image: url('../img/icons/colour-2/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-blue &.f-icn-colour when (@colour = true) {
    background-image: url('../img/icons/colour-4/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-green &.f-icn-colour when (@colour = true) {
    background-image: url('../img/icons/colour-5/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-red &.f-icn-colour when (@colour = true) {
    background-image: url('../img/icons/colour-6/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-orange &.f-icn-colour when (@colour = true) {
    background-image: url('../img/icons/colour-7/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-yellow &.f-icn-colour when (@colour = true) {
    background-image: url('../img/icons/colour-8/forghetti-ui_@{image}_normal.svg');
  }
}
.background-image-reverse(@image) {
  .theme-light & {
    background-image: url('../img/icons/colour-2/forghetti-ui_@{image}_normal.svg');
  }
  .theme-dark & {
    background-image: url('../img/icons/colour-1/forghetti-ui_@{image}_normal.svg');
  }
}
.background-image-dark(@image) {
  background-image: url('../img/icons/colour-1/forghetti-ui_@{image}_normal.svg');
}
.background-image-light(@image) {
  background-image: url('../img/icons/colour-2/forghetti-ui_@{image}_normal.svg');
}
.f-icn-add {
  .background-image(add);
}
.f-icn-add-member {
  .background-image(add-member);
}
.f-icn-add-solid {
  .background-image(add-solid);
}
.f-icn-alarm {
  .background-image(alarm);
}
.f-icn-authentication {
  .background-image(authentication);
}
.f-icn-cancel {
  .background-image(cancel);
}
.f-icn-cancel-solid {
  .background-image(cancel-solid);
}
.f-icn-change-number {
  .background-image(change-number);
}
.f-icn-check {
  .background-image(check);
}
.f-icn-check-circle {
  .background-image(check-circle);
}
.f-icn-check-solid {
  .background-image(check-solid);
}
.f-icn-chevron-down {
  .background-image(chevron-down);
}
.f-icn-chevron-left {
  .background-image(chevron-left);
}
.f-icn-chevron-right {
  background-image: url('../img/icons/colour-2/forghetti-ui_chevron-right_normal.svg');
}
.f-icn-chevron-up {
  .background-image(chevron-up);
}
.f-icn-connect-to-pc {
  .background-image(connect-to-pc);
}
.f-icn-contact {
  .background-image(contact);
}
.f-icn-copy {
  .background-image(copy);
}
.f-icn-delete {
  .background-image(delete);
}
.f-icn-delete-account {
  .background-image(delete-account);
}
.f-icn-dialpad {
  .background-image(dialpad);
}
.f-icn-doodlepad {
  .background-image(doodlepad);
}
.f-icn-doodlepad-drawn {
  .background-image(doodlepad-drawn);
}
.f-icn-dots {
  .background-image(dots);
}
.f-icn-down {
  .background-image(down);
}
.f-icn-down-solid {
  .background-image(down-solid);
}
.f-icn-email {
  .background-image(email);
}
.f-icn-exit {
  .background-image(exit);
}
.f-icn-fingerprint {
  .background-image(fingerprint);
}
.f-icn-fingerprint-colour {
  .background-image-colour(fingerprint);
}
.f-icn-folder {
  .background-image(folder);
}
.f-icn-forghetti-icon {
  .background-image(forghetti-icon);
}
.f-icn-forghetticon-peaceful {
  .background-image(forghetticon-peaceful);
}
.f-icn-group-share {
  .background-image(group-share);
}
.f-icn-healthcheck {
  .background-image(healthcheck);
}
.f-icn-heart {
  .background-image(heart);
}

.f-icn-help {
  .background-image(help);
}
.f-icn-help-solid {
  .background-image(help-solid);
}
.f-icn-hide {
  .background-image(hide);
}
.f-icn-hide-numbers {
  .background-image(hide-numbers);
}
.f-icn-home {
  .background-image(home);
}
.f-icn-image {
  .background-image(image);
}
.f-icn-left {
  .background-image(image);
}
.f-icn-left-solid {
  .background-image(left-solid);
}
.f-icn-lock {
  .background-image(lock);
}
.f-icn-lock-plus {
  .background-image(lock-plus);
}
.f-icn-members {
  .background-image(members);
}
.f-icn-mobile {
  .background-image(mobile);
}
.f-icn-open-browser {
  .background-image(open-browser);
}
.f-icn-password {
  .background-image(password);
}
.f-icn-password-colour {
  .background-image-colour(password);
}

.f-icn-pc {
  .background-image-colour(pc);
}
.f-icn-phone {
  .background-image(phone);
}
.f-icn-profile {
  .background-image(profile);
}

.f-icn-reset {
  .background-image(reset);
}
.f-icn-reset-colour {
  .background-image-colour(reset);
}

.f-icn-restore {
  .background-image(restore);
}
.f-icn-right {
  .background-image(right);
}
.f-icn-right-solid {
  .background-image(right-solid);
}
.f-icn-right-colour {
  .background-image-colour(right);
}
.f-icn-search {
  .background-image(search);
}
.f-icn-security {
  .background-image(security);
}
.f-icn-settings {
  .background-image(settings);
}
.f-icn-sharing {
  .background-image(sharing);
}
.f-icn-show {
  .background-image(show);
}
.f-icn-show-numbers {
  .background-image(show-numbers);
}
.f-icn-success {
  .background-image(success);
}
.f-icn-swipe {
  .background-image(swipe);
}
.f-icn-synchronising {
  .background-image(synchronising);
}
.f-icn-synchronising-solid {
  .background-image(synchronising-solid);
}
.f-icn-up {
  .background-image(up);
}
.f-icn-up-solid {
  .background-image(up-solid);
}
.f-icn-web {
  .background-image(web);
}
.f-icn-switch {
  .background-image(switch);
}
.f-icn-switch-colour {
  .background-image-colour(switch);
}
.f-icn-edit {
  .background-image(edit);
}
.f-icn-shield {
  .background-image(shield);
}
.f-icn-right-rotate {
  .background-image(rotate_right);
}
.f-icn-enable-biometrics {
  .background-image(enable-biometrics);
}
.f-icn-move-service-blank {
  .background-image(move-service-blank,true);
}
//Colour theme icons
.background-image-colour(@image) {
  .theme-colour-blue & {
    background-image: url('../img/icons/colour-4/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-green & {
    background-image: url('../img/icons/colour-5/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-red & {
    background-image: url('../img/icons/colour-6/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-orange & {
    background-image: url('../img/icons/colour-7/forghetti-ui_@{image}_normal.svg');
  }
  .theme-colour-yellow & {
    background-image: url('../img/icons/colour-8/forghetti-ui_@{image}_normal.svg');
  }
}
.background-image-red(@image) {
  .theme-colour-blue &,
  .theme-colour-green &,
  .theme-colour-red &,
  .theme-colour-orange &,
  .theme-colour-yellow & {
    background-image: url('../img/icons/colour-10/forghetti-ui_@{image}_normal.svg');
  }
  .theme-light .red .column:hover .icn-button & {
    background-image: url('../img/icons/colour-3/forghetti-ui_@{image}_normal.svg');
  }
}
.cta-hover(@image) {
  .cta:hover .icn-button & {
    .theme-colour-blue &,
    .theme-colour-red &,
    .theme-colour-orange & {
      background-image: url('../img/icons/colour-3/forghetti-ui_@{image}_normal.svg');
    }
    .theme-dark & {
      .theme-colour-green&,
      .theme-colour-yellow& {
        background-image: url('../img/icons/colour-1/forghetti-ui_@{image}_normal.svg');
      }
    }
  }
}

.f-icn-cancel-notification {
  .background-image-colour(cancel);
}
.f-icn-add {
  .background-image-colour(add);
  .cta-hover(add);
}
.f-icn-reset {
  .background-image-colour(reset);
  .cta-hover(reset);
}
.f-icn-restore {
  .background-image-colour(restore);
  .cta-hover(restore);
}
.f-icn-delete {
  .background-image-red(delete);
  .cta:hover .icn-button & {
    background-image: url('../img/icons/colour-3/forghetti-ui_delete_normal.svg');
  }
}
.f-icn-check-solid {
  .background-image-colour(check-solid);
}
.f-icn-uncheck-solid {
  .background-image-colour(unchecked-circle);
}
.f-icn-forghetti-icon {
  .background-image-colour(forghetti-icon);
}
.background-image-white(@image) {
  .theme-light & {
    background-image: url('../img/icons/colour-3/forghetti-ui_@{image}_normal.svg');
  }
  .theme-dark & {
    background-image: url('../img/icons/colour-3/forghetti-ui_@{image}_normal.svg');
  }
}

.f-icn-unchecked-circle {
  border-radius: 50%;
  display: inline-block;
  margin: 1rem;
  height: @16px;
  width: @16px;
  margin: auto;
  border: 1px solid;
  .theme-light & {
    border-color: @grey-5;
  }
  .theme-dark & {
    border-color: @grey-1;
  }
}

.f-icn-badge-premium {
  background-image: url('../img/icons/forghetti-ui_monthly_shield_normal.svg');
}

.f-icn-loading {
  background-image: url('../img/icons/forghetti-ui_theme-light_loading_normal.svg');
  .theme-dark & {
    background-image: url('../img/icons/forghetti-ui_theme-dark_loading_normal.svg');
  }
}

.rotate-180 {
  transform-origin: 50% 50%;
  transform: rotate(-180deg);
}
