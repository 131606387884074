.suggested-add-button {
  display: none; // Hidden by default
  border-bottom: none;
  
  .icn-button {
    height: 4rem;
    width: 4rem;
    border: solid 2px var(--theme-colour);
    border-radius: 999px;
    background-color: var(--theme-colour);
  }
  .column.text-group {
    padding-left: 1rem;
  }
  .f-icn-add {
    //.background-image(add);
    .background-image-white(add);
    .theme-dark & {
      .theme-colour-green&,
      .theme-colour-yellow& {
        background-image: url('../img/icons/colour-1/forghetti-ui_add_normal.svg');
      }
    }
  }
}
@media (hover: hover) {
  .suggested-add-button {
    .icn-button:before {
      display: none !important;
    }
  }
}
