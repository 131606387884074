.profile-banner {
  width: 95%;
  justify-content: center;
  overflow: none;


  &__container {
    
    display: flex;
    flex-direction: row;
    background-color: @grey-11;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    background-size: cover;
    background-position: 50% 50%;
    color: @white-1;
  }

  &__text {
    padding:1rem;
    padding-left: 2rem;
    
    flex-basis: 60%;
    display: flex;
    flex-direction: column; 
  }

  &__header {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
      } 


  &__detail {
    font-size: 1.1rem;
  }

  &__graphic {

  }
}