.list-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  //right: 0px;
  //position: sticky;
  top: 0;
  box-sizing: border-box;
  height: @89px;
  padding: 0;
  z-index: 1;

  // & + .search {
  //   position: fixed;
  //   right: 0px;
  //   display: flex;
  //   justify-content: flex-end;
  //   padding-right: 0px;
  //   top: @80px;


  //   &.open {
  //     position: unset;
  //   }
  // }

  // & + .search  .search__button {
  //   //position: fixed;

  //   right: 0px;
  //   background-color: @grey-1;
  //   border-radius: 0px;
  //   border-top-left-radius: 2rem;
  //   border-bottom-left-radius: 2rem;

  //   height: 3rem;
  //   display: flex;
  //   width: 95%;
  //   transition: width 2s;


  //   .search-input {

  //     background-color: @grey-1;
  //     border: none;

  //     width: 0px;


  //     &.show {
  //       width: 100%;

  //     }
  //   }
  //   .f-icn {
  //     margin: auto;
  //     padding-left: 1rem;

  //   }

  // }


  // & + .search{
  //   position: absolute;
  //   top: @89px;
  //   right: 0px;
  //   padding-right: 0px;
  //   .search_input {
  //     height: @42px;
  //   }
  //   border-top-right-radius: 0px;
  //   border-bottom-right-radius: 0px;
  //   width: 60px;

  //   :focus {
  //     position: block;
  //     width: 100%;
  //   }
  // }
  // padding-left: @64px;


  //background-color: @white-1;

  h1 {
    flex-grow: 1;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: auto;
    margin-right: @10px;
    //margin-top: @14px;
    margin-top: 0;
  }

  .nav-link {
    position: static;
    top: @25px;
    right: @10px;

    flex-grow: 0;
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }
}

@media @mobile {
  .list-header {
    height: @60px;
    padding-top: @2px;

    h1 {
      margin-top: 0;
    }

    .nav-link {
      top: @10px;
    }
  }
}
