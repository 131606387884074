.popup-menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: none;
  
  color: white;
  background-color: fade(@grey-5, 80%);
  
  min-height: 100%;
  min-width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  


  &__item {
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid;
    width: 10rem;
    background-color: @grey-5;
    &:hover {
      background-color: @grey-1;
    }
    &:last-child {
    margin-bottom: 6rem;
    }
  }
  
  margin: auto;
}