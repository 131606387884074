.slick-custom__dots {
  //position: absolute;
  //bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  //width: 100%;
  order: 2;
  //align-self: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    position: relative;
    display: inline-block;
    .px(height,10px);
    .px(width,10px);
    .px(margin-left,5px);
    .px(margin-right,5px);
    padding: 0;
    cursor: pointer;
    transition: width 350ms ease;
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      background: transparent;
      display: block;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
      }
      &:hover {
        &:before {
          opacity: @slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        //content: @slick-dot-character;
        content: "";
        width: 100%;
        height: 100%;
        //font-family: @slick-font-family;
        border-radius: 999px;
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
        color: @grey-1;
        background-color: @grey-1;
        opacity: @slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: background-color 350ms ease;
      }
    }
    &.slick-active {
      .px(width,30px);
      button:before {
        //color: @slick-dot-color-active;
        //.theme-colours(background-color);
        background-color: @white-1;
        opacity: 1;
      }
    }
  }
}
.slick-nav-bar {
  display: flex;
  //justify-content: space-between;
  justify-content: space-around;
}
.slick-arrow {
  transition: opacity 350ms ease;
  opacity: 1;
  &.nav-right {
    order: 3
  }
  &.nav-left {
    order: 1
  }
  &.slick-disabled {
    opacity: 0;
  }
}
.slick-track {
  transition: transform 500ms ease; //Fix the sudden jumping of slides when clicking fast on the slider
}
