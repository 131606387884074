.column-group {
  //The country-select plugin is pretty aggressive with it's styling
  //So we have to add a bit of what looks like redundancy in here
  .country-select {
    width: 100%;
    &.inside {
      .selected-flag {
        width: 100%;
        &:before {
          content: '|';
          position: absolute;
          left: 37px;
          top: 10px;
          color: @grey-2;
          .theme-dark & {
            color: @grey-2;
          }
        }
      }
    }
    .selected-flag {
      .arrow {
        right: 1.5rem;
        border-width: 6px;
        .theme-dark & {
          border-top-color: @grey-2;
          &.up {
            border-bottom-color: @grey-2;
            border-width: 6px;
          }
        }
      }
    }
    .flag-dropdown {
      width: 100%;
    }
    .country-list {
      width: 100%;
      .country {
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 44px;
        .iti-mobile & {
          padding: 10px;
          line-height: 1.5em;
        }
      }
    }
  }
}
.country-input {
  line-height: 2.875rem;
  border: 0;
  margin: 0;
  text-indent: 0;
  display: block;
  width: 100%;
  font-size: 1.1875rem;
  line-height: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  height: 2.875rem;
  line-height: 2.875rem;
  .theme-dark & {
    background-color: @grey-1;
    box-shadow: inset 0 0 0 1pt @grey-10;
    color: @white-1;
  }
  .theme-light & {
    background-color: #fff;
    box-shadow: inset 0 0 0 1pt #e0e4e4;
    color: #505064;
  }
}
.country-select .country-list {
  .theme-dark & {
    color: @white-1;
    background-color: @grey-11;
    border: @grey-5;
  }
  .theme-light & {
    background-color: #fff;
    color: #505064;
  }
}
#view-user-preferences {
  .country-select {
    margin-top: 0.7rem;
  }
}
.country-select .flag.zh {
  height: 14px;
  background-position: -1049px 0px;
}
.country-select .flag.zh_hant {
  height: 14px;
  background-position: -1049px 0px;
}
