.lock-screen {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  z-index: 99999;
  
  //background-image: url(../img/splash-screen-background.svg);
  //background-color: #5af0be;
  //background-repeat: no-repeat;
  //background-size: cover;
  background-color:#5af0be;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    .h1;
    display: block;
    margin-bottom: 4rem;
    text-align: center;
  }

  &__icon {
    display: block;
    width: 64px;
    height: 64px;
    background-image: url(../img/icons/colour-1/forghetti-ui_fingerprint_normal.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
