@rem: 0.0625rem; //Working from a base unit of 16px (1/16 = 0.0625)

//Helper function to avoid creating a new variable
.remVal(@val) {
  @result: if((@val = auto),auto,unit(@val/1px)*@rem);
}
.px(@property;@a){
  @{property}: .remVal(@a)[@result];
};
.px(@property;@a;@b){
  @{property}: .remVal(@a)[@result] .remVal(@b)[@result];
};
.px(@property;@a;@b;@c){
  @{property}: .remVal(@a)[@result] .remVal(@b)[@result] .remVal(@c)[@result];
};
.px(@property;@a;@b;@c;@d){
  @{property}: .remVal(@a)[@result] .remVal(@b)[@result] .remVal(@c)[@result] .remVal(@d)[@result];
};

// Rem conversions
@1px: (1 * @rem);
@1point5px: (1.5 * @rem);
@2px: (2 * @rem);
@3px: (3 * @rem);
@4px: (4 * @rem);
@5px: (5 * @rem);
@6px: (6 * @rem);
@7px: (7 * @rem);
@8px: (8 * @rem);
@9px: (9 * @rem);
@10px: (10 * @rem);
@11px: (11 * @rem);
@12px: (12 * @rem);
@13px: (13 * @rem);
@14px: (14 * @rem);
@15px: (15 * @rem);
@16px: (16 * @rem);
@17px: (17 * @rem);
@18px: (18 * @rem);
@19px: (19 * @rem);
@20px: (20 * @rem);
@21px: (21 * @rem);
@22px: (22 * @rem);
@23px: (23 * @rem);
@24px: (24 * @rem);
@25px: (25 * @rem);
@26px: (26 * @rem);
@27px: (27 * @rem);
@28px: (28 * @rem);
@29px: (29 * @rem);
@30px: (30 * @rem);
@31px: (31 * @rem);
@32px: (32 * @rem);
@33px: (33 * @rem);
@34px: (34 * @rem);
@35px: (35 * @rem);
@36px: (36 * @rem);
@37px: (37 * @rem);
@38px: (38 * @rem);
@39px: (39 * @rem);
@40px: (40 * @rem);
@41px: (41 * @rem);
@42px: (42 * @rem);
@43px: (43 * @rem);
@44px: (44 * @rem);
@45px: (45 * @rem);
@46px: (46 * @rem);
@47px: (47 * @rem);
@48px: (48 * @rem);
@49px: (49 * @rem);
@50px: (50 * @rem);
@51px: (51 * @rem);
@52px: (52 * @rem);
@53px: (53 * @rem);
@54px: (54 * @rem);
@55px: (55 * @rem);
@56px: (56 * @rem);
@57px: (57 * @rem);
@58px: (58 * @rem);
@59px: (59 * @rem);
@60px: (60 * @rem);
@61px: (61 * @rem);
@62px: (62 * @rem);
@63px: (63 * @rem);
@64px: (64 * @rem);
@65px: (65 * @rem);
@66px: (66 * @rem);
@67px: (67 * @rem);
@68px: (68 * @rem);
@69px: (69 * @rem);
@70px: (70 * @rem);
@71px: (71 * @rem);
@72px: (72 * @rem);
@73px: (73 * @rem);
@74px: (74 * @rem);
@75px: (75 * @rem);
@76px: (76 * @rem);
@77px: (77 * @rem);
@78px: (78 * @rem);
@79px: (79 * @rem);
@80px: (80 * @rem);
@81px: (81 * @rem);
@82px: (82 * @rem);
@83px: (83 * @rem);
@84px: (84 * @rem);
@85px: (85 * @rem);
@86px: (86 * @rem);
@87px: (87 * @rem);
@88px: (88 * @rem);
@89px: (89 * @rem);
@90px: (90 * @rem);
@91px: (91 * @rem);
@92px: (92 * @rem);
@93px: (93 * @rem);
@94px: (94 * @rem);
@95px: (95 * @rem);
@96px: (96 * @rem);
@97px: (97 * @rem);
@98px: (98 * @rem);
@99px: (99 * @rem);
@100px: (100 * @rem);
@105px: (105 * @rem);
@110px: (110 * @rem);
@115px: (115 * @rem);
@120px: (120 * @rem);
@128px: (128 * @rem);
@130px: (130 * @rem);
@160px: (160 * @rem);
@400px: (400 * @rem);
@132px: (123 * @rem);
@154px: (154 * @rem);
@176px: (176 * @rem);
@198px: (198 * @rem);
@220px: (220 * @rem);
@240px: (240 * @rem);
@242px: (242 * @rem);
@264px: (264 * @rem);
@280px: (280 * @rem);
@286px: (286 * @rem);
@308px: (308 * @rem);
@320px: (320 * @rem);
@330px: (330 * @rem);
@352px: (352 * @rem);
@374px: (374 * @rem);
@380px: (380 * @rem);
@396px: (396 * @rem);
@418px: (418 * @rem);
@420px: (420 * @rem);
@440px: (440 * @rem);
@460px: (460 * @rem);
@482px: (482 * @rem);
@504px: (504 * @rem);
@526px: (526 * @rem);
@548px: (548 * @rem);
@570px: (570 * @rem);
@592px: (592 * @rem);
@614px: (614 * @rem);
@636px: (636 * @rem);
@658px: (658 * @rem);
@680px: (680 * @rem);
@702px: (702 * @rem);
@724px: (724 * @rem);
@746px: (746 * @rem);
@768px: (768 * @rem);
@790px: (790 * @rem);
@812px: (812 * @rem);
@834px: (834 * @rem);
@856px: (856 * @rem);
@878px: (878 * @rem);
@900px: (900 * @rem);
@922px: (922 * @rem);
@944px: (944 * @rem);
@961px: (961 * @rem);
@983px: (983 * @rem);
@1005px: (1005 * @rem);
@1027px: (1027 * @rem);
@1049px: (1049 * @rem);
@1071px: (1071 * @rem);
@1093px: (1093 * @rem);
@1115px: (1115 * @rem);
@1137px: (1137 * @rem);
@1159px: (1159 * @rem);
@1181px: (1181 * @rem);
@1203px: (1203 * @rem);
@1225px: (1225 * @rem);
@1247px: (1247 * @rem);
@1269px: (1269 * @rem);
@1291px: (1291 * @rem);
@1313px: (1313 * @rem);
@1335px: (1335 * @rem);
@1357px: (1357 * @rem);
@1379px: (1379 * @rem);
@1401px: (1401 * @rem);
@1423px: (1423 * @rem);
@1445px: (1445 * @rem);
@1467px: (1467 * @rem);
@1489px: (1489 * @rem);
@1511px: (1511 * @rem);
@1533px: (1533 * @rem);
@1555px: (1555 * @rem);
@1577px: (1577 * @rem);
@1599px: (1599 * @rem);
@1621px: (1621 * @rem);
@1643px: (1643 * @rem);
@1665px: (1665 * @rem);
@1687px: (1687 * @rem);
@1709px: (1709 * @rem);
@1731px: (1731 * @rem);
@1753px: (1753 * @rem);
@1775px: (1775 * @rem);
@1797px: (1797 * @rem);
@1819px: (1819 * @rem);
@1841px: (1841 * @rem);
@1863px: (1863 * @rem);
@1885px: (1885 * @rem);
@1907px: (1907 * @rem);
@1929px: (1929 * @rem);
@1951px: (1951 * @rem);
@1973px: (1973 * @rem);
@1995px: (1995 * @rem);
@2017px: (2017 * @rem);
@2039px: (2039 * @rem);
@2061px: (2061 * @rem);
@2083px: (2083 * @rem);
@2105px: (2105 * @rem);
@2127px: (2127 * @rem);
@2149px: (2149 * @rem);
@2171px: (2171 * @rem);
@2193px: (2193 * @rem);
@2215px: (2215 * @rem);
@2237px: (2237 * @rem);
@2259px: (2259 * @rem);
@2281px: (2281 * @rem);
@2303px: (2303 * @rem);
@2325px: (2325 * @rem);
@2347px: (2347 * @rem);
@2369px: (2369 * @rem);
@2391px: (2391 * @rem);
@2413px: (2413 * @rem);
@2435px: (2435 * @rem);
@2457px: (2457 * @rem);
@2479px: (2479 * @rem);
@2501px: (2501 * @rem);
@2523px: (2523 * @rem);
@2545px: (2545 * @rem);
@2567px: (2567 * @rem);
@2589px: (2589 * @rem);
@2611px: (2611 * @rem);
@2633px: (2633 * @rem);
@2655px: (2655 * @rem);
@2677px: (2677 * @rem);
@2699px: (2699 * @rem);
@2721px: (2721 * @rem);
@2743px: (2743 * @rem);
@2765px: (2765 * @rem);
@2787px: (2787 * @rem);
@2809px: (2809 * @rem);
@2831px: (2831 * @rem);
@2853px: (2853 * @rem);
@2875px: (2875 * @rem);
@2897px: (2897 * @rem);
@2919px: (2919 * @rem);
@2941px: (2941 * @rem);
@2963px: (2963 * @rem);
@2985px: (2985 * @rem);
@3007px: (3007 * @rem);
@3029px: (3029 * @rem);
@3051px: (3051 * @rem);
@3073px: (3073 * @rem);
@3095px: (3095 * @rem);
@3117px: (3117 * @rem);
@3139px: (3139 * @rem);
@3160px: (3160 * @rem);
@3182px: (3182 * @rem);
@3204px: (3204 * @rem);
@3226px: (3226 * @rem);
@3248px: (3248 * @rem);
@3270px: (3270 * @rem);
@3292px: (3292 * @rem);
@3314px: (3314 * @rem);
@3336px: (3336 * @rem);
@3358px: (3358 * @rem);
@3380px: (3380 * @rem);
@3402px: (3402 * @rem);
@3424px: (3424 * @rem);
@3446px: (3446 * @rem);
@3468px: (3468 * @rem);
@3490px: (3490 * @rem);
@3512px: (3512 * @rem);
@3534px: (3534 * @rem);
@3556px: (3556 * @rem);
@3578px: (3578 * @rem);
@3600px: (3600 * @rem);
@3622px: (3622 * @rem);
@3644px: (3644 * @rem);
@3666px: (3666 * @rem);
@3686px: (3686 * @rem);
@3708px: (3708 * @rem);
@3730px: (3730 * @rem);
@3752px: (3752 * @rem);
@3774px: (3774 * @rem);
@3796px: (3796 * @rem);
@3811px: (3811 * @rem);
@3833px: (3833 * @rem);
@3855px: (3855 * @rem);
@3877px: (3877 * @rem);
@3899px: (3899 * @rem);
@3921px: (3921 * @rem);
@3943px: (3943 * @rem);
@3965px: (3965 * @rem);
@3987px: (3987 * @rem);
@4009px: (4009 * @rem);
@4031px: (4031 * @rem);
@4053px: (4053 * @rem);
@4075px: (4075 * @rem);
@4097px: (4097 * @rem);
@4119px: (4119 * @rem);
@4141px: (4141 * @rem);
@4163px: (4163 * @rem);
@4185px: (4185 * @rem);
@4207px: (4207 * @rem);
@4229px: (4229 * @rem);
@4251px: (4251 * @rem);
@4273px: (4273 * @rem);
@4295px: (4295 * @rem);
@4317px: (4317 * @rem);
@4339px: (4339 * @rem);
@4361px: (4361 * @rem);
@4383px: (4383 * @rem);
@4405px: (4405 * @rem);
@4427px: (4427 * @rem);
@4449px: (4449 * @rem);
@4471px: (4471 * @rem);
@4493px: (4493 * @rem);
@4515px: (4515 * @rem);
@4537px: (4537 * @rem);
@4559px: (4559 * @rem);
@4581px: (4581 * @rem);
@4603px: (4603 * @rem);
@4625px: (4625 * @rem);
@4647px: (4647 * @rem);
@4669px: (4669 * @rem);
@4691px: (4691 * @rem);
@4713px: (4713 * @rem);
@4735px: (4735 * @rem);
@4757px: (4757 * @rem);
@4779px: (4779 * @rem);
@4801px: (4801 * @rem);
@4823px: (4823 * @rem);
@4845px: (4845 * @rem);
@4867px: (4867 * @rem);
@4889px: (4889 * @rem);
@4911px: (4911 * @rem);
@4933px: (4933 * @rem);
@4955px: (4955 * @rem);
@4977px: (4977 * @rem);
@4999px: (4999 * @rem);
@5021px: (5021 * @rem);
@5043px: (5043 * @rem);
@5065px: (5065 * @rem);
@5087px: (5087 * @rem);
@5109px: (5109 * @rem);
@5131px: (5131 * @rem);
@5153px: (5153 * @rem);
@5175px: (5175 * @rem);
@5197px: (5197 * @rem);
@5219px: (5219 * @rem);
@5241px: (5241 * @rem);
@5263px: (5263 * @rem);
@5285px: (5285 * @rem);
@5307px: (5307 * @rem);
@5324px: (5324 * @rem);
@5346px: (5346 * @rem);
@5368px: (5368 * @rem);
@5390px: (5390 * @rem);
@5412px: (5412 * @rem);
@5434px: (5434 * @rem);
@5456px: (5456 * @rem);
@5478px: (5478 * @rem);
@5500px: (5500 * @rem);
@5522px: (5522 * @rem);
@5544px: (5544 * @rem);
@5566px: (5566 * @rem);
@5588px: (5588 * @rem);
@5610px: (5610 * @rem);
@5630px: (5630 * @rem);
