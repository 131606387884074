// Ensure that the groups in sidenav can be scrolled.
// This does mean that the secondary nav (groups/profile/updates) are stuck at
// the bottom of the sidenav rather than directly below the groups list
.groups-nav-container {
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: overlay;
  &::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
  }
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0,0,0,0);
  }
  &::-webkit-scrollbar-thumb {
    .theme-light & {
      background-color: @grey-9;
    }
  }
  &::-webkit-scrollbar-button {
    display: none; //Enables scrollbar thumb to reach the absolute top and bottom of the scrollbar track piece
  }
  .groups-nav-inner-container {
    flex-grow: 0;
    position: relative;
    padding-left: @14px;
  }
}
.groups-nav-container,
#groups-nav {
  width: 100%;
}
#groups-nav {
  opacity: 0;
  @media @desktop {
    padding: 0 0 @12px;
  }
  @media @compactNav {
    .px(padding-top;18px);
  }
  @media @mobile {
    .px(padding-bottom,14px);
  }
  &:nth-child(1) {
    animation: fadeInSlideUp 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms forwards;
  }
  &:nth-child(2) {
    animation: fadeInSlideUp 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 300ms forwards;
  }
  li {
    position: relative;
    padding-top: @7px;
    padding-bottom: @7px;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    //font-family: 'Nunito', sans-serif;
    //font-weight: 600;
    //font-size: @18px;
    //line-height: @24px;
    * {
      cursor: pointer;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      //left: @4px;
      left: 0;
      border-radius: 999px 0 0 999px;
      transition: opacity 0.2s ease, background-color 0.2s ease;
      opacity: 0;
      background-color: @grey-10;
    }
    &.active {
      color: @white-1;
      &:before {
        .theme-colours(background-color);
        opacity: 1;
      }
    }
    @media (hover: hover) {
      &:hover {
        //color: @white-1;
        &:before {
          opacity: 1;
        }
      }
    }
    // Grey-out most groups in side-nav if user is non-premium
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    .login-avatar {
      position: relative;
      width: @32px;
      height: @32px;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 @12px 0 @7px;
    }
    .icn-button-name {
      //flex-grow: 1;
      //width: 1px;
      //display: flex;
      //align-items: center;
      //z-index: 1;
      line-height: normal;
      position: relative;
      @media @compactNav {
        display: none;
      }
    }
  }
  .text-group {
    position: relative;
    .px(margin-top,12px);
    .px(padding-top,14px);
    .px(padding-right,17px);
    .px(padding-left,7px);
    .px(padding-bottom,8px);
    &:before {
      // This is the thin border at the bottom
      // It is applied as a psuedo element so that the scrollbar can go the edge of #main-nav
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      .px(right,7px);
      .px(left,-3px);
      .px(height,1px);
      background-color: var(--border-colour);
      .theme-light & {
        background-color: lighten(@grey-1,15%);
      }
    }
    .primary-text-content {
      .h1;
      .px(font-size,14px) !important;
      .px(line-height,14px);
    }
    .secondary-text-content {
      .h1;
      .px(font-size,22px) !important;
      line-height: @18px * (52/32);
      text-transform: none;
      color: @white-1;
    }
    @media @compactNav {
      overflow: hidden;
      max-height: 0;
      padding: 0;
      margin: 0;
    }
  }
}

// Allow momentum based scrolling within section panes and the groups selector
// in the left side nav
// .section-pane-wrapper .section-pane,
// .groups-nav-inner-container {
// 	-webkit-overflow-scrolling: touch;
// }

// If we are in a responsive mobile view and pane 2/3 is currently active,
// fade out the group highlight in main nav
@media @mobile {
  body.pane-2-active,
  body.pane-3-active {
    #main-nav ul#groups-nav li.active:before {
      opacity: 0.4;
    }
  }
}
