@border-radius: 10px;

#premium {
  .premium {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > .column {
      flex-direction: column;
      flex-grow: 0 !important;
      max-width: 100%;
    }

    &__tab-row {
      display: flex;
      width: 100%;
      height: 8rem;
      padding-top: 1rem;
    }

    &__tab {
      flex-grow: 1;
      .theme-colours(background-color);
      border-radius: @border-radius;
      color: @grey-1;
      width: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: solid 4px rgba(0, 0, 0, 0.1);

      opacity: 0.7;
      width: calc(32% - 1rem);
      margin-top: 1rem;
      cursor: pointer;
      * {
        cursor: pointer;
      }

      .premium__tab-title {
        font-size: 1.2rem;
        line-height: 1.3rem;
        padding: 0.5rem 2rem;
        text-align: center;
        margin: 0;
        flex-grow: 1;
        display: block;
        transition: font-size 100ms, line-height 100ms;
      }
      transition: opacity 100ms, width 100ms, margin 100ms;
    }

    &__tab--active {
      opacity: 1;
      width: calc(36%);
      margin-top: 0;
      .premium__tab-title {
        font-size: 1.6rem;
        line-height: 1.7rem;
      }
    }

    &__tab-detail {
      font-family: 'Nunito', sans-serif;
      height: 50%;
      background-color: rgba(125, 125, 125, 0.1);
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.75rem;
      height: 2.8rem;
      line-height: calc(2.8rem - 6px);

      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: center;
      border-bottom-left-radius: @border-radius;
      border-bottom-right-radius: @border-radius;

      border: solid 3px;
      .theme-colours(border-color);
    }

    &__tab-price {
      font-weight: bold;
      font-size: 1.1rem;
    }

    &__tab:not(:first-child) {
      margin-left: 1rem;
    }

    &__tab-container {
      position: relative;
      flex-grow: 1;
      margin-top: 1rem;
    }

    &__tab-content {
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 200ms, transform 200ms;
      z-index: 1;
    }

    // Transition into view
    &__tab-content--active {
      transform: translateY(0px);
      opacity: 1;
      z-index: 2;
    }

    &__tab-content-box {
      color: white;
      background-color: @grey-1;// #505064;
      .theme-dark & {
        background-color: #2e2e3d;
      }
      border: solid 1px rgba(255, 255, 255, 0.2);
      border-radius: @border-radius;
      padding: 2rem 3vw;
      height: 100%;

      align-items: center;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-size: 1.7rem;
        line-height: 1.7rem;
        margin: 0;
        margin-bottom: 1.5rem;

        flex-grow: 0 !important;
      }

      ul {
        list-style: none;
        margin: 0;
      }
      li {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      // Tick icon for each bulletpoint
      li:before {
        content: '';
        display: inline-block;
        height: 2rem;
        width: 2rem;
        margin-right: 0.5rem;
        transform: translate(0px, 9px);
        background-image: url(../img/icons/colour-5/forghetti-ui_check_normal.svg);
      }
    }

    &__avail-soon {
      display: block;
      text-align: center;
      width: 100%;
      font-size: 1.2rem;
    }
  }
}
