@label-length: 4rem;

.row .column .column-group.avatar-group {
  justify-content: space-around;
  min-width: auto;
  //border-radius: 999px;
  //padding: 0.4rem 1rem 0.6rem 1rem;
  margin: 1rem auto 0.6rem auto;

  //background-color: #e0e4e4;
  .theme-dark & {
  //  background-color: #161620;
  }

  .login-avatar {
    border: solid 3px #e0e4e4;
    .theme-dark & {
      border: solid 3px lighten(@grey-9,10%);
    }
  }

  .login-avatar-wrapper {
    //margin: 0 0.4rem;
    margin: 0.2rem;
    .login-avatar-label {
      font-size: 0.75rem;
      line-height: 1rem;
      max-width: @label-length;
      //text-align: center;
      .truncate;
    }
  }

  .f-icn {
    // Show the arrow icon if "with-arrow" class is used
    display: block;
    margin: 0;
    padding: 0;
    transform: translateY(1rem);
  }
}

.avatar-group-row {
  border: none;
}


// Hide the arrow icon if "with-arrow" class is not used
.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group {
  // Hide the arrow icon
  padding-left: 0px;
  padding-right: 0px;
  .f-icn {
    display: none !important;
  }
  // Right-align first avatar/name
  .login-avatar-wrapper:first-child {
    align-items: center;
    position: relative;
    z-index: 1;
    .login-avatar {
      //transform: translateX(0.8rem);
    }
    .login-avatar-label {
      text-align: center;
      width: @label-length;
      .truncate;
    }
  }
  // Left-align second avatar/name
  .login-avatar-wrapper:last-child {
    align-items: center;
    .login-avatar {
      //transform: translateX(-0.8rem);
    }
    .login-avatar-label {
      text-align: center;
      width: @label-length;
      .truncate;
    }
  }
}
