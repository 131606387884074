.h5-note {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-size: 2rem;
  text-transform: none;
  letter-spacing: normal;
  background-color: fade(@blue,40%);
  color: #505062;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid @blue;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
.h6-note {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  text-transform: none;
  letter-spacing: normal;
  background-color: fade(@green,40%);
  color: #505062;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid @green;
  box-sizing: border-box;
}
.row {
  #block-templates & {
    border: 0;
  }
}
