.context-menu {
  display: none;
  position: absolute;
  min-width: @160px;
  z-index: 1000;
  background-color: @white-1;
  box-shadow: 0 @8px @16px fade(@grey-2, 25%);
  border-radius: @4px;
  color: @grey-1;
  overflow: hidden;
  &.context-menu--active {
    display: block;
  }

  .context-menu__heading {
    padding: @4px @20px @8px;
    font-weight: 600;
    text-align: center;
  }

  .context-menu__item {
    text-align: center;
    display: block;
    opacity: 1 !important;
    .px(margin-top,-1px);
    position: relative;
    &:not(:last-child):after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: @10px;
      height: @1px;
      background: @grey-3;
    }
    &:hover {
      background-color: @grey-6;
      color: @grey-8;
      &:after {
        background: transparent;
      }
    }
  }

  .context-menu__link {
    display: block;
    padding: @12px @20px;
    color: inherit;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    * {
      cursor: pointer;
    }
  }

  &[data-position="center"] {
    top: 33%;
    left: 33%;
    min-width: 33%;
  }
}
