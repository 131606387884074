input,
textarea,
select {
  &:focus {
    outline: none;
  }
}

/* Hide number field spinner buttons */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-input {
  border: 0;
  color: @grey-1;
  padding: 0;
  margin: 0;
  text-indent: 0;
  display: block;
  width: 100%;
  background-color: transparent;
  font-size: @19px;
  line-height: @24px;
  height: @24px;
  border-radius: @4px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1pt transparent;
  transition: height 200ms ease, text-indent 200ms ease;

  .focussed & + .input-action {
    /* Now your own custom styles */
    position: absolute;
    top: 0px;
    right: @hori-margin / 4;
    height: @46px;
    width: @46px;
    display: block;
    background-repeat: no-repeat;
    background-size: @44px @44px;
    background-position: 50% 50%;
    z-index: 2000;
  }
}

.focussed {
  // .text-input:not(.activation-input) {
  //   height: @46px;
  //   line-height: @46px;
//   text-indent: @13px;
  //   box-shadow: inset 0 0 0 1pt @grey-3;
  //   background-color: #fff;
  //   transition: height 200ms ease, text-indent 200ms ease;
  // }
  .text-input {
    height: @46px;
    line-height: @46px;
    text-indent: @13px;
    //box-shadow: inset 0 0 0 1pt @grey-3;
    background-color: @grey-3;
    .theme-dark & {
      background-color: @grey-5;
      color: #fff;
    }
    border-color: @grey-5;
    transition: height 200ms ease, text-indent 200ms ease;
  }
  &.primary-heading {
    .text-input {
      height: @52px;
      text-indent: @15px;
      line-height: @52px;
    }
  }
  &.with-action {
    position: relative;
    .text-input {
      padding-right: @50px;
    }
  }
}

// =================================================================================================

// .text-input.activation-input {
//   font-family: 'Nunito', sans-serif;
//   font-weight: 700;
//   border-radius: 5px;
//   text-align: center;
//   background-color: #fff;
//   color: @grey-1;
//   box-shadow: inset 0 0 0 1pt @grey-3;
//   .px(font-size,50px);
//   .px(height,80px);
//   line-height: normal;
//   .px(width,250px);
//   letter-spacing: 0.1em;
//   .theme-dark & {
//     color: @grey-1;
//   }
//   &:focus {
//     .theme-colour-blue & {
//       box-shadow: inset 0 0 0 @1px @blue;
//     }
//     .theme-colour-green & {
//       box-shadow: inset 0 0 0 @1px @green;
//     }
//     .theme-colour-red & {
//       box-shadow: inset 0 0 0 @1px @red;
//     }
//     .theme-colour-orange & {
//       box-shadow: inset 0 0 0 @1px @orange;
//     }
//     .theme-colour-yellow & {
//       box-shadow: inset 0 0 0 @1px @yellow;
//     }
//   }
// }

// =================================================================================================

.align-right {
  input {
    text-align: right;
    padding-right: @10px;
  }
}

// Small input field, primary used for editing group maximum members value
.small-input {
  flex-grow: 0.25;
}

.input-lowercase {
  text-transform: lowercase;
}
