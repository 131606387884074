.suggested-presets.row {
  .px(margin-left,-8px);
  .px(margin-right,-8px);


  > .column .column-group {
    display: flex;
    flex-wrap: wrap;
    .px(padding-top,11px);
    padding-bottom: 0;
    .column {
      flex-basis: percentage(1/4);
      max-width: percentage(1/4);
      min-width: percentage(1/4);
      padding: 0;
      font-size: 0.9rem;
      margin: 0;
      .px(margin-top,15px);
      .px(min-height,100px);
      @media @onboardingMobile {
        .px(margin-top,10px);
      }
      @media screen and (max-width: 370px) {
        flex-basis: percentage(1/3);
        max-width: percentage(1/3);
        min-width: percentage(1/3);
      }

    }
  }




  .login-avatar-wrapper {
    cursor: pointer;
    &:hover .login-avatar-inner:after {
      opacity: 1;
    }
    &[data-selected='true'] .login-avatar-inner:after {
      opacity: 1;
    }
    &.exists {
      opacity: 0.7;
    }
    .login-avatar-inner {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .theme-colours(border-color);
        border-style: solid;
        border-radius: 999px;
        .px(border-width,3px);
        opacity: 0;
        transition: opacity 150ms ease;
      }
    }
    .login-avatar-inner,
    .login-avatar {
      width: 4rem;
      height: 4rem;
    }
    .login-avatar-label {
      max-width: 90%;
    }
  }
}
