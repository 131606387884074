.login-item {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: (@hori-margin*1.5)-@7px;
  @media (min-width: 1025px) {
    padding-left: (@hori-margin*2)-@7px;
  }
  @media (hover: hover) {
    &:hover {
      .login-item-inner {
        background-color: @grey-7;
      }
      .button-group {
        opacity: 1;
      }
    }
    }
}

.login-item-inner {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: @7px;
  background-color: @white-1;
  will-change: background-color;
  transition: background-color 0.1s ease-in;
  border-radius: 999px 0 0 999px;
  .theme-dark & {
    background-color: @grey-5;
  }
  .icn-button {
    margin: 0;
    .px(min-width,60px);
    @media screen and (max-width: 1024px) {
      .px(min-width,46px);
    }
    .thin-border; // Mixin
        .alpha-list:last-child .login-item:last-child & {
      border: 0;
    }
  }
}

.login-avatar-wrapper {
  flex: 0 0 @46px;
  padding: @7px 0;
  @media (min-width: 1025px) {
    flex: 0 0 @60px;
  }
&[data-selected='true'] .login-avatar-inner:after {
  opacity: 1;
}

&.exists {
  opacity: 0.5;
}

.login-avatar-inner {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .theme-colours(border-color);
    border-style: solid;
    border-radius: 999px;
    .px(border-width, 3px);
    opacity: 0;
    transition: opacity 150ms ease;
  }
}
}

.login-avatar {
  transform-origin: 50% 50%;
  animation: 500ms ease transitionFadeIn 100ms;
  animation-fill-mode: both;
}

.login-overview-wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 0; //enable ellipsis text overflow
  .thin-border; // Mixin
  .alpha-list:last-child .login-item:last-child & {
    border: 0;
  }
  .login-overview {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: column;
    justify-content: center;
    padding: @6px 0 @10px @10px;
    min-width: 0;
    @media (min-width: 1025px) {
      padding-top: @7px;
      padding-bottom: @12px;
    }
  }
  .login-name,
  .login-username {
    margin: 0;
    .truncate;
  }
  .login-name {
    //font-weight: 600;
    font-family: 'Nunito';
    margin-bottom: -1px;
    @media (min-width: 1025px) {
      font-size: @19px;
      line-height: @26px;
      margin-bottom: -@4px;
    }
  }
  .login-username {
    @media (min-width: 1025px) {
      font-size: @16px;
      line-height: @20px;
    }
  }
  .login-overview,
  .button-group {
    padding-left: @10px;
  }
  .button-group {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    padding-right: @10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .button-group-inner {
    display: table;
    height: 100%;
    .icn-button {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      .f-icn {
        position: relative;
        top: auto;
        right: auto;
        display: inline-block;
        @media (min-width: 1025px) {
          width: @40px;
          height: @40px;
          line-height: @40px;
          background-size: @40px @40px;
        }
      }
    }
  }
}
