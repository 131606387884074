.align-left {
  text-align: left;
}

.primary-text {
  font-size: @18px;
  line-height: @24px;
  .truncate;
  &.column {
    flex: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-width: 0;
    .table-view > & {
      flex-grow: 0;
      text-overflow: initial;
      white-space: initial;
      overflow: visible;
      &:first-child {
        .px(padding-top,16px);
      }
    }
    .row:not(.onboarding-image) + & {
      .px(padding-top,16px);
    }
  }
  &.wrap-text {
    flex-grow: 0;
    text-overflow: initial;
    white-space: initial;
    overflow: visible;
  }
  span:not(.secondary-text):not(.tag):not(.country-name):not(.dial-code) {
    display: block;
    .tag:not(.small) {
      top: 0;
    }
  }
}

.secondary-text {
  font-size: @16px;
  line-height: @16px;
  .theme-light & {
    color: @grey-2;
  }
}

.secondary-heading {
  .h2;
  .truncate;
  margin: 0;
  overflow: visible;
  padding-bottom: @4px;
}

.third-heading {
  border-bottom: none;
  .secondary-heading {
    font-size: @16px;
    font-weight: 600;
    line-height: @18px;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    opacity: 0.65;
  }
}

.info-text {
  font-size: @16px;
  line-height: @26px;

  .centered-text & {
    text-align: center;
  }
  .align-left & {
    text-align: left;
  }
  &.column {
    //padding-left: @hori-margin / 2;
    padding-right: @hori-margin / 2;
  }
  .theme-light & {
    color: @grey-2;
  }
}

.help-text {
  font-size: @13px;
  line-height: @16px;
  .theme-light & {
    color: @grey-4;
  }
  a {
    font-weight: 700;
    text-decoration: none;
    .theme-light & {
      color: @grey-2;
    }
  }
  .message & {
    color: @grey-4;
  }
}

.text-group {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  min-width: 0;
  &:first-child:last-child {
    .primary-heading {
      text-align: center;
    }
  }
}

.text-group-inner {
  display: inline-grid;
  width: 100%;
  span {
    display: inline;
  }
  .column-group {
    min-width: 0;
    .column + .column {
      padding-left: @hori-margin / 2;
    }
  }
}

.member-name-wrapper {
  white-space: nowrap;
  overflow: hidden;
}

.text-button {
  opacity: 1;
  transition: opacity 100ms ease !important;
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
      .row.cta & {
        opacity: 1;
      }
    }
  }
}

.secondary-text-content {
  font-size: 0.65rem;
  &:not(.nocolour) {
    color: var(--theme-colour);
  }

  &:not(.no-transform) {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
  }
}

.text-group-inner {
  display: flex;
  flex-direction: column;
  .primary-text {
    max-width: 100%;
    flex-grow: 1;
    overflow-wrap: break-word;
    .primary-text-content {
      white-space: normal;
    }
  }
}

.monospace() {
  font-family: 'Ubuntu Mono', monospace;
  line-height: 1.25;
  letter-spacing: 0.2em;
}

// Monospaced font for displaying passwords, to make them easier to read
.text-group.monospace {
  //.px(min-height,47px);
  align-items: flex-start;
  .primary-text {
    .px(min-height,35px);
    .row:not(.password-visible) & {
      display: flex !important;
      //align-items: center;
    }
  }
  .primary-text-content {
    .monospace;
    .px(font-size,14px);
    .row:not(.password-visible) & {
      display: block !important;
      .px(height,29px);
      .px(line-height,29px);
    }
    .password-visible & {
      .px(font-size,22px);
      line-height: 1.3;
      letter-spacing: 0.1em;
      @media @mobile {
        font-size: 1em;
      }
    }
  }
}

span.monospace {
  .monospace;
  letter-spacing: 0.1em;
  color: @grey-2;
  .px(font-size,22px);
  line-height: inherit;
  margin: 0 @5px;
  .theme-dark & {
    color: @grey-11;
  }
}

//Password breakdown by numbered characters
.numbered-character-list {
  list-style: none;
  counter-reset: item;
  display: flex !important;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  .px(margin-left,-4px);
  .px(margin-right,-4px);
  > li,
  > span {
    counter-increment: item;
    .px(height,27px);
    .px(min-width,19px);
    .px(max-width,19px);
    display: flex !important;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .px(font-size,18px);
    .password-visible & {
      .px(font-size,26px);
    }
    &:nth-child(n + 1) {
      border-left: @2px solid transparent;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -@1px;
        width: 1px;
      }
    }
    .password-visible & {
      animation: revealPassword 1000ms cubic-bezier(0.25, 0.1, 0.25, 1) 0ms forwards;
      //border-left-color: var(--border-colour);
      &:after {
        background: linear-gradient(0deg, var(--border-colour) 0%, transparent 60%);
      }
    }
    &:before {
      content: counter(item);
      color: @grey-4;
      display: flex;
      .px(font-size,14px);
      letter-spacing: -0.05em;
      opacity: 0;
      .theme-dark & {
        color: @grey-2;
      }
      .password-visible & {
        animation: fadeInSlideDown 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 750ms forwards;
      }
    }
    span {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      .px(left,1px);
    }
  }
}

.secondary-text-label {
  margin-top: @10px;
}
