.theme-dark {
  //Overlays
  &.overlay {
    color: @white-1;
  }

  // Background colour
  .search,
  #pane-1,
  #pane-2,
  #pane-3,
  .list-header,
  .login-list-wrapper .login-list .login-item-inner,
  .image-uploader__main {
    background-color: @grey-5;
  }
  .image-uploader__overlay {
    background-color: #15151faa;
  }

  .search-input {
    background-color: @grey-10;
    border: solid 1px @grey-5;
    color: @white-1;
    &:focus {
      box-shadow: none;
    }
  }

  .text-input {
    color: @white-1;
  }
  // .focussed .text-input:not(.activation-input) {
  //   color: @grey-1;
  // }
  .focussed .text-input {
    color: @grey-1;
    color: @white-1;
    background-color: @grey-8;
  }

  // Side nav background colour
  #main-nav .main-nav--inner,
  body& {
    background-color: @grey-9;
  }

  #main-nav {
    color: @white-1;
  }

  #groups-nav {
    li {
      &:before {
        background-color: darken(@grey-1, 8%);
      }
      &.active {
        .icn-button-name {
          .theme-colour-yellow&,
          .theme-colour-green& {
            color: @grey-1;
          }
        }
      }
    }
  }

  #menu-icon {
    background-image: url('../img/icons/menu-light.png');
  }

  // Text colour
  button,
  .nav-bar li .icn-button-name {
    color: @white-1;
  }
  .tabbed-picker .column.active,
  .tabbed-picker .column:hover {
    color: @grey-5;
  }

  // Borders
  .row,
  .section-pane-nav,
  .row-group:last-child .row:last-child,
  .login-list-wrapper .login-list .login-overview-wrapper,
  .login-list-wrapper .login-list .login-item-inner .icn-button {
    border-color: @grey-10;
  }

  //------------------------------------------------------------------------------------------------
  // Hover background colour
  .row[data-actionclick]:not(.cta),
  .row[data-nav]:not(.cta) {
    @media (hover: hover) {
      &:hover > .column {
        background-color: @grey-8;
      }
    }
  }
  label:not(.search) {
    @media (hover: hover) {
      &:hover {
        background-color: @grey-8;
        &.small-input {
          &:before {
            background-color: @grey-8;
          }
        }
      }
    }
  }
  #groups-nav li:not(.active),
  .main-nav_middle > .row,
  .main-nav__bottom > .row {
    &:before {
      background-color: @grey-5;
    }
    @media (hover: hover) {
      &:hover:before {
        background-color: @grey-8;
      }
    }
  }
  .login-list-wrapper .login-list .login-item {
    @media (hover: hover) {
      &:hover .login-item-inner {
        background-color: @grey-8;
      }
    }
  }

  //------------------------------------------------------------------------------------------------
  // Scrollbar
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-resizer,
  ::-webkit-scrollbar-track-piece {
    background-color: @grey-9;
  }
  ::-webkit-scrollbar-thumb {
    background-color: @grey-8;
  }

  //------------------------------------------------------------------------------------------------
  // Toast message popups
  .dialogMgr_toastBox {
    background-color: black;
    color: @white-1;
  }

  //------------------------------------------------------------------------------------------------
  /*.context-menu {
    background-color: @grey-10;
    box-shadow: none;

    .context-menu__item {
      .context-menu__link {
        color: @grey-4;
        border-color: @grey-1;
      }
    }

    .context-menu__item:hover {
      background-color: @grey-11;
    }
    .context-menu__item:hover .context-menu__link,
    .context-menu__item .context-menu__link:hover {
      color: @grey-7;
    }

    .context-menu__heading {
      color: @grey-7;
    }
  }*/

  .context-menu {
    background-color: shade(@grey-5, -15%);
    box-shadow: 0 @8px @16px fade(@grey-9, 75%);
    color: fade(@white-1, 85%);
    .context-menu__item {
      &:not(:last-child):after {
        background: @grey-10;
      }
      &:hover {
        background-color: @grey-8;
        color: @white-1;
        &:after {
          background: transparent;
        }
      }
    }
  }

  //------------------------------------------------------------------------------------------------
  #pane-1,
  #pane-2,
  #pane-3,
  .image-uploader__main,
  .list-header,
  .search {
    background-color: @grey-5;
  }

  .row,
  .login-list-wrapper .login-list .login-overview-wrapper,
  .row-group:last-child .row:last-child,
  .section-pane-nav {
    border-color: @grey-10;
  }

  //------------------------------------------------------------------------------------------------
  .nav-bar__button-group {
    background-color: @grey-9;
    ul {
      border: none;
    }
  }
}
