@specialCharacterColumns: 6;
.special-character-table {
  display: grid;
  grid-template-columns: repeat(@specialCharacterColumns, 1fr);
  grid-auto-rows: @60px;
  grid-gap: @1px;
  overflow: hidden;
  //border: @1px solid var(--border-colour);
  background: var(--border-colour);
  .px(border-radius,3px);
  margin-bottom: @10px;
  .theme-dark & {
    //border: @1px solid @grey-11;
    background: @grey-11;
  }
}
.special-character-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 1px solid;
  padding: @8px @6px;
  .truncate;
  .monospace;
  .px(font-size,22px);
  line-height: 1.3;
  letter-spacing: 0.1em;
  .theme-colours(color);
  background: @white-1;
  transition: background-color 200ms ease;
  &.disabled {
    pointer-events: none;
    color: @grey-3;
    .theme-dark & {
      color: @grey-11;
    }
  }
  .theme-dark & {
    background: @grey-5;
  }
  &[data-selected="true"] {
    .theme-colours(background-color);
    &.disabled {
      background: @white-1;
      color: @grey-3;
      .theme-dark & {
        background: @grey-5;
        color: @grey-11;
      }
    }
  }
  &:hover {
    background: @grey-7;
    &[data-selected="true"] {
      .theme-colours(background-color);
    }
    .theme-dark & {
      background: @grey-11;
      color: @white-1;
    }
  }
  //Top Left Cell
  &:nth-child(1) {
    .px(border-top-left-radius,3px);
  }
  //Top Right Cell
  &:nth-child(@{specialCharacterColumns}) {
    .px(border-top-right-radius,3px);
  }
  //Bottom Left Cell
  &:nth-child(@{specialCharacterColumns}n+1):nth-last-child(-n+@{specialCharacterColumns}) {
    .px(border-bottom-left-radius,3px);
  }
  //Bottom Right Cell
  &:nth-child(@{specialCharacterColumns}n):nth-last-child(-n+@{specialCharacterColumns}) {
    .px(border-bottom-right-radius,3px);
  }
  //All Top Row Cells
  &:nth-child(-n+@{specialCharacterColumns}) {
    border-top: @1px solid var(--border-colour);
    &:hover {
      border-top-color: @grey-7;
      .theme-dark & {
        border-top-color: @grey-11;
      }
    }
  }
  //All Bottom Row Cells
  &:nth-last-child(-n+@{specialCharacterColumns}) {
    border-bottom: @1px solid var(--border-colour);
    &:hover {
      border-bottom-color: @grey-7;
      .theme-dark & {
        border-bottom-color: @grey-11;
      }
    }
  }
  //All First Column Cells
  &:nth-child(@{specialCharacterColumns}n+1) {
    border-left: @1px solid var(--border-colour);
    &:hover {
      border-left-color: @grey-7;
      .theme-dark & {
        border-left-color: @grey-11;
      }
    }
  }
  //All Last Column Cells
  &:nth-child(@{specialCharacterColumns}n) {
    border-right: @1px solid var(--border-colour);
    &:hover {
      border-right-color: @grey-7;
      .theme-dark & {
        border-right-color: @grey-11;
      }
    }
  }
}
.transition-collapsible-row {
  //Set the max height to SOMETHING so that the animation timing is consistent no matter what the screen height
  .px(max-height, 80px);

  transition: max-height 800ms ease;
  #symbolsWrapper&,
  #wordsCustomLengthWrapper&,
  #letterNumberSymbolCustomLengthWrapper& {
    display: block;
  }
  #symbolsWrapper& {
    //Max height allows for an extra row of symbols if necessary
    .px(max-height, 400px);
  }
  &.collapsed {
    max-height: 0 !important;
    .no-border + & {
      max-height: @1px !important;
    }
    overflow: hidden;
  }
}