#pane-2 {
  position: relative;
  box-sizing: border-box;
  width: 55%;
  @media (min-width: @desktopXLargeHoriBreak) {
    width: 50%;
  }
  @media @mobile {
    width: 100%;
    height: 100%;
  }
  .theme-light & {
    background-color: @white-1;
  }
}
#loading {
  color: @white-1;
  background-image: url(../img/grid.svg);
  background-size: @80px @80px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  .theme-colours(background-color);
}
