.section-pane.full-colour {
  color: #f8fafa;
  .section-pane-nav {
    border-bottom: 0.3125rem solid #ffffff80;
  }
  .nav-link.nav-left {
    color: #f8fafa;
  }
}

// The following colours, for the background colour of
// "Connecting" screens, come from the colours of the loading
// GIFs that we have generated, which are very slighltly off
// from the base theme colours.
.section-pane.full-colour {
  background-color: var(--theme-colour);
}

#profile-landing-page,
#edit-profile-name {
  .login-avatar-wrapper {
    margin-right: @14px;
  }
}

.radio-button.active {
  opacity: 1;
  .theme-dark & .border {
    border-color: @grey-3;
  }
  .theme-light & .border {
    border-color: @grey-2;
  }
}

.radio-button {
  &.blue,
  &.orange,
  &.red,
  &.green,
  &.yellow {
    .border {
      background-color: @white-1;
      border-color: @grey-3;
    }
  }
  &.dark .fill {
    background-color: @grey-5;
  }
  &.light .fill {
    background-color: @white-1;
  }
}

.busy .lochy-busy {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  z-index: 200;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.busy .lochy-loading {
  //position: absolute;
  background: url(../img/lochy/09-Lochy-Weights.gif);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 200px 200px;
  flex-grow: 1.7;
  width: 100%;
}
.lochy-loading-text {
  display: none;
}
.busy .lochy-loading-text {
  display: block;
  flex-grow: 1;
}

.busy {
  cursor: wait;

  & > * > * {
    //for now, make it completely overlay the pane.
    opacity: 1;
    pointer-events: none;
  }
  .section-pane {
    opacity: 0;
  }
  .section-pane-inner,
  .login-list-page {
    //opacity: 0.4;
  }
}

.main {
  @media @mobile {
    //max-height: ~'calc(100% - @{60px})';
    body:not(.pane-2-active):not(.pane-3-active) & {
      box-sizing: border-box;
      .px(padding-bottom, 60px);
    }
  }
}

.primary-text-content {
  user-select: text;
}

.primary-text span:not(.secondary-text):not(.tag):not(.country-name):not(.dial-code) {
  display: inline;
}

// Make rows with an action (like on chooseServiceIcon page) have a grey
// background and pointer to suggest it can be clicked on
.row[data-actionclick]:not(.cta):hover .column {
  //background-color: @grey-7;
  cursor: pointer;
}

// Ensure anything clickable has a 'pointer' cursor
*[data-actionclick]:not(:disabled) {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

// If we are in a responsive mobile view and pane 2/3 is currently active,
// fade out the group highlight in main nav
@media @mobile {
  // Pane 2 needs to slide in on top of pane 1 when it becomes active
  #pane-2 {
    z-index: 4 !important;
    transform: translateX(100%) translateY(0) !important;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.25);
  }

  #pane-2.active {
    transform: translateX(0) translateY(0) !important;
  }

  // Pane 3 (only currently used for adding new services) always appears on top
  #pane-3 {
    z-index: 5 !important;
  }
}

.loading-icon-row {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon-row .column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  width: 128px;
  height: 128px;
  opacity: 0.3;
}

.theme-dark {
  .loading-icon {
    background: url('../img/loading-202030-128.gif');
  }
}
.theme-light {
  .loading-icon {
    background: url('../img/loading-F8FAFA-128.gif');
  }
}

.full-colour {
  .loading-icon {
    height: 160px;
    width: 160px;
    opacity: 1;
  }

  .loading-icon {
    .theme-colour-red & {
      background: url('../img/loading-white-red.gif');
    }
    .theme-colour-blue & {
      background: url('../img/loading-white-blue.gif');
    }
    .theme-colour-green & {
      background: url('../img/loading-white-green.gif');
    }
    .theme-colour-orange & {
      background: url('../img/loading-white-orange.gif');
    }
    .theme-colour-yellow & {
      background: url('../img/loading-white-yellow.gif');
    }
  }
}

// #verify-device-3 .loading-icon {
//   background: url('../img/loading-white-green.gif');
// }

#pane-3 {
  z-index: 3;
  transform: translateX(0) translateY(100%);
  transition: transform 300ms ease 50ms;
  @media @desktop {
    z-index: 5;
    left: auto;
    width: 55%;
  }
}

.no-items-row {
  span {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    .px(margin-top, 20px);
    .px(padding;0;12px);
    font-size: @20px;
    width: 100%;
    .px(max-width, 500px);
    opacity: 0.6;
    @media screen and (min-height: 641px) {
      .px(margin-top, 80px);
    }
  }
}
.no-items-column {
  span {
    display: block;
  }
}

#hidden-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.centered-text {
  text-align: center;
}
/* This was added purely to get the star on the "You're ready to forghetti!"
	 screen to be centered. Maybe it needs an entirely new row template. The
	 block-icn template wasn't being used for anything else. */
.centered-text .icn-button {
  width: 100%;
}

.row.centered-text h2 {
  white-space: normal;
}

// Center columns within "centered-text" rows. Initially done to center the row of
// services in the createAccount_addLogin page.
.row.centered-text .column-group {
  justify-content: center;
}

.wrapped-text {
  white-space: normal;
  .primary-text,
  .secondary-text {
    white-space: normal;
  }
}

/* Stop help buttons appearing too close to the prev column */
.icn-button {
  margin-left: @5px;
}

.theme-light,
.theme-dark {
  button.cta-button:not(:hover),
  button.cta-button.column.text-button:not(:hover) {
    color: @white-1;
  }
  &.theme-colour-green,
  &.theme-colour-yellow {
    button.cta-button:not(:hover),
    button.cta-button.column.text-button:not(:hover),
    .cta:hover .column.text-button {
      color: @grey-1;
    }
    .red.cta:hover .column.text-button {
      color: @white-1;
    }
  }
}

.cta:hover,
.cta:hover .column.text-button {
  color: @white-1;
}
.cta-button:hover {
  opacity: 1;
}

.red {
  .column.text-button {
    color: @ios-red;
    &:hover .column.text-button {
      color: @white-1;
      opacity: 1;
    }
  }
}

.f-icn-star {
  background-size: 5rem 5rem;
  width: 5rem;
  height: 5rem;
  background-image: url('../img/star.png');
}

[data-context] {
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.row.collapsed {
  display: none;
}

// Support group icons which have a coloured background and contain an SVG image
.group-avatar {
  .login-avatar {
    .theme-colour-red & {
      background-color: @red;
    }
    .theme-colour-green & {
      background-color: @green;
    }
    .theme-colour-blue & {
      background-color: @blue;
    }
    .theme-colour-yellow & {
      background-color: @yellow;
    }
    .theme-colour-orange & {
      background-color: @orange;
    }
  }
  img[data-actionclick] {
    cursor: pointer;
  }
}

// To allow table-views in sections expand (based on their contents) taller
// than 100% height of the screen, we need the section-pane-inner to expand.
// So to counteract the height:100% rule somewhere else, set height to auto
// but min-height to 100% instead.
// This allows the search field to be always visible in the chooseServiceIcon
// screen.
.section-pane-inner {
  height: auto;
  min-height: 100%;
}

// Show arrow-in-circle icon in theme colour for onboarding screens
.f-icn-right-solid {
  .background-image-colour(right-solid);
}

// Onboarding screens - progress dots
.progress-dots {
  .column-group {
    justify-content: center;
  }

  .progress-dots__dot {
    width: @10px;
    height: @10px;
    margin: @5px;
    border-radius: 50%;
    opacity: 0.3;

    &.active {
      opacity: 1;
      width: @12px;
      height: @12px;
      margin: @4px;
    }

    .theme-colour-red & {
      background-color: @red;
    }
    .theme-colour-blue & {
      background-color: @blue;
    }
    .theme-colour-yellow & {
      background-color: @yellow;
    }
    .theme-colour-orange & {
      background-color: @orange;
    }
    .theme-colour-green & {
      background-color: @green;
    }
  }
}

// Hide progress dots in onboarding on mobile devices (not enough space)
@media @mobile {
  .progress-dots {
    display: none;
  }
}

// When the 'display password' button is clicked we need to display
// a timer value within it.
button[data-actionclick='startViewPasswordTimer'] {
  font-size: 1.1em;
  width: 50px;
  color: inherit;
}

.row.disabled > * {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.theme-dark .row.disabled:hover,
.theme-light .row.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.icn-big {
  width: 100%;
  .f-icn {
    background-size: contain;
    width: 100%;
    .px(height, 67px);
  }
}

.info-list-row {
  font-size: @19px;
  line-height: @24px;
  padding-bottom: @6px;
  padding-top: @6px;
  border: none;
}

// Ensure screens during create-account are 100% height
.overlay .section-pane-wrapper {
  max-height: 100%;
  max-width: 100%;

  // Give the content (excluding padding) a maximum width of 812px.
  @maxWidth: @440px;
  .section-pane {
    padding-left: ~'calc(50vw - @{maxWidth}/2)';
    padding-right: ~'calc(50vw - @{maxWidth}/2)';
  }
}

body {
  position: fixed;
}

// Ensure the forghetti icon in the Updates button is white when the button
// has been selected.
li.active .f-icn-forghetti-icon {
  .background-image-white(forghetti-icon);
}

.appstore-link,
.playstore-link {
  flex: 1;
  text-align: center;
  img {
    width: 200px;
    max-width: 90%;
  }
}

// Hide help links unless the <body> has a userprefs-tooltips class, to indicate that tooltips
// are enabled in the user preferences
.help-link {
  display: none;
}
.userprefs-tooltips .help-link {
  display: block;
}
// Hide help links if they don't have a data-id
.help-link:not([data-id]) {
  display: none;
}

.section-pane-nav .nav-link {
  @media @desktop {
    flex-basis: 50%;
  }
}

.section-pane-nav__title {
  order: 3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .truncate;
  /*margin-top: -3px;
  margin-bottom: @2px;

  @media @desktop {
    margin-top: -6px;
    margin-bottom: @4px;
  }*/

  h1 {
    order: initial;
    flex-grow: 0;
    flex-basis: auto;
    @media @desktop {
      transform: translate(0px, -4px);
    }
  }

  .help-link {
    width: 0px;
    position: relative;
    left: @15px;
    top: 0;
    transform: translate(0px, 2px);
    @media (max-width: 412px) {
      display: none; // not enough room
    }
  }
}

// On mobile...
@media @mobile {
  // Diplay help link next to page header
  .section-pane-nav .help-link {
    order: 2;
  }
}

// Use coloured icon for help link icons
.f-icn-help-solid {
  .background-image-colour(help-solid);
  width: 13px;
  height: 13px;
  background-size: contain;
  .cta:hover & {
    .background-image-white(help-solid);
  }
}

.tabbed-picker-column {
  flex-grow: 1;
}

.row > .column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  h1,
  h2 {
    flex-grow: 1;
  }
  .column-group {
    min-width: 100%;
  }
  .help-link {
    top: @9px;
  }
}

.row > .column > .column-group:empty {
  //padding-top: 0px;
  display: none;
}

.onboarding-image > .column {
  display: block;
}

.onboarding-image > .column > img {
  width: 100%;

  height: auto;
  max-height: 35vh;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
}
.onboarding-image > .column > img.small {
  max-height: 25vh;
}

.onboarding-image {
  flex-grow: 1;
  flex-basis: 68%;
  display: flex;
  align-items: center;
  &.lochy-loading {
    flex-basis: auto;
  }
  img,
  svg {
    display: block;
    width: 100%;
    max-width: 75%;
    height: auto;
    max-height: 35vh;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    &.small {
      max-height: 25vh;
    }
  }
  > .column {
    .px(padding-top, 10px);
    .px(padding-bottom, 10px);
  }
}

.row > .column {
  & > .primary-heading {
    order: 1;
  }
  & > .secondary-heading {
    order: 2;
  }
  & > .help-link {
    order: 3;
  }
  & > .column-group {
    order: 4;
  }
}

.row.greyed-out {
  opacity: 0.5;
}

#profile-landing-page {
  .banner.loading-safe img {
    display: none;
  }
  .f-icn-forghetti-icon {
    .background-image(forghetti-icon);
  }
}

.login-avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  /*
  #add-service-group & {
    flex: 1 1;
    flex-wrap: wrap;
    min-width: 25%;
    max-width: 25%;
    margin: 0;
    box-sizing: border-box;
    .px(padding-left, 3px);
    .px(padding-right, 3px);
  }
  */
  //flex: 1 1 ;

  .login-avatar-label {
    font-size: 0.9em;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-top: 3px;
    word-break: break-word;
    #main-nav & {
      white-space: nowrap;
    }
  }
}

.big-avatar {
  .login-avatar-inner,
  .login-avatar {
    width: @92px;
    height: @92px;
    @media (min-width: 1025px) {
      width: @120px;
      height: @120px;
    }
  }
}

#create-account-add-login {
  .login-avatar-wrapper {
    width: 17%;
    cursor: pointer;
  }
  .f-icn-add-solid {
    .background-image-colour(add-solid);
  }
}

.search-result-row {
  > .column > .column-group {
    padding: 0 0 !important;
  }
  .login-avatar,
  .login-avatar-inner {
    height: 4rem;
    width: 4rem;
  }
  .login-avatar-wrapper {
    padding: 1rem;
  }
}

.icon-row {
  > .column > .column-group {
    display: flex;
    justify-content: center;
  }
}

[data-slick] {
  margin: auto 0;
}

[data-actionclick='addService_submitUsername'] {
  position: absolute;
  transform: translateX(110vw);
  transition: transform 0.5s;
  .f-icn {
    //transition-delay: scale 1s;
    transform: scale(0.5);
    //transition:  scale 1s;
  }
  //transform: scale(40px)
}
[data-actionclick='addService_submitUsername'].active {
  position: relative;
  transform: translateX(0px);
  transition: transform 0.5s;
  & .f-icn {
    //transition-delay: scale 1s;
    transform: scale(1);
    //transition:  scale 5s;
  }
}

// Styling for dialog-manager popups (i.e. showToastMessage)
.dialogMgr_footer {
  display: none;
}
.dialogMgr_frame {
  background-color: transparent;
  border-radius: 10px;
  text-align: center;
  max-width: 92%; // Prevent the help tips from being wider than the screen
  .theme-dark & {
    background-color: @grey-9;
  }
  .theme-light & {
    background-color: @white-1;
  }
  .primary-heading {
    .px(font-size, 32px);
  }
  .primary-text {
    @media screen and (max-width: 412px) {
      .px(font-size;16px);
      line-height: 1.35;
    }
  }
  .cta-button {
    width: 100%;
    .px(max-width, 380px);
  }
}
.dialogMgr_content {
  max-height: ~'calc(100vh - 8vw)';
}
.dialogMgr_toastBox {
  .theme-dark & {
    background: @grey-9;
  }
  .theme-light & {
    background: @grey-8;
  }
}
.dialogMgr_toastBox,
.dialogMgr_contentPadding {
  .px(padding;24px;24px;28px);
  @media screen and (max-width: 412px) {
    .px(padding;18px;16px;20px);
  }
}
.dialogMgr_toastRoot.verticalAlign {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  font-size: 1rem;
  background-color: rgba(64, 64, 64, 0.5);
  box-sizing: border-box;
  z-index: 990;
  width: 90%;
  margin: auto;
  .truncate;
  .dialogMgr_toastBox {
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  .toast--title {
    display: block;
    font-weight: 700;
    .px(margin-bottom, 5px);
    /*&::first-letter {
      text-transform: capitalize;
    }*/
  }
  .toast--clipboard-item {
    display: block;
    .px(font-size, 20px);
    background: @grey-11;
    padding: 0.2em 0.5em;
    border-radius: 5px;
    + .toast--link {
      .px(margin-top, 15px);
    }
  }
  .toast--text {
    color: @white-1;
    display: block;
    + .toast--text {
      .px(margin-top, 5px);
    }
  }
  .toast--link {
    display: block;
    &:hover {
      opacity: 1;
    }
  }
}

.recents {
  &.row > .column .column-group {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .column {
      flex-grow: 1;
      margin: 0.5rem 0;
      padding: 0;
      font-size: 0.9rem;
      max-width: percentage(1/6);
      &:nth-child(n) {
        @media screen and (max-width: 460px),
          screen and (min-width: 921px) and (max-width: @compactNavBreak),
          screen and (min-width: @defaultNavBreak) and (max-width: 1580px) {
          max-width: percentage(1/5);
        }
        @media screen and (max-width: 350px), screen and (min-width: @desktopBreak) and (max-width: 920px) {
          max-width: percentage(1/4);
        }
        @media screen and (max-width: 290px) {
          max-width: percentage(1/3);
        }
      }
      &:nth-child(n + 4) {
        @media screen and (max-width: 290px) {
          display: none;
        }
      }
      &:nth-child(n + 5) {
        @media screen and (max-width: 350px) {
          display: none;
        }
        @media screen and (min-width: @desktopBreak) and (max-width: 920px) {
          display: none;
        }
      }
      &:nth-child(n + 6) {
        @media screen and (max-width: 460px) {
          display: none;
        }
        @media screen and (min-width: 921px) and (max-width: @compactNavBreak) {
          display: none;
        }
        @media screen and (min-width: @defaultNavBreak) and (max-width: 1580px) {
          display: none;
        }
      }
    }
    // .login-avatar-inner,
    // .login-avatar {
    //   width: 100%;
    //   height: 100%;
    // }
    // .login-avatar-inner {
    //   padding: 6px 21px;
    // }
    .login-avatar-wrapper {
      cursor: pointer;
    }
    .login-avatar-inner,
    .login-avatar {
      //width: 4rem;
      //height: 4rem;
    }
    .login-avatar-label {
      max-width: 90%;
    }
  }
}

/*
Updates HTML / Landing Page (and profile page)
*/
.banner.image-wrapper {
  box-sizing: border-box;
  width: 100%;
  transition: transform 350ms ease;
  transform: scale(1);

  flex-grow: 1;
  &:nth-of-type(1) {
    display: flex;
    align-items: flex-end;
  }
  &:nth-of-type(2) {
    display: flex;
    align-items: flex-start;
  }

  &:hover:not([banner-context='menu']):not([banner-context='profile']):not([banner-context='landingPage']) {
    transform: scale(1.03);
  }
  + .banner.image-wrapper {
    .px(margin-top, 16px);
  }
  .image-wrapper--inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: percentage(273/650);
    overflow: hidden;
    border-radius: 0.5em;
    /*#pane-2 &,
    #profile-landing-page & {
      @media @desktop {
        padding-bottom: percentage(692/1024);
      }
    }*/
    [banner-context='landingPage']& {
      transition: transform 350ms ease;
      transform: scale(1);
      &:hover {
        transform: scale(1.03);
      }
    }
    &[data-id='your_journey'] {
      height: auto;
      padding-bottom: initial;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
#landing-page {
  &.loading-landing-page .banner {
    display: none;
  }
  .section-pane-header {
    display: none; //Would be better if this did not exist...
  }
  .table-view,
  .banner-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
  .banner-wrapper {
    padding: 0 @hori-margin / 2;
  }
  .table-view {
    .column:first-child:last-child {
      flex-grow: 0;
    }
  }
}

.spaghetti-bg {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.green-bg {
  background-color: @green;
  &.spaghetti-bg {
    background-image: url(../img/spaghetti-green.svg);
  }
}
.blue-bg {
  background-color: @blue;
  &.spaghetti-bg {
    background-image: url(../img/spaghetti-blue.svg);
  }
}
.pink-bg {
  background-color: @red;
  &.spaghetti-bg {
    background-image: url(../img/spaghetti-pink.svg);
  }
}
.orange-bg {
  background-color: @orange;
  &.spaghetti-bg {
    background-image: url(../img/spaghetti-orange.svg);
  }
}
.yellow-bg {
  background-color: @yellow;
  &.spaghetti-bg {
    background-image: url(../img/spaghetti-yellow.svg);
  }
}
.grey-bg {
  background-color: @grey-1;
  &.spaghetti-bg {
    background-image: url(../img/spaghetti-grey.svg);
  }
}
.banner {
  color: #f8fafa;
  @media (min-width: 813px) {
    .content-block-wrapper {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  @media (max-width: 412px) {
    .content-block-wrapper {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  .content-block {
    padding: 1.5rem 1.25rem 1.85rem; /* 30px @ 100%=30 x 0.0625 */
    text-align: center;
    border-radius: 0.5em;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInSlideUp;
    animation-name: fadeInSlideUp;
    overflow: hidden;
  }
}

//badge to be displayed next to premium user avatar
.avatar-badge-premium:after {
  content: '';
  position: absolute;
  right: -5px;
  bottom: -10px;
  height: 2rem;
  width: 2rem;
  background-size: 2rem 2rem;

  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url('../img/icons/forghetti-ui_annual_shield_normal.svg');
}

.login-avatar-wrapper.disabled {
  opacity: 0.7;
}

.label {
  font-size: 0.6rem;
}

//Animate the loading of the content on these pages
#login-list {
  .login-list-page__header,
  .search__wrapper,
  .alpha-list {
    transform-origin: 50% 50%;
    animation: 500ms ease fadeInSlideUp;
    animation-fill-mode: both;
  }
  .login-list-wrapper {
    animation: none;
    opacity: 1;
  }
  > * {
    //Apply increasing animation delay up to a specific nth-child
    .delay(@nthChild; @increment; @index: 1) when (@index =< @nthChild) {
      .delay(@nthChild; @increment; (@index + 1));
      &:nth-child(@{index}) {
        animation-delay: 35ms + @increment * @index;
      }
    }
    .delay(2, 35ms); //Up to nth-child(2)
  }
  .alpha-list {
    //Apply increasing animation delay up to a specific nth-child
    .delay(@nthChild; @increment; @index: 1) when (@index =< @nthChild) {
      .delay(@nthChild; @increment; (@index + 1));
      &:nth-child(@{index}) {
        animation-delay: 35ms * 2 + 35ms + @increment * @index;
      }
    }
    .delay(27, 35ms); //Up to nth-child(27)
  }
}
#groups-list,
#profile-landing-page,
#healthcheck_welcome {
  .section-pane-header,
  .table-view > * {
    transform-origin: 50% 50%;
    animation: 500ms ease fadeInSlideUp;
    animation-fill-mode: both;
  }
  .table-view > * {
    //Apply increasing animation delay up to a specific nth-child
    .delay(@nthChild; @increment; @index: 1) when (@index =< @nthChild) {
      .delay(@nthChild; @increment; (@index + 1));
      &:nth-child(@{index}) {
        animation-delay: 35ms + @increment * @index;
      }
    }
    .delay(15, 35ms); //Up to nth-child(15)
  }
}

#groups-list {
  .table-view {
    .px(padding-bottom, 134px);
    @media @mobile {
      .px(padding-bottom, 10px);
    }
  }

  .groups-shared-by-heading {
    .primary-text-content {
      .h1;
      .px(font-size, 14px) !important;
      .px(line-height, 14px);
    }
    .secondary-text-content {
      .h1;
      .px(font-size, 22px) !important;
      line-height: @18px * (52/32);
      text-transform: none;
      color: @grey-1;
      .theme-dark & {
        color: @white-1;
      }
    }
  }
}
.loading-wheel,.loading-nav-badges {
  background-size: 38px 38px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  .px(min-height, 16px + 38px + 16px);

  .theme-light & {
    background-image: url(../img/loading-dark.svg);
  }
  .theme-dark & {
    background-image: url(../img/loading.svg);
  }
}
.loading-safe {
  background-size: 180px 180px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  .px(min-height, 16px + 180px + 16px);
  background-image: url(../img/badges/active/11.gif);
}
.image-uploader__main.busy .tick i.f-icn.f-icn-check-solid {
  .loading-wheel;
}
.centered {
  justify-content: center;
}

// #pane-2 .section-pane:not(#view-profile-account) .suggested-add-button .icn-button {
//   &:before {
//   display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-color: var(--theme-colour);
//     border-radius: 999px;
//   content: "";
//   }
//   .theme-dark.theme-colour-green &  , .theme-dark.theme-colour-yellow & {

//     .f-icn.f-icn-add {
//       background-image: url('../img/icons/colour-1/forghetti-ui_add_normal.svg');
//     }
//   }

// }
