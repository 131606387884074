.primary-heading {
  .h1;
  font-size: @26px;
  .text-input {
    font-size: @26px;
    line-height: @35px;
    height: @35px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
  }
  @media @mobile {
    font-size: @26px;
  }
}

.column.primary-heading {
  margin: 0;
  flex: 1;
  text-align: left;
  align-items: center;
}

.row > .column > .primary-heading {
  width: 100%;
  padding-top: @16px;
  text-align: center;
  margin: 0;
}

.align-left.row > .column > .primary-heading {
  text-align: left;
}
