.table-view {
  display: flex;
  flex-direction: column;

  flex: 1;
  padding: @34px @hori-margin @33px;
  overflow: scroll;

  /*@media @desktop {
    padding-top: @34px;
  }*/
  @media (min-width: 1025px) {
    padding-left: @hori-margin*1.5;
    padding-right: @hori-margin*1.5;
  }
  @media (max-width: 412px) {
    padding-left: @hori-margin / 2;
    padding-right: @hori-margin / 2;
  }
  header + & {
    padding-top: 0;

   // .px(margin-top, 80px);
  }
  > .row > .column {
    margin-left: -@hori-margin / 2;
    margin-right: -@hori-margin / 2;
    .px(margin-bottom,-1px);
    border-radius: @hori-margin / 2;
    > .column-group > .icn-button:first-child {
      margin-left: -@10px;
    }
  }
}
.row {
  .thin-border;
  .px(margin-bottom,-1px);
  &.no-border,
  &:last-child {
    border: 0;
  }
  &.grow {
    flex-grow: 1 !important;
  }
  &.no-grow {
    flex-grow: 0 !important;
  }
  &.space-above {
    .px(margin-top,10px);
  }
}
.row-group {
  .thick-border;
  &:last-child {
    border: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    .row {
      &:last-child {
        .thin-border;
      }
      &.no-border {
        border: 0;
      }
    }
  }
}
.column {
  //padding: 0 @hori-margin/2 0 @10px;
  padding: 0 @hori-margin / 2 0 @hori-margin;
  transition: background-color 200ms ease;
  flex-grow: 1;
  /*@media @mobile {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }*/
  &:first-child:last-child {
    flex-grow: 1;
  }
  &.icn-button {
    flex-grow: 0;
  }
  &.text-button {
    flex-grow: 0;
    .theme-colours(color);
  }
  &.info-text {
    flex-grow: 0;
  }
  &.primary-heading {
    margin: 0;
    flex: 1;
    text-align: left;
    align-items: center;
  }
  &.service-heading {
    display: flex;
    align-items: center;
    .secondary-heading {
      padding: 0;
    }
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

  }
  &.toggle-switch {
    flex-grow: 0;
    display: flex;
    align-items: center;
  }
  &.badge-wrapper {
    flex-grow: 0;
  }
  &.small-input {
    flex-grow: 0;
    .px(min-width,100px);
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -@10px;
      right: 0;
      bottom: -@10px;
      left: @5px;
      border-radius: @4px;
      background-color: transparent;
      transition: background-color 200ms ease, top 200ms ease, bottom 200ms ease;
      .focussed& {
        top: 0;
        bottom: 0;
      }
    }
    > * {
      position: relative;
    }
  }
  + .column:not(.icn-button):not(.banner) {
    padding-left: @5px;
  }
  .column {
    padding: 0;
    .column {
      padding: 0;
    }
  }
  .button-text & {
    padding-left: 0;
    .column:nth-child(2) {
      padding-left: @5px;
    }
  }
}
.column-group {
  display: flex;
  flex-direction: row;
  min-width: 0;
  //align-items: center;
  &.button-group {
    justify-content: center;
  }
  .icn-button,
  .text-button {
    margin-top: -@10px;
    margin-bottom: -@10px;
  }
  + .column-group {
    padding-top: @19px;
  }
  .row[data-actionclick='switchToGroup'] > .column > & {
    align-items: center;
    .primary-text {
      overflow-wrap: normal;
    }
    .secondary-text {
      .truncate;
      display: block;
    }
  }
}
.row:not(.doodlepad-buttons) > .column > .column-group {
  padding-bottom: @16px;
}
.row:not(.doodlepad-buttons) {
  > .column > .column-group:first-child:not(:last-child) {
    padding-bottom: @10px;
  }
  &.loading-password > .column > .column-group:first-child:not(:last-child) {
    padding-bottom: @16px;
  }
}
.row:not(.doodlepad-buttons) > .column > .column-group,
.row > .column > .secondary-heading {
  padding-top: @16px;
}
.row:not(.doodlepad-buttons) > .column > .column-group + .column-group,
.row > .column > .secondary-heading + .column-group,
.row.has-large-avatar > .column > .column-group {
  padding-top: 0;
}
.row.has-large-avatar > .column > .column-group {
  padding-bottom: 0;
}
.row > .column > .secondary-heading:first-child:last-child {
  padding-top: @25px;
  padding-bottom: @7px;
}
label {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}
.section-pane-wrapper [data-nav],
.section-pane-wrapper label {
  cursor: pointer;
  transition: background-color 200ms ease;
  &.focussed {
    background-color: transparent;
  }
}
.row[data-nav]:not(.cta),
label:not(.search) {
  @media (hover: hover) {
    &:hover {
      background-color: @grey-7;
      &.focussed {
        background-color: transparent;
      }
      &.small-input {
        background: none !important;
        &:before {
          background-color: @grey-7;
        }
      }
    }
  }
}
.focussed {
  .icn-button {
    display: none;
  }
}
.current-focus {
  &:hover {
    background: none;
  }
}
.editable {
  .icn-button {
    will-change: opacity;
    transition: opacity 300ms ease;
    opacity: 0;
  }
  .text-input {
    will-change: width, height, text-indent;
    transition: width 300ms ease, height 300ms ease, text-indent 300ms ease;
  }
  &:hover {
    .icn-button {
      opacity: 1;
      transition: opacity 200ms ease;
    }
    .text-input {
      cursor: pointer;
      //color: @orange;
      &:focus {
        cursor: text;
        color: @grey-1;
      }
    }
  }
}
.cta {
  cursor: pointer;
  padding: @3px 0;
  .theme-colours(color);
  &:hover {
    > .column {
      .theme-colours(background-color);
    }
    .theme-light & {
      color: @white-1;
    }
  }
  &.red {
    .theme-light & {
      color: @ios-red;
    }
    &:hover {
      > .column {
        background-color: @ios-red;
      }
      .theme-light & {
        color: @white-1;
      }
    }
  }
  > .column {
    border-radius: 999px !important;
  }
}
.has-large-avatar {
  > .column {
    padding: 0;
    > .column-group > .column {
      padding: @19px @hori-margin / 2;
      &:first-child {
        padding-left: @10px;
      }
      &.primary-heading,
      &.primary-text {
        display: flex;
        align-items: center;
      }
    }
  }
  .login-avatar-inner {
    &:after {
      width: @60px;
      height: @60px;
      @media (min-width: 1025px) {
        width: @80px;
        height: @80px;
      }
    }
  }
  .login-avatar-inner,
  .login-avatar {
    width: @60px;
    height: @60px;
    @media (min-width: 1025px) {
      width: @80px;
      height: @80px;
    }
    &:hover {
      .theme-colours(border-color);
    }
  }
  .primary-text {
    @media (min-width: 1025px) {
      font-size: @22px;
      line-height: @28px;
    }
  }
  .secondary-text {
    @media (min-width: 1025px) {
      font-size: @18px;
      line-height: @22px;
    }
  }
  .f-icn {
    @media (min-width: 1025px) {
      width: @40px;
      height: @40px;
      line-height: @40px;
      background-size: @40px @40px;
    }
    @media (min-width: 1367px) {
      width: @45px;
      height: @45px;
      line-height: @45px;
      background-size: @45px @45px;
    }
    @media (min-width: 1681px) {
      width: @50px;
      height: @50px;
      line-height: @50px;
      background-size: @50px @50px;
    }
  }
}
.has-small-avatar {
  .login-avatar-inner {
    width: @32px;
    height: @32px;
    padding: @2px;
    margin-top: -@4px;
    margin-bottom: -@4px;
    &:after {
      display: none;
    }
  }
  .login-avatar {
    width: @28px;
    height: @28px;
  }
  .column:nth-child(2) {
    padding-left: @10px;
    .has-status& {
      padding-left: @16px;
    }
  }
}
.has-cta-button {
  border: 0 !important;
  min-height: @17px + @19px + (1.15 * @21px);
  &:first-child:not(:last-child) {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  &:last-child,
  .overlay & {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }
  + button.text-button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    .px(padding-top,24px);
    .px(padding-bottom,20px);
    text-align: center;
    &:last-child:not(:first-child) {
      .px(padding-bottom,10px);
    }
  }
  a {
    transition: none;
    &:hover {
      opacity: 1;
    }
  }
}
.status {
  display: block;
  position: absolute;
  width: @20px;
  height: @20px;
  top: @2px;
  right: -@6px;
  border-radius: 999px;
  background-color: @grey-1;
  background-size: @14px @14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  &[data-status='secure'] {
    .background-image-reverse(lock);
  }
}

// Hover background colour
.row[data-actionclick]:not(.cta) {
  @media (hover: hover) {
    &:hover > .column {
      background-color: @grey-7;
      .theme-dark & {
        background-color: @grey-8;
      }
    }
  }
}
.row[data-nav]:not(.cta),
label:not(.search) {
  @media (hover: hover) {
    &:hover {
      background-color: @grey-7;
      .theme-dark & {
        background-color: @grey-8;
      }
    }
  }
}
