:root {
  --nav-width: @280px;
}
#main-nav {
  flex-shrink: 0;
  display: flex;
  color: @white-1;
  @media @compactNav {
    .px(min-width,74px);
    .px(max-width,74px);
  }
  @media @defaultNav {
    min-width: var(--nav-width);
    max-width: var(--nav-width);
  }
  @media @mobile {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: fade(@grey-8,65%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 350ms ease;
      z-index: 4;
      .menu-open & {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .theme-light & {
    @media @desktop {
      //border-right: lighten(@grey-1,50%) 1px solid;
    }
  }
  .main-nav--inner {
    position: relative;
    width: var(--nav-width);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: @grey-1;
    @media @mobile {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1000;
      width: 280px;
      transform: translateX(calc(-1 * 280px));
      .menu-open & {
        transform: translateX(0px);
      }
      .opening &,
      .closing & {
        transition: transform 0.2s ease-out;
      }
    }
    @media @compactNav {
      .px(width,74px);
    }
    @media screen and (max-width: 412px) {
      width: calc(100vw - 50px);
      transform: translateX(calc(-1 * (100vw - 50px)));
    }
    h1 {
      position: relative;
      margin-bottom: 0;
      padding: @14px @17px @14px;
      font-size: @22px;
      line-height: @22px * (52/32); //Global H1 line-height/font-size
      @media @compactNav {
        display: none;
      }
    }
  }
  [data-notification] {
    top: @6px;
  }
}
.main-nav__middle {
  position: relative;
  @media @mobile {
    display: none;
  }
  &:before {
    // This is the thin border at the bottom
    // It is applied as a psuedo element so that the scrollbar can go the edge of #main-nav
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    right: @7px;
    left: @7px;
    height: @1px;
    background-color: var(--border-colour);
    .theme-light & {
      background-color: lighten(@grey-1,15%);
    }
  }
  > .row {
    border: 0;
  }
  [data-template="newGroup"] {
    & .secondary-heading {
      text-align: center;
      .theme-colours(color);
    }
  }
  .f-icn-sharing {
    .theme-light & {
      background-image: url('../img/icons/colour-3/forghetti-ui_sharing_normal.svg');
    }
  }
  .f-icn-profile {
    .theme-light & {
      background-image: url('../img/icons/colour-3/forghetti-ui_profile_normal.svg');
    }
  }
  .f-icn-healthcheck {
    .theme-light & {
      background-image: url('../img/icons/colour-3/forghetti-ui_healthcheck_normal.svg');
    }
  }
}
.main-nav__middle {

}
.main-nav__middle,
.main-nav__bottom {
  padding-top: @10px;
  padding-bottom: @10px;
  > .row {
    > .column > .column-group {
      .px(padding-top,12px);
      .px(padding-bottom,12px);
      .icn-button {
        margin-left: 0;
        .f-icn {
          .px(background-size;28px;28px);
        }
      }
    }
  }
  > .row:not(.main-nav__banner):not(.main-nav__badges) {
    position: relative;
    cursor: pointer;
    padding-left: @14px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: @14px;
      border-radius: 999px 0 0 999px;
      transition: opacity 0.2s ease, background-color 0.2s ease;
      opacity: 0;
      background-color: darken(@grey-1, 8%);
      background-color: @grey-10;
    }
    @media (hover: hover) {
      &:hover {
        background: none !important;
        &:before {
          opacity: 1;
        }
        .column {
          background: none !important;
        }
      }
    }
    > .column {
      position: relative;
      padding-left: 0;
    }
    .primary-text-content {
      user-select: none;
      font-size: 1rem;
    }
  }
  .text-group {
    @media @compactNav {
      display: none;
    }
  }
}
.main-nav__bottom {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 0;
  @media @compactNav , @mobile {
    padding-top: 0;
    &:before {
      // This is the thin border at the top
      // It is applied as a psuedo element so that the scrollbar can go the edge of #main-nav
      content: "";
      display: block;
      position: absolute;
      right: @7px;
      top: 0;
      left: @7px;
      height: @1px;
      background-color: var(--border-colour);
      .theme-light & {
        background-color: lighten(@grey-1,15%);
      }
    }
  }
  #main-nav-banner {
    flex-grow: 1;
    padding-left: 0;
    display: flex;
    align-items: flex-end;
    padding-bottom: @18px;
    border: 0;
    position: relative;
    //min-height: 104px;
    height: 0;
    padding: 0;
    &:after {
      // This is the thin border at the bottom
      // It is applied as a psuedo element so that the scrollbar can go the edge of #main-nav
      content: "";
      display: block;
      position: absolute;
      right: @7px;
      bottom: 0;
      left: @7px;
      height: @1px;
      background-color: var(--border-colour);
      .theme-light & {
        background-color: lighten(@grey-1,15%);
      }
    }
    @media @compactNav {
      display: none;
    }
    @media @mobile {
      display: none;
    }
    .column {
      display: none;
      padding-left: @hori-margin;
      padding-right: @hori-margin;
      .image-wrapper {
        width: 100%;
        .image-wrapper--inner {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: percentage(273/650);
          overflow: hidden;
          border-radius: 0.5em;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  #main-nav-badges {
    border: 0;
    position: relative;
    background: none;
    min-height: 78px;
    &:after {
      // This is the thin border at the bottom
      // It is applied as a psuedo element so that the scrollbar can go the edge of #main-nav
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: @7px;
      left: @7px;
      height: @1px;
      background-color: var(--border-colour);
      .theme-light & {
        background-color: lighten(@grey-1,15%);
      }
    }
    @media (hover: hover) {
      &:hover {
        background: none;
      }
    }
    .column .column-group {
      flex-wrap: wrap;
      justify-content: flex-start;
      .badge-wrapper {
        flex-grow: 1;
        flex-basis: percentage(1/5);
        max-width: percentage(1/5);
        @media screen and (min-width: 381px) and (max-width: 412px) {
          flex-basis: percentage(1/6);
          max-width: percentage(1/6);
        }
        @media @compactNav {
          flex-basis: 100%;
          max-width: 100%;
          &:nth-child(n+2) {
            display: none;
          }
        }
        @media (hover: hover) {
          &:hover img {
            transform: scale(1.2);
            opacity: 1;
          }
        }
        + .badge-wrapper {
          padding: 0;
        }
        img {
          transform: scale(1);
          transform-origin: 50% 50%;
          transition: opacity 350ms ease~","transform 200ms ease;
        }
      }
    }
  }

  .row:last-child {
    margin-top: @10px;
  }
  .f-icn-exit {
    .theme-light & {
      background-image: url('../img/icons/colour-3/forghetti-ui_exit_normal.svg');
    }
  }
}

#menu-icon {
  padding: @33px @23px;
  height: @24px;
  width: @24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url('../img/icons/menu-dark.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: @24px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  @media @mobile {
    padding-top: @20px;
    padding-bottom: @20px;
  }
  @media (max-width: 640px) {
    padding-top: @19px;
    padding-bottom: @19px;
  }
}
