.row-nav {
  display: flex;
  @media @desktop {
    flex-wrap: wrap;
  }
  @media @mobile {
    flex-direction: row;
    align-items: center;
    //padding-bottom: @16px;
  }
  &__left {
    text-align: left;
    order: 1;
    padding-right: 1rem;
  }
  &__right {
    text-align: right;
    order: 2;
    @media @mobile {
      order: 3;
    }
    padding-right: 1rem;
  }
  &__link {
    flex: 1;
    align-items: flex-start;
    font-size: @21px;
    .theme-colours(color);
    @media (max-width: 640px) {
      font-size: @17px;
    }
  }
}

.row.mergeWithNextRow {
  border-bottom: none;
  & > .column > .column-group {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}

.row .hidden {
  opacity: 0;
}
