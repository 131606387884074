/* This is how you would create a custom secondary theme on top of tooltipster-noir: */
@errorColour: #e42020;

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-error .tooltipster-box {
  background: @errorColour;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-error.tooltipster-top .tooltipster-arrow-border {
  border-top-color: @errorColour;
}

.column input.text-input.field-in-error {
  border-color: @errorColour;
  box-shadow: inset 0 0 0 1pt @errorColour;
}
