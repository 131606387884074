.progressbar {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
  width: 90%;
  height: 4vh;
  margin: 0 auto;
  //border-width: 1px 1px 1px 0;
  //border-style: solid;
  //border-color: lighten(@white-1,80%);
  &:first-child {
    border-left-width: 1px;
    &::-webkit-progress-bar,
    &::-webkit-progress-value {
      border-radius: 0.25em 0 0 0.25em;
    }
  }
  &:last-child {
    &::-webkit-progress-bar,
    &::-webkit-progress-value {
      border-radius: 0 0.25em 0.25em 0;
    }
  }
  &::-webkit-progress-bar {
    .theme-light & {
      background-color: @grey-7;
    }
    .theme-dark & {
      background-color: @grey-11;
    }
  }
  &::-webkit-progress-value {
    .theme-colours(background-color);
  }
  + .progressbar {
    border-left: 1px solid lighten(@white-1,80%);
    .theme-dark & {
      border-color: @grey-10;
    }
  }
}
