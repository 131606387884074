.text-button-left:extend(.text-button) {
	text-align: left;
}
.column {
	&.text-button-left {
		.theme-colours(color);
  }
}
.column-group {
	.text-button-left {
    margin-top: -@10px;
    margin-bottom: -@10px;
  }
}
.text-button {
  &.column.highlight  {
    .theme-colours(background-color);
    color: @grey-1;
    padding-left: 1.5rem;
    margin: auto;
    padding-right: 1.5rem;
    height: 50%;
    border-radius: 2rem;
  }
  &.column.highlight__disabled  {
    .theme-dark & {
      background-color: @grey-11;
      color: @white-1;
      padding-left: 1.5rem;
      margin: auto;
      padding-right: 1.5rem;
      height: 40%;
      border-radius: 2rem;
      cursor: auto;
      opacity: 0.6;
    }
  }
}
