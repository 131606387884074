.section-pane-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  /*@media @mobile {
    //min-height: calc(100vh - 60px);
    min-height: ~'calc(100% - @{60px})';
  }*/
  main& {
    @media @compactNav {
      max-width: calc(100% - 74px);
    }
    @media @defaultNav {
      max-width: calc(100% - var(--nav-width));
    }
  }
}

.section-pane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform;
  z-index: 0;
  transition: transform 500ms cubic-bezier(0.62, 0.02, 0.34, 1) 50ms;
  display: flex;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  box-sizing: border-box;
  &.active {
    z-index: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  &.right,
  + .section-pane:not(.right):not(.left):not(.active) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: transform 800ms cubic-bezier(0.62, 0.02, 0.93, 0.82) 50ms;
  }
  &.left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 800ms cubic-bezier(0.62, 0.02, 0.93, 0.82) 50ms;
  }
  &#verify-device-1 {
    transition: none !important;
    -webkit-transform: none !important;
    transform: none !important;
  }
  &#pane-1 {
    @media @mobile {
      transition: none !important;
      -webkit-transform: none !important;
      transform: none !important;
    }
  }
  &#pane-2 {
    @media @mobile {
      z-index: 2;
      -webkit-transform: translateX(0) translateY(100%);
      transform: translateX(0) translateY(100%);
      transition: transform 400ms ease 50ms;
      &.active {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        transition: transform 600ms ease 50ms;
      }
    }
    [data-activate-pane] {
      @media @desktop {
        display: none;
      }
    }
  }
  //OVERRIDE FOR TESTING
  &#pane-2 {
    @media @mobile {
      z-index: 3;
      -webkit-transform: translateX(0) translateY(0);
      transform: translateX(0) translateY(0);
      transition: transform 400ms ease 50ms;
    }
  }
  &#pane-3 {
    z-index: 3;
    -webkit-transform: translateX(0) translateY(100%);
    transform: translateX(0) translateY(100%);
    transition: transform 400ms ease 50ms;
    @media @desktop {
      z-index: 2;
      left: auto;
      width: 55%;
    }
    @media (min-width: 1681px) {
      width: 50%;
    }
    &.active {
      -webkit-transform: translateX(0) translateY(0);
      transform: translateX(0) translateY(0);
      transition: transform 600ms ease 50ms;
    }
  }
  &#pane-1,
  &#pane-2 {
    @media @desktop {
      position: relative;
      transition: none;
      transform: none;
    }
  }
  .theme-light & {
    background-color: @white-1;
  }
  .theme-dark & {
    background-color: @grey-5;
  }
}
.section-pane-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  overflow-y: hidden;

  //border-radius: @16px;
  @media (min-width: 1025px) {
    //width: 376px;
  }
}

.section-pane-header {
  padding: @16px @hori-margin 0;
  box-sizing: border-box;
  //position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  .theme-light & {
    background-color: @white-1;
  }
  .theme-dark & {
    background-color: @grey-5;
  }
  @media screen and (min-width: 1025px) {
    padding-top: @16px;
    padding-right: @hori-margin*1.5;
    padding-left: @hori-margin*1.5;
    padding-bottom: 0;
  }
  @media (max-width: 412px) {
    padding-left: @hori-margin / 2;
    padding-right: @hori-margin / 2;
    padding-top: @6px;
  }
  h1 {
    text-align: center;
    margin: 0;
    @media @mobile {
      font-size: @24px;
    }
    @media (max-width: 640px) {
      font-size: @21px;
    }
  }
}

.section-pane-header {
  .section-pane-nav {
    .nav-link {
      .px(min-width, 80px);
      .px(min-height, 27px);
      + .section-pane-nav__title {
        .px(padding-top, 10px);
      }
    }
  }
}
.section-pane.no-title {
  & .section-pane-header {
    min-height: 0px;
  }
  .section-pane-nav {
    border: none;
    min-height: 0px;
  }
}
.section-pane.no-border {
  & .section-pane-header {
    border: none;
  }
  .section-pane-nav {
    border: none;
  }
}
.section-pane.display-none {
  & .section-pane-header {
    display: none;
  }
  .section-pane-nav {
    display: none;
  }
}
.section-pane.visibility-hidden {
  & .section-pane-header {
    visibility: hidden;
  }
  .section-pane-nav {
    visibility: hidden;
  }
}


.section-pane-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  overflow-y: hidden;
  
  //border-radius: @16px;
  @media (min-width: 1025px) {
    //width: 376px;
  }
}


#pane-1 {
  .section-pane-header {
    .nav-left,
    .nav-right {
      min-height: @27px;
    }
    .section-pane-nav__title {
      //margin-top: -8px;
      @media (max-width: 640px) {
        //margin-top: 0px;
      }
    }
  }
}

.section-pane-nav {
  min-height: @40px;
  .thick-border;
  display: flex;
  @media @desktop {
    flex-wrap: wrap;
  }
  @media @mobile {
    flex-direction: row;
    align-items: center;
    padding-bottom: @16px;
  }
  @media (max-width: 640px) {
    padding-bottom: @4px;
  }
  .nav-left {
    text-align: left;
    order: 1;
    padding-right: @hori-margin;
  }
  .nav-right {
    text-align: right;
    order: 2;
    padding-left: @hori-margin;
    @media @mobile {
      order: 3;
    }
  }
  .nav-link {
    flex: 1;
    @media (max-width: 640px) {
      font-size: @17px;
    }
  }
  h1 {
    order: 3;
    flex: 100%;
    transform: translate(0px, 0px);
    .truncate;
    @media @mobile {
      flex: 1;
      order: 2;
    }
  }
}
