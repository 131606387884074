#pane-1 {
  /*position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;*/
  width: 45%;
  z-index: 3;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.25);
  background-color: @white-1;
  @media (min-width: @desktopXLargeHoriBreak) {
    width: 50%;
  }
  @media @mobile {
    width: 100%;
    overflow: hidden;
  }
}

#pane-1-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  animation: fadeInSlideUp 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms forwards;
}

.recent {
  /* **************** */
  display: none;
  /* **************** */
  box-sizing: border-box;
  padding: @16px @hori-margin @6px @hori-margin;
  opacity: 0;
  animation: fadeInSlideUp 400ms cubic-bezier(0.25, 0.1, 0.25, 1) 300ms forwards;
  @media @mobile {
    display: none;
  }
  h2 {
    margin: 0 0 @16px 0;
  }
  ul {
    overflow: hidden;
    width: 100%;
    height: @46px + @16px + @4px + @11px;
    padding: 0 (@hori-margin*1.5)- ((@hori-margin)+ ((@78px)- (@60px))/2);
    @media (min-width: 1025px) {
      padding: 0 (@hori-margin*2)- ((@hori-margin)+ ((@78px)- (@60px))/2);
    }
    @media (min-width: 1025px) {
      height: @60px + @16px + @4px + @11px;
    }
    li {
      display: inline-block;
      box-sizing: border-box;
      width: @70px;
      padding: 0 @6px @16px;
      @media (min-width: 1025px) {
        width: @78px;
      }
      a {
        display: block;
        text-align: center;
        font-size: @11px;
        text-decoration: none;
        .login-avatar {
          display: block;
          margin: 0 auto;
        }
        .login-name {
          display: block;
          padding-top: @4px;
          color: @grey-1;
          .truncate;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}
