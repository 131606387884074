.search {
  z-index: 4;
  height: @62px;
  flex-basis: @62px;
  padding: @9px @hori-margin;
  box-sizing: border-box;
  //opacity: 0;
  //animation: fadeInSlideUp 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 100ms forwards;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  /*.login-list-page & {
    position: relative;
    position: sticky;
    top: @89px;
    @media @mobile {
      top: @60px;
    }
  }*/
  .help-link {
    margin-left: @12px;
  }
  .table-view > & {
    padding-left: 0;
    padding-right: 0;
    &:first-child {
      .px(margin-top,10px);
    }
  }
}

/* Search field in a column */
.row > .column > .column-group > .search {
  flex-grow: 1;
}

.search-input {
  text-transform: none;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: @6px;
  height: @46px;
  line-height: @46px;
  text-indent: @16px;
  background-color: @grey-3;
  //background-image: url('../img/icons/colour-2/forghetti-ui_search_normal.svg');
  background-repeat: no-repeat;
  background-size: @22px @22px;
  background-position-y: center;
  background-position-x: 110%;
  transition: text-indent 200ms ease, background-position 300ms ease 100ms, background-color 300ms ease 100ms;
  border: solid 1px @grey-3;
  & + .search-input-clear {
    /* Now your own custom styles */
    position: absolute;
    top: @9px;
    right: @hori-margin;
    height: @46px;
    width: @46px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: @20px @20px;
    background-position: 50% 50%;
    z-index: 2000;
  }
  &::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
  &::placeholder {
    color: @grey-2;
  }
  // We are using the "required" attribute on the input field to determine if the field is empty
  // for the purposes of hiding the magnifying glass if the field has a value in it
  &:invalid {
    background-position-x: calc(100% - @12px);
  }
  &:focus {
    //box-shadow: inset 0 0 0 1pt darken(@grey-3, 10%);
    transition: text-indent 100ms ease, background-position 100ms ease;
  }
}

.search__wrapper {
  display: flex;
  justify-content: flex-start;
}

.search__wrapper label.search {
  flex-grow: 1;
}
.search__wrapper [data-actionclick] {
  display: none;
  margin-left: -@hori-margin;
  padding-left: @hori-margin;
  padding-right: @hori-margin;
  .theme-colours(color);
  right: 0.1rem;
  .px(min-width,86px);
  overflow: visible;
  position: relative;
  z-index: 4;
  &:before {
    .px(width,62px) !important;
    .px(height,62px) !important;
  }
}

