.list-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  .list-header {
    flex-shrink: 0;
  }
  .search {
    flex-shrink: 0;
  }
  .login-list-wrapper {
    opacity: 0;
    animation: fadeInSlideUp 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms forwards;
    //height: 1px;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
}

// -------------------------------------------------------------------------------------------------
// Group Icon

.login-list-page {
  //padding-top: @4px;
  .login-list-page__header {
    padding-right: @4px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    h1 {
      margin-bottom: 0;
    }
  }

  .login-list-page__group-icon-wrapper {
    height: 100%;
    //width: @64px;
    //margin-left: @8px;
    padding-left: @hori-margin;
    padding-right: @hori-margin*0.75;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-list-page__group-icon {
    width: @36px;
    height: @36px;
    border-radius: 999px;
    border: solid 3px var(--theme-colour);
  }
}

.login-list {
  // This is to prevent the tiny gap you get on high res devices between the logins list and
  // the search section, due to the rem sizing system we are using.
  transform: translateY(-1px);
  .px(padding-bottom,134px);
  @media @mobile {
    .px(padding-bottom,10px);
  }
  .alpha-list-heading {
    position: sticky;
    z-index: 3;
    padding-top: @6px;
    height: @40px;
    box-sizing: border-box;
    padding-left: @hori-margin;
    margin: 0;
    line-height: @34px;
    top: 0;
    background-color: @white-1;
    font-size: @20px;
    @media @mobile {
      top: 0;
      font-size: @16px;
    }
    @media (max-width: 640px) {
      height: @30px;
      padding-top: @4px;
      line-height: @26px;
      font-size: @14px;
    }
    .theme-dark & {
      background-color: @grey-5;
    }
  }
}
