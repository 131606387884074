/* Emergency plan to prevent people using the web app on mobile devices as there
is an issue with the doodlepad */

/* Allow portrait iPads, but block devices smaller than that */
// @media (max-width: 767px) {
//   #download-app-blocker {
//     display: flex !important;
//   }
// }

#download-app-blocker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: @green;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../img/splash-screen-background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  #download-app-inner {
    width: 100%;
    max-width: @420px;
  }

  .forghetti-logo {
    width: 100%;
    height: auto;
    padding: 0 @50px;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;

    @media (max-height: 420px) and (orientation: landscape) {
      max-width: @380px;
    }
    path {
      fill: @grey-1;
    }
  }
}
