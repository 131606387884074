//This is basically a copy of the login-item.less.
//TODO clean this up.

.import-item {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  list-style-type: none;
  //padding-left: (@hori-margin*1.5)-@7px;
  //@media (min-width: 1025px) {
  //  padding-left: (@hori-margin*2)-@7px;
  //}
  @media (hover: hover) {
    &:hover {
      .import-item-inner {
        background-color: @grey-5;
      }
      .button-group {
        opacity: 1;
      }
    }
  }

}

.import-item-inner {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: @7px;
  background-color: @white-1;
  will-change: background-color;
  transition: background-color 0.1s ease-in;
  border-radius: 999px 0 0 999px;
  .theme-dark & {
    background-color: @grey-5;
  }
  .icn-button {
    //margin: 0;
    .thin-border; // Mixin
  }
}

.import-avatar-wrapper {
  flex: 0 0 @46px;
  padding: @7px 0;
  @media (min-width: 1025px) {
    flex: 0 0 @60px;
  }
}

.import-overview-wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 0; //enable ellipsis text overflow
  .thin-border; // Mixin

  .import-overview {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: column;
    justify-content: center;
    padding: @6px 0 @10px @10px;
    min-width: 0;
    @media (min-width: 1025px) {
      padding-top: @7px;
      padding-bottom: @12px;
    }
  }
  .import-name,
  .import-username {
    margin: 0;
    .truncate;
  }
  .import-name {
    //font-weight: 600;
    font-family: 'Nunito';
    margin-bottom: -1px;
    @media (min-width: 1025px) {
      font-size: @19px;
      line-height: @26px;
      margin-bottom: -@4px;
    }
  }
  .import-username {
    @media (min-width: 1025px) {
      font-size: @16px;
      line-height: @20px;
    }
  }
  .import-overview,
  .button-group {
    padding-left: @10px;
  }
  .button-group {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    padding-right: @10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .button-group-inner {
    display: table;
    height: 100%;
    .icn-button {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      .f-icn {
        position: relative;
        top: auto;
        right: auto;
        display: inline-block;
        @media (min-width: 1025px) {
          width: @40px;
          height: @40px;
          line-height: @40px;
          background-size: @40px @40px;
        }
      }
    }
  }
}
.import-avatar-wrapper {
  position: relative;
  margin-right: @7px;
}
.import-avatar-inner {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.import-avatar-inner,
.import-avatar {
  width: @46px;
  height: @46px;
  border-radius: 50%;
  @media (min-width: 1025px) {
    width: @60px;
    height: @60px;
  }
}

.import-avatar {
  background-color: #fff;
  object-fit: cover;

  .avatar-heading & {
    transform: scale(1);
    transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  .avatar-heading .login-avatar-inner:hover & {
    transform: scale(1.2);
  }
}