.overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  .theme-light & {
    background-color: @white-1;
  }
  .theme-dark & {
    background-color: @grey-5;
  }
  .section-pane-wrapper {
    max-width: 812px;
    max-height: 900px;
    position: relative;
    box-sizing: border-box;
  }
  .large-heading {
    margin-top: @36px;
    margin-bottom: 0;
  }
}
.large-heading {
  font-size: @40px;
  text-align: center;
}
.medium-heading {
  font-size: @26px;
  text-align: center;
}
