@label-length: 4rem;

.profile-badges {
    &.row > .column .column-group {
      padding-top: @11px;
      display: flex;
      flex-wrap: wrap;
      .column {
        flex-grow: 1;
        min-width: percentage(1/6);
        max-width: percentage(1/6);
        margin: 1rem 0;
        padding: 0;
        font-size: 0.9rem;
        @media
          screen and (max-width: 460px),
          screen and (min-width: 921px) and (max-width: @compactNavBreak),
          screen and (min-width: @defaultNavBreak) and (max-width: 1580px) {
            min-width: percentage(1/5);
            max-width: percentage(1/5);
        }
        @media
          screen and (max-width: 350px),
          screen and (min-width: @desktopBreak) and (max-width: 920px) {
            min-width: percentage(1/4);
            max-width: percentage(1/4);
        }
        @media screen and (max-width: 290px) {
          min-width: percentage(1/3);
          max-width: percentage(1/3);
        }
        .badge {
          width: @64px;
          height: @64px;
          //border-radius: 50%;
          transform: scale(1);
          transform-origin: 50% 50%;
          transition: opacity 350ms ease~","transform 200ms ease;
          @media (min-width: 1025px) {
            width: @80px;
            height: @80px;
          }
          @media (hover: hover) {
            &:hover {
              transform: scale(1.2);
              opacity: 1;
            }
          }
        }
      }
    }
}
.badge-wrapper {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  //justify-content: space-around;
  min-width: auto;
  //border-radius: 999px;
  //padding: 0.4rem 1rem 0.6rem 1rem;
  //margin: 1rem auto 0.6rem auto;


  //background-color: #e0e4e4;
  .theme-dark & {
  //  background-color: #161620;
  }

  .badge {

    .px(width,46px);
    .px(height,46px);
    //border-radius: 50%;
    /*@media (min-width: 1025px) {
      width: @46px;
      height: @46px;
    }*/
  }
  .badge.disabled {
    opacity: 0.35;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}




