#splash-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: @green;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../img/splash-screen-background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  #splash-screen-inner {
    width: 100%;
    max-width: @420px;
  }

  .forghetti-logo {
    width: 100%;
    height: auto;
    padding: 0 @50px;
    box-sizing: border-box;
    margin: 0 auto;
    display: block;

    @media (max-height: 420px) and (orientation: landscape) {
      max-width: @380px;
    }
    path {
      fill: @grey-1;
    }
  }

  .loading-msg {
    text-align: center;
    margin-top: @10px;
    opacity: 0.9;
    color: @grey-1;
  }

  .loading-msg-delay {
    text-align: center;
    margin-top: @20px;
    opacity: 0.9;
    color: @grey-1;
  }

  .loading-bar-wrapper {
    width: 100%;
    max-width: @420px;
    margin: @40px auto 0;
    padding: 0 @90px;
    box-sizing: border-box;
    height: 5px;

    .loading-bar {
      width: 100%;
      height: 5px;
      background: rgba(255, 255, 255, 0.25);
      overflow: hidden;
      border-radius: 999px;

      span {
        width: 0%;
        display: block;
        height: 5px;
        background-color: @grey-1;
        // animation: expandWidth 2s ease-in-out;
        transition: width 1s ease-in-out;
      }
    }
  }
}

#account-log-in {
  .icn-button.icn-button-xlrg {
    .px(margin-top,20px);
  }
}
