html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button {
  padding: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  color: #222;
  letter-spacing: 0;
  -webkit-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0 0 1px #00000003;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  transition: margin .3s cubic-bezier(.23, 1, .32, 1);
}

::selection {
  color: #f8fafa;
  text-shadow: none;
  background: #505064;
}

::placeholder {
  color: #b4b4b8;
}

::-webkit-scrollbar {
  width: .4375rem;
  height: .4375rem;
}

::-webkit-scrollbar-button {
  background-color: #f8fafa;
}

::-webkit-scrollbar-track {
  background-color: #f8fafa;
}

::-webkit-scrollbar-track-piece {
  background-color: #f8fafa;
}

::-webkit-scrollbar-thumb {
  background-color: #b4b4b8;
}

::-webkit-scrollbar-corner {
  background-color: #f8fafa;
}

::-webkit-scrollbar-resizer {
  background-color: #f8fafa;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.browserupgrade {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

.theme-colour-blue {
  --theme-colour: #5a96ff;
}

.theme-colour-green {
  --theme-colour: #5af0be;
}

.theme-colour-red {
  --theme-colour: #ff556e;
}

.theme-colour-orange {
  --theme-colour: #ff7d5a;
}

.theme-colour-yellow {
  --theme-colour: #ffeb46;
}

:root {
  --border-colour: #e0e4e4;
}

.theme-dark {
  --border-colour: #30303c;
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInSlideDownAlert {
  0% {
    opacity: 0;
    transform: translateY(-10px)translateX(-50%);
  }

  10% {
    opacity: 1;
    transform: translateY(0)translateX(-50%);
  }

  90% {
    opacity: 1;
    transform: translateY(0)translateX(-50%);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px)translateX(-50%);
  }
}

@keyframes transitionFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes transitionLineIn {
  0% {
    stroke-dashoffset: 25%;
    stroke-dasharray: 0% 50%;
    stroke-width: 5px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 50% 0%;
    stroke-width: 5px;
  }
}

@keyframes circleBlip {
  0% {
    stroke-width: 0;
  }

  50% {
    stroke-width: 2px;
  }

  100% {
    stroke-width: 0;
  }
}

@keyframes revealPassword {
  0% {
    height: 1.6875rem;
  }

  50% {
    height: 1.6875rem;
  }

  100% {
    height: 3.25rem;
  }
}

em {
  color: var(--theme-colour);
  font-style: normal;
}

body {
  font-family: Muli, sans-serif;
  overflow: hidden;
}

body.theme-light {
  color: #505064;
  background-color: #fff;
}

body.theme-dark {
  color: #f8fafa;
  background-color: #202030;
}

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
}

h1 {
  letter-spacing: -.03em;
  margin: 0 0 1.125rem;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }
}

h2 {
  letter-spacing: -.02em;
  font-family: Nunito, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
}

h3 {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.375rem;
}

h4 {
  color: #8e8e93;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1rem;
}

a {
  text-decoration: none;
  transition: opacity .1s;
}

.theme-colour-blue a {
  color: #5a96ff;
}

.theme-colour-green a {
  color: #5af0be;
}

.theme-colour-red a {
  color: #ff556e;
}

.theme-colour-orange a {
  color: #ff7d5a;
}

.theme-colour-yellow a {
  color: #ffeb46;
}

a:hover {
  opacity: .5;
}

p {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

* + p, p + .column-group {
  padding-top: 1.1875rem;
}

p + p {
  padding-top: 1em;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
}

.nav-link {
  font-size: 1.3125rem;
}

.theme-colour-blue .nav-link {
  color: #5a96ff;
}

.theme-colour-green .nav-link {
  color: #5af0be;
}

.theme-colour-red .nav-link {
  color: #ff556e;
}

.theme-colour-orange .nav-link {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-link {
  color: #ffeb46;
}

button.nav-link {
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  outline: none;
  padding: 0;
}

[data-notification] {
  position: relative;
}

[data-notification]:before {
  content: attr(data-notification);
  color: #fff;
  min-width: 1.125rem;
  height: 1.125rem;
  text-align: center;
  box-sizing: border-box;
  background-color: #ff3b30;
  border-radius: 999px;
  padding: 0 .1875rem;
  font-size: .75rem;
  line-height: 1.125rem;
  position: absolute;
  top: 0;
  right: 0;
}

body, html, #body-inner {
  width: 100%;
  height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  flex-direction: column;
  margin: 0;
  display: flex;
}

#body-inner {
  max-width: 1800px;
}

.main {
  flex-direction: row;
  flex: 1;
  display: flex;
  overflow-y: scroll;
}

main {
  box-sizing: border-box;
  overflow: hidden;
}

.theme-light main {
  background-color: #f8fafa;
}

@media (min-width: 413px) {
  main {
    flex-direction: row;
    flex: 1;
    display: flex;
  }
}

.tag {
  border-style: solid;
  border-width: .0625rem;
  border-radius: 999px;
  padding: 0 .4375rem .0625rem;
  font-size: .8em;
  font-weight: 400;
  display: inline-block;
  position: relative;
  top: -.15em;
}

.theme-colour-blue .tag {
  border-color: #5a96ff;
}

.theme-colour-green .tag {
  border-color: #5af0be;
}

.theme-colour-red .tag {
  border-color: #ff556e;
}

.theme-colour-orange .tag {
  border-color: #ff7d5a;
}

.theme-colour-yellow .tag {
  border-color: #ffeb46;
}

.theme-colour-blue .tag {
  color: #5a96ff;
}

.theme-colour-green .tag {
  color: #5af0be;
}

.theme-colour-red .tag {
  color: #ff556e;
}

.theme-colour-orange .tag {
  color: #ff7d5a;
}

.theme-colour-yellow .tag {
  color: #ffeb46;
}

.tag.small {
  padding: 0 .375rem;
  font-size: .6em;
  font-weight: 600;
  line-height: 1.6em;
  top: -.2em;
}

.tag + span, span + .tag {
  margin-left: .3125rem;
}

.login-avatar-wrapper {
  margin-right: .4375rem;
  position: relative;
}

.login-avatar-inner {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.login-avatar-inner, .login-avatar {
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 50%;
  box-shadow: 0 .1875rem .1875rem #5050640f;
}

@media (min-width: 1025px) {
  .login-avatar-inner, .login-avatar {
    width: 3.75rem;
    height: 3.75rem;
  }
}

.login-avatar {
  object-fit: cover;
  background-color: #8080801a;
}

.avatar-heading .login-avatar {
  transition: all .3s;
  transform: scale(1);
}

.avatar-heading .login-avatar-inner:hover .login-avatar {
  transform: scale(1.2);
}

.alert {
  z-index: 99;
  opacity: 0;
  color: #f8fafa;
  white-space: nowrap;
  background-color: #505064e6;
  border-radius: .3125rem;
  padding: 1.25rem 1.875rem;
  font-size: 1.375rem;
  animation: 3s linear 50ms forwards fadeInSlideDownAlert;
  position: absolute;
  top: 1.875rem;
  left: 50%;
  transform: translateY(-10px)translateX(-50%);
}

@media screen and (max-width: 768px) {
  .alert {
    padding: .8125rem 1.4375rem;
  }
}

.no-click {
  pointer-events: none;
}

textarea {
  min-height: 25rem;
  resize: none;
  text-indent: 0 !important;
  padding: 1rem !important;
  line-height: 1.5rem !important;
}

.pre {
  white-space: pre-wrap !important;
}

.forghetti-logo {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 3.125rem;
  display: block;
}

@media (max-height: 420px) and (orientation: landscape) {
  .forghetti-logo {
    max-width: 23.75rem;
  }
}

.forghetti-logo path {
  fill: #f8fafa;
}

.full-height {
  height: 100%;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: start;
}

.link {
  pointer-events: all;
  text-decoration: none;
  color: #5af0be !important;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}

.has-inner-scrollable .table-view {
  height: 100%;
  box-sizing: border-box;
}

.notification-message {
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin: .0625rem;
  display: none;
}

.theme-colour-blue .notification-message {
  color: #5a96ff;
}

.theme-colour-green .notification-message {
  color: #5af0be;
}

.theme-colour-red .notification-message {
  color: #ff556e;
}

.theme-colour-orange .notification-message {
  color: #ff7d5a;
}

.theme-colour-yellow .notification-message {
  color: #ffeb46;
}

.notification-message .theme-light {
  background-color: #202030;
}

.notification-message .theme-dark {
  background-color: #505064;
}

.notification-message .notification-inner {
  color: #f8fafad9;
  width: 100%;
  height: 2.5rem;
  background-color: #252537;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: .3125rem;
  padding: .3125rem;
  display: flex;
  box-shadow: 0 .5rem 1rem #15151fbf;
}

.notification-message .notification-inner .message {
  text-align: center;
  flex-grow: 1;
}

.notification-message .notification-inner .cancel-message {
  cursor: pointer;
}

.theme-colour-blue .notification-message .notification-inner .cancel-message {
  color: #5a96ff;
}

.theme-colour-green .notification-message .notification-inner .cancel-message {
  color: #5af0be;
}

.theme-colour-red .notification-message .notification-inner .cancel-message {
  color: #ff556e;
}

.theme-colour-orange .notification-message .notification-inner .cancel-message {
  color: #ff7d5a;
}

.theme-colour-yellow .notification-message .notification-inner .cancel-message {
  color: #ffeb46;
}

.notification-message.show {
  display: flex;
}

.replay-cursor {
  opacity: 1;
  transition: opacity .3s;
}

.theme-dark .replay-cursor {
  fill: #d8dddd;
}

.theme-light .replay-cursor {
  fill: #2f303b;
}

.otp-field {
  width: 100%;
  justify-content: space-around;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  text-indent: 0 !important;
}

.otp-field input {
  width: 40px;
  min-height: 50px;
  text-align: center;
  background: #21232d;
  border: 2px solid #2f303b;
  font-size: 24px;
  transition: all .1s;
}

.otp-field input:focus {
  border: 2px solid #5af0be;
  box-shadow: 0 0 2px 2px #5af0be;
}

.disabled {
  opacity: .5;
}

.space {
  margin-right: 1rem !important;
}

.context-menu-overlay.active {
  width: 100%;
  height: 100%;
  pointer-events: all;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.section-pane-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  main.section-pane-wrapper {
    max-width: calc(100% - 74px);
  }
}

@media screen and (min-width: 1194px) {
  main.section-pane-wrapper {
    max-width: calc(100% - var(--nav-width));
  }
}

.section-pane {
  will-change: transform;
  z-index: 0;
  transform-origin: 50%;
  box-sizing: border-box;
  transition: transform .5s cubic-bezier(.62, .02, .34, 1) 50ms;
  display: flex;
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.section-pane.active {
  z-index: 1;
  transform: translateX(0);
}

.section-pane.right, .section-pane + .section-pane:not(.right):not(.left):not(.active) {
  transition: transform .8s cubic-bezier(.62, .02, .93, .82) 50ms;
  transform: translateX(100%);
}

.section-pane.left {
  transition: transform .8s cubic-bezier(.62, .02, .93, .82) 50ms;
  transform: translateX(-100%);
}

.section-pane#verify-device-1 {
  transition: none !important;
  transform: none !important;
}

@media screen and (max-width: 768px) {
  .section-pane#pane-1 {
    transition: none !important;
    transform: none !important;
  }

  .section-pane#pane-2 {
    z-index: 2;
    transition: transform .4s 50ms;
    transform: translateX(0)translateY(100%);
  }

  .section-pane#pane-2.active {
    transition: transform .6s 50ms;
    transform: translateX(0)translateY(0);
  }
}

@media screen and (min-width: 769px) {
  .section-pane#pane-2 [data-activate-pane] {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .section-pane#pane-2 {
    z-index: 3;
    transition: transform .4s 50ms;
    transform: translateX(0)translateY(0);
  }
}

.section-pane#pane-3 {
  z-index: 3;
  transition: transform .4s 50ms;
  transform: translateX(0)translateY(100%);
}

@media screen and (min-width: 769px) {
  .section-pane#pane-3 {
    z-index: 2;
    width: 55%;
    left: auto;
  }
}

@media (min-width: 1681px) {
  .section-pane#pane-3 {
    width: 50%;
  }
}

.section-pane#pane-3.active {
  transition: transform .6s 50ms;
  transform: translateX(0)translateY(0);
}

@media screen and (min-width: 769px) {
  .section-pane#pane-1, .section-pane#pane-2 {
    transition: none;
    position: relative;
    transform: none;
  }
}

.theme-light .section-pane {
  background-color: #f8fafa;
}

.theme-dark .section-pane {
  background-color: #202030;
}

.section-pane-inner {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
}

.section-pane-header {
  box-sizing: border-box;
  width: 100%;
  z-index: 2;
  padding: 1rem 1rem 0;
  top: 0;
}

.theme-light .section-pane-header {
  background-color: #f8fafa;
}

.theme-dark .section-pane-header {
  background-color: #202030;
}

@media screen and (min-width: 1025px) {
  .section-pane-header {
    padding: 1rem 1.5rem 0;
  }
}

@media (max-width: 412px) {
  .section-pane-header {
    padding-top: .375rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.section-pane-header h1 {
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .section-pane-header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) {
  .section-pane-header h1 {
    font-size: 1.3125rem;
  }
}

.section-pane-header .section-pane-nav .nav-link {
  min-width: 5rem;
  min-height: 1.6875rem;
}

.section-pane-header .section-pane-nav .nav-link + .section-pane-nav__title {
  padding-top: .625rem;
}

.section-pane.no-title .section-pane-header {
  min-height: 0;
}

.section-pane.no-title .section-pane-nav {
  min-height: 0;
  border: none;
}

.section-pane.no-border .section-pane-header, .section-pane.no-border .section-pane-nav {
  border: none;
}

.section-pane.display-none .section-pane-header, .section-pane.display-none .section-pane-nav {
  display: none;
}

.section-pane.visibility-hidden .section-pane-header, .section-pane.visibility-hidden .section-pane-nav {
  visibility: hidden;
}

.section-pane-content {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
}

#pane-1 .section-pane-header .nav-left, #pane-1 .section-pane-header .nav-right {
  min-height: 1.6875rem;
}

.section-pane-nav {
  min-height: 2.5rem;
  border-bottom: .3125rem solid var(--border-colour);
  display: flex;
}

@media screen and (min-width: 769px) {
  .section-pane-nav {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .section-pane-nav {
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
  }
}

@media (max-width: 640px) {
  .section-pane-nav {
    padding-bottom: .25rem;
  }
}

.section-pane-nav .nav-left {
  text-align: left;
  order: 1;
  padding-right: 1rem;
}

.section-pane-nav .nav-right {
  text-align: right;
  order: 2;
  padding-left: 1rem;
}

@media screen and (max-width: 768px) {
  .section-pane-nav .nav-right {
    order: 3;
  }
}

.section-pane-nav .nav-link {
  flex: 1;
}

@media (max-width: 640px) {
  .section-pane-nav .nav-link {
    font-size: 1.0625rem;
  }
}

.section-pane-nav h1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  flex: 100%;
  order: 3;
  overflow: hidden;
  transform: translate(0);
}

@media screen and (max-width: 768px) {
  .section-pane-nav h1 {
    flex: 1;
    order: 2;
  }
}

#pane-1 {
  width: 45%;
  z-index: 3;
  background-color: #f8fafa;
  box-shadow: 0 0 20px #00000040;
}

@media (min-width: 1681px) {
  #pane-1 {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  #pane-1 {
    width: 100%;
    overflow: hidden;
  }
}

#pane-1-inner {
  width: 100%;
  height: 100%;
  animation: .4s .2s forwards fadeInSlideUp;
  position: relative;
  overflow: hidden;
}

.recent {
  box-sizing: border-box;
  opacity: 0;
  padding: 1rem 1rem .375rem;
  animation: .4s .3s forwards fadeInSlideUp;
  display: none;
}

@media screen and (max-width: 768px) {
  .recent {
    display: none;
  }
}

.recent h2 {
  margin: 0 0 1rem;
}

.recent ul {
  width: 100%;
  height: 4.8125rem;
  padding: 0 -.0625rem;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .recent ul {
    height: 5.6875rem;
    padding: 0 .4375rem;
  }
}

.recent ul li {
  box-sizing: border-box;
  width: 4.375rem;
  padding: 0 .375rem 1rem;
  display: inline-block;
}

@media (min-width: 1025px) {
  .recent ul li {
    width: 4.875rem;
  }
}

.recent ul li a {
  text-align: center;
  font-size: .6875rem;
  text-decoration: none;
  display: block;
}

.recent ul li a .login-avatar {
  margin: 0 auto;
  display: block;
}

.recent ul li a .login-name {
  color: #505064;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  width: 100%;
  margin: 0 auto;
  padding-top: .25rem;
  display: block;
  overflow: hidden;
}

#pane-2 {
  box-sizing: border-box;
  width: 55%;
  position: relative;
}

@media (min-width: 1681px) {
  #pane-2 {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  #pane-2 {
    width: 100%;
    height: 100%;
  }
}

.theme-light #pane-2 {
  background-color: #f8fafa;
}

#loading {
  color: #f8fafa;
  background-image: url("grid.0aa59fdd.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 5rem 5rem;
}

.theme-colour-blue #loading {
  background-color: #5a96ff;
}

.theme-colour-green #loading {
  background-color: #5af0be;
}

.theme-colour-red #loading {
  background-color: #ff556e;
}

.theme-colour-orange #loading {
  background-color: #ff7d5a;
}

.theme-colour-yellow #loading {
  background-color: #ffeb46;
}

.theme-colour-blue #loading {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green #loading {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red #loading {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange #loading {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow #loading {
  color: #505064;
  background-color: #ffeb46;
}

.table-view {
  flex-direction: column;
  flex: 1;
  padding: 2.125rem 1rem 2.0625rem;
  display: flex;
  overflow: scroll;
}

@media (min-width: 1025px) {
  .table-view {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 412px) {
  .table-view {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

header + .table-view {
  padding-top: 0;
}

.table-view > .row > .column {
  border-radius: .5rem;
  margin-bottom: -.0625rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.table-view > .row > .column > .column-group > .icn-button:first-child {
  margin-left: -.625rem;
}

.row {
  border-bottom: .0625rem solid var(--border-colour);
  margin-bottom: -.0625rem;
}

.row.no-border, .row:last-child {
  border: 0;
}

.row.grow {
  flex-grow: 1 !important;
}

.row.no-grow {
  flex-grow: 0 !important;
}

.row.space-above {
  margin-top: .625rem;
}

.row-group {
  border-bottom: .3125rem solid var(--border-colour);
}

.row-group:last-child {
  border: 0;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.row-group:last-child .row:last-child {
  border-bottom: .0625rem solid var(--border-colour);
}

.row-group:last-child .row.no-border {
  border: 0;
}

.column {
  flex-grow: 1;
  padding: 0 .5rem 0 1rem;
  transition: background-color .2s;
}

.column:first-child:last-child {
  flex-grow: 1;
}

.column.icn-button, .column.text-button {
  flex-grow: 0;
}

.theme-colour-blue .column.text-button {
  color: #5a96ff;
}

.theme-colour-green .column.text-button {
  color: #5af0be;
}

.theme-colour-red .column.text-button {
  color: #ff556e;
}

.theme-colour-orange .column.text-button {
  color: #ff7d5a;
}

.theme-colour-yellow .column.text-button {
  color: #ffeb46;
}

.column.info-text {
  flex-grow: 0;
}

.column.primary-heading {
  text-align: left;
  flex: 1;
  align-items: center;
  margin: 0;
}

.column.service-heading {
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  display: flex;
  overflow-x: scroll;
}

.column.service-heading .secondary-heading {
  padding: 0;
}

.column.service-heading::-webkit-scrollbar {
  display: none;
}

.column.toggle-switch {
  flex-grow: 0;
  align-items: center;
  display: flex;
}

.column.badge-wrapper {
  flex-grow: 0;
}

.column.small-input {
  min-width: 6.25rem;
  flex-grow: 0;
  position: relative;
}

.column.small-input:before {
  content: "";
  background-color: #0000;
  border-radius: .25rem;
  transition: background-color .2s, top .2s, bottom .2s;
  position: absolute;
  inset: -.625rem 0 -.625rem .3125rem;
}

.focussed.column.small-input:before {
  top: 0;
  bottom: 0;
}

.column.small-input > * {
  position: relative;
}

.column + .column:not(.icn-button):not(.banner) {
  padding-left: .3125rem;
}

.column .column, .column .column .column {
  padding: 0;
}

.button-text .column {
  padding-left: 0;
}

.button-text .column .column:nth-child(2) {
  padding-left: .3125rem;
}

.column-group {
  min-width: 0;
  flex-direction: row;
  display: flex;
}

.column-group.button-group {
  justify-content: center;
}

.column-group .icn-button, .column-group .text-button {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

.column-group + .column-group {
  padding-top: 1.1875rem;
}

.row[data-actionclick="switchToGroup"] > .column > .column-group {
  align-items: center;
}

.row[data-actionclick="switchToGroup"] > .column > .column-group .primary-text {
  overflow-wrap: normal;
}

.row[data-actionclick="switchToGroup"] > .column > .column-group .secondary-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  display: block;
  overflow: hidden;
}

.row:not(.doodlepad-buttons) > .column > .column-group {
  padding-bottom: 1rem;
}

.row:not(.doodlepad-buttons) > .column > .column-group:first-child:not(:last-child) {
  padding-bottom: .625rem;
}

.row:not(.doodlepad-buttons).loading-password > .column > .column-group:first-child:not(:last-child) {
  padding-bottom: 1rem;
}

.row:not(.doodlepad-buttons) > .column > .column-group, .row > .column > .secondary-heading {
  padding-top: 1rem;
}

.row:not(.doodlepad-buttons) > .column > .column-group + .column-group, .row > .column > .secondary-heading + .column-group, .row.has-large-avatar > .column > .column-group {
  padding-top: 0;
}

.row.has-large-avatar > .column > .column-group {
  padding-bottom: 0;
}

.row > .column > .secondary-heading:first-child:last-child {
  padding-top: 1.5625rem;
  padding-bottom: .4375rem;
}

label {
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.section-pane-wrapper [data-nav], .section-pane-wrapper label {
  cursor: pointer;
  transition: background-color .2s;
}

.section-pane-wrapper [data-nav].focussed, .section-pane-wrapper label.focussed {
  background-color: #0000;
}

@media (hover: hover) {
  .row[data-nav]:not(.cta):hover, label:not(.search):hover {
    background-color: #e6eaea;
  }

  .row[data-nav]:not(.cta):hover.focussed, label:not(.search):hover.focussed {
    background-color: #0000;
  }

  .row[data-nav]:not(.cta):hover.small-input, label:not(.search):hover.small-input {
    background: none !important;
  }

  .row[data-nav]:not(.cta):hover.small-input:before, label:not(.search):hover.small-input:before {
    background-color: #e6eaea;
  }
}

.focussed .icn-button {
  display: none;
}

.current-focus:hover {
  background: none;
}

.editable .icn-button {
  will-change: opacity;
  opacity: 0;
  transition: opacity .3s;
}

.editable .text-input {
  will-change: width, height, text-indent;
  transition: width .3s, height .3s, text-indent .3s;
}

.editable:hover .icn-button {
  opacity: 1;
  transition: opacity .2s;
}

.editable:hover .text-input {
  cursor: pointer;
}

.editable:hover .text-input:focus {
  cursor: text;
  color: #505064;
}

.cta {
  cursor: pointer;
  padding: .1875rem 0;
}

.theme-colour-blue .cta {
  color: #5a96ff;
}

.theme-colour-green .cta {
  color: #5af0be;
}

.theme-colour-red .cta {
  color: #ff556e;
}

.theme-colour-orange .cta {
  color: #ff7d5a;
}

.theme-colour-yellow .cta {
  color: #ffeb46;
}

.theme-colour-blue .cta:hover > .column {
  background-color: #5a96ff;
}

.theme-colour-green .cta:hover > .column {
  background-color: #5af0be;
}

.theme-colour-red .cta:hover > .column {
  background-color: #ff556e;
}

.theme-colour-orange .cta:hover > .column {
  background-color: #ff7d5a;
}

.theme-colour-yellow .cta:hover > .column {
  background-color: #ffeb46;
}

.theme-colour-blue .cta:hover > .column {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .cta:hover > .column {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .cta:hover > .column {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .cta:hover > .column {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .cta:hover > .column {
  color: #505064;
  background-color: #ffeb46;
}

.theme-light .cta:hover {
  color: #f8fafa;
}

.theme-light .cta.red {
  color: #ff3b30;
}

.cta.red:hover > .column {
  background-color: #ff3b30;
}

.theme-light .cta.red:hover {
  color: #f8fafa;
}

.cta > .column {
  border-radius: 999px !important;
}

.has-large-avatar > .column {
  padding: 0;
}

.has-large-avatar > .column > .column-group > .column {
  padding: 1.1875rem .5rem;
}

.has-large-avatar > .column > .column-group > .column:first-child {
  padding-left: .625rem;
}

.has-large-avatar > .column > .column-group > .column.primary-heading, .has-large-avatar > .column > .column-group > .column.primary-text {
  align-items: center;
  display: flex;
}

.has-large-avatar .login-avatar-inner:after {
  width: 3.75rem;
  height: 3.75rem;
}

@media (min-width: 1025px) {
  .has-large-avatar .login-avatar-inner:after {
    width: 5rem;
    height: 5rem;
  }
}

.has-large-avatar .login-avatar-inner, .has-large-avatar .login-avatar {
  width: 3.75rem;
  height: 3.75rem;
}

@media (min-width: 1025px) {
  .has-large-avatar .login-avatar-inner, .has-large-avatar .login-avatar {
    width: 5rem;
    height: 5rem;
  }
}

.theme-colour-blue .has-large-avatar .login-avatar-inner:hover, .theme-colour-blue .has-large-avatar .login-avatar:hover {
  border-color: #5a96ff;
}

.theme-colour-green .has-large-avatar .login-avatar-inner:hover, .theme-colour-green .has-large-avatar .login-avatar:hover {
  border-color: #5af0be;
}

.theme-colour-red .has-large-avatar .login-avatar-inner:hover, .theme-colour-red .has-large-avatar .login-avatar:hover {
  border-color: #ff556e;
}

.theme-colour-orange .has-large-avatar .login-avatar-inner:hover, .theme-colour-orange .has-large-avatar .login-avatar:hover {
  border-color: #ff7d5a;
}

.theme-colour-yellow .has-large-avatar .login-avatar-inner:hover, .theme-colour-yellow .has-large-avatar .login-avatar:hover {
  border-color: #ffeb46;
}

@media (min-width: 1025px) {
  .has-large-avatar .primary-text {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  .has-large-avatar .secondary-text {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .has-large-avatar .f-icn {
    width: 2.5rem;
    height: 2.5rem;
    background-size: 2.5rem 2.5rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1367px) {
  .has-large-avatar .f-icn {
    width: 2.8125rem;
    height: 2.8125rem;
    background-size: 2.8125rem 2.8125rem;
    line-height: 2.8125rem;
  }
}

@media (min-width: 1681px) {
  .has-large-avatar .f-icn {
    width: 3.125rem;
    height: 3.125rem;
    background-size: 3.125rem 3.125rem;
    line-height: 3.125rem;
  }
}

.has-small-avatar .login-avatar-inner {
  width: 2rem;
  height: 2rem;
  margin-top: -.25rem;
  margin-bottom: -.25rem;
  padding: .125rem;
}

.has-small-avatar .login-avatar-inner:after {
  display: none;
}

.has-small-avatar .login-avatar {
  width: 1.75rem;
  height: 1.75rem;
}

.has-small-avatar .column:nth-child(2) {
  padding-left: .625rem;
}

.has-status.has-small-avatar .column:nth-child(2) {
  padding-left: 1rem;
}

.has-cta-button {
  min-height: 3.75938rem;
  border: 0 !important;
}

.has-cta-button:first-child:not(:last-child) {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.has-cta-button:last-child, .overlay .has-cta-button {
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}

.has-cta-button + button.text-button {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.25rem;
  display: block;
}

.has-cta-button + button.text-button:last-child:not(:first-child) {
  padding-bottom: .625rem;
}

.has-cta-button a {
  transition: none;
}

.has-cta-button a:hover {
  opacity: 1;
}

.status {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #505064;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: .875rem .875rem;
  border-radius: 999px;
  display: block;
  position: absolute;
  top: .125rem;
  right: -.375rem;
}

.theme-light .status[data-status="secure"] {
  background-image: url("forghetti-ui_lock_normal.08b10138.svg");
}

.theme-dark .status[data-status="secure"] {
  background-image: url("forghetti-ui_lock_normal.45ae7b4e.svg");
}

@media (hover: hover) {
  .row[data-actionclick]:not(.cta):hover > .column {
    background-color: #e6eaea;
  }

  .theme-dark .row[data-actionclick]:not(.cta):hover > .column {
    background-color: #2f303b;
  }

  .row[data-nav]:not(.cta):hover, label:not(.search):hover {
    background-color: #e6eaea;
  }

  .theme-dark .row[data-nav]:not(.cta):hover, .theme-dark label:not(.search):hover {
    background-color: #2f303b;
  }
}

#splash-screen {
  z-index: 2000;
  background-color: #5af0be;
  background-image: url("splash-screen-background.287a685f.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

#splash-screen #splash-screen-inner {
  width: 100%;
  max-width: 26.25rem;
}

#splash-screen .forghetti-logo {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 3.125rem;
  display: block;
}

@media (max-height: 420px) and (orientation: landscape) {
  #splash-screen .forghetti-logo {
    max-width: 23.75rem;
  }
}

#splash-screen .forghetti-logo path {
  fill: #505064;
}

#splash-screen .loading-msg {
  text-align: center;
  opacity: .9;
  color: #505064;
  margin-top: .625rem;
}

#splash-screen .loading-msg-delay {
  text-align: center;
  opacity: .9;
  color: #505064;
  margin-top: 1.25rem;
}

#splash-screen .loading-bar-wrapper {
  width: 100%;
  max-width: 26.25rem;
  box-sizing: border-box;
  height: 5px;
  margin: 2.5rem auto 0;
  padding: 0 5.625rem;
}

#splash-screen .loading-bar-wrapper .loading-bar {
  width: 100%;
  height: 5px;
  background: #ffffff40;
  border-radius: 999px;
  overflow: hidden;
}

#splash-screen .loading-bar-wrapper .loading-bar span {
  width: 0%;
  height: 5px;
  background-color: #505064;
  transition: width 1s ease-in-out;
  display: block;
}

#account-log-in .icn-button.icn-button-xlrg {
  margin-top: 1.25rem;
}

.overlay {
  z-index: 500;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: absolute;
  inset: 0;
}

.theme-light .overlay {
  background-color: #f8fafa;
}

.theme-dark .overlay {
  background-color: #202030;
}

.overlay .section-pane-wrapper {
  max-width: 812px;
  max-height: 900px;
  box-sizing: border-box;
  position: relative;
}

.overlay .large-heading {
  margin-top: 2.25rem;
  margin-bottom: 0;
}

.large-heading {
  text-align: center;
  font-size: 2.5rem;
}

.medium-heading {
  text-align: center;
  font-size: 1.625rem;
}

button {
  text-align: center;
  background-color: #0000;
  border: 0;
  outline: 0;
}

.loading-password button {
  cursor: default;
  pointer-events: none;
}

.icn-button {
  text-align: center;
  transition: transform .3s;
  position: relative;
}

.icn-button.nav-link {
  width: auto;
}

.icn-button.icn-button-has-label {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icn-button.column {
  min-height: 3.6875rem;
  max-height: 4.375rem;
}

.icn-button.column.icn-button-xlrg {
  max-height: none;
}

.column-group.button-group .icn-button {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0 1.125rem;
}

.icn-button .f-icn + .label {
  margin-top: -.5em;
  margin-bottom: 1em;
}

.icn-button-label {
  font-size: .625rem;
  line-height: .8125rem;
  display: block;
}

.f-icn {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  padding: .375rem;
  line-height: 2rem;
  display: inline-block;
}

.icn-button-sm .f-icn {
  width: 2.8125rem;
  height: 2.8125rem;
  background-size: 2.8125rem 2.8125rem;
  padding: 0;
  line-height: 2.8125rem;
}

.icn-button-med .f-icn {
  width: 3.75rem;
  height: 3.75rem;
  background-size: 3.75rem 3.75rem;
  padding: 0;
  line-height: 3.75rem;
}

.icn-button-lrg .f-icn {
  width: 5rem;
  height: 5rem;
  background-size: 5rem 5rem;
  padding: 0;
  line-height: 5rem;
}

.icn-button-xlrg .f-icn {
  width: 8rem;
  height: 8rem;
  background-size: 8rem 8rem;
  padding: 0;
  line-height: 8rem;
}

.text-button, .text-button-left {
  min-width: 2.75rem;
  min-height: 2.75rem;
  font-size: 1.1875rem;
  line-height: 1.5rem;
}

.theme-light .f-icn-add {
  background-image: url("forghetti-ui_add_normal.53f0147e.svg");
}

.theme-dark .f-icn-add {
  background-image: url("forghetti-ui_add_normal.0b0ef2c5.svg");
}

.theme-light .f-icn-add-member {
  background-image: url("forghetti-ui_add-member_normal.2aaf531c.svg");
}

.theme-dark .f-icn-add-member {
  background-image: url("forghetti-ui_add-member_normal.77916abc.svg");
}

.theme-light .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.e9686853.svg");
}

.theme-dark .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.2731604b.svg");
}

.theme-light .f-icn-alarm {
  background-image: url("forghetti-ui_alarm_normal.f7cf6612.svg");
}

.theme-dark .f-icn-alarm {
  background-image: url("forghetti-ui_alarm_normal.20f09173.svg");
}

.theme-light .f-icn-authentication {
  background-image: url("forghetti-ui_authentication_normal.0d6f19c5.svg");
}

.theme-dark .f-icn-authentication {
  background-image: url("forghetti-ui_authentication_normal.ced08014.svg");
}

.theme-light .f-icn-cancel {
  background-image: url("forghetti-ui_cancel_normal.47fb6001.svg");
}

.theme-dark .f-icn-cancel {
  background-image: url("forghetti-ui_cancel_normal.0eb669cc.svg");
}

.theme-light .f-icn-cancel-solid {
  background-image: url("forghetti-ui_cancel-solid_normal.c5ae145f.svg");
}

.theme-dark .f-icn-cancel-solid {
  background-image: url("forghetti-ui_cancel-solid_normal.a5356f47.svg");
}

.theme-light .f-icn-change-number {
  background-image: url("forghetti-ui_change-number_normal.7d53b2d6.svg");
}

.theme-dark .f-icn-change-number {
  background-image: url("forghetti-ui_change-number_normal.0f7d23d1.svg");
}

.theme-light .f-icn-check {
  background-image: url("forghetti-ui_check_normal.7f22ffcf.svg");
}

.theme-dark .f-icn-check {
  background-image: url("forghetti-ui_check_normal.5ca87365.svg");
}

.theme-light .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.ce12f464.svg");
}

.theme-dark .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.59e03254.svg");
}

.theme-light .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.741b9946.svg");
}

.theme-dark .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.253e4faa.svg");
}

.theme-light .f-icn-chevron-down {
  background-image: url("forghetti-ui_chevron-down_normal.5e6e7cd9.svg");
}

.theme-dark .f-icn-chevron-down {
  background-image: url("forghetti-ui_chevron-down_normal.90f57dd3.svg");
}

.theme-light .f-icn-chevron-left {
  background-image: url("forghetti-ui_chevron-left_normal.0a7b412f.svg");
}

.theme-dark .f-icn-chevron-left {
  background-image: url("forghetti-ui_chevron-left_normal.1e13690b.svg");
}

.f-icn-chevron-right {
  background-image: url("forghetti-ui_chevron-right_normal.6f698897.svg");
}

.theme-light .f-icn-chevron-up {
  background-image: url("forghetti-ui_chevron-up_normal.069fd31b.svg");
}

.theme-dark .f-icn-chevron-up {
  background-image: url("forghetti-ui_chevron-up_normal.37d35e14.svg");
}

.theme-light .f-icn-connect-to-pc {
  background-image: url("forghetti-ui_connect-to-pc_normal.a392891b.svg");
}

.theme-dark .f-icn-connect-to-pc {
  background-image: url("forghetti-ui_connect-to-pc_normal.5c367ade.svg");
}

.theme-light .f-icn-contact {
  background-image: url("forghetti-ui_contact_normal.cf2af475.svg");
}

.theme-dark .f-icn-contact {
  background-image: url("forghetti-ui_contact_normal.39220e0d.svg");
}

.theme-light .f-icn-copy {
  background-image: url("forghetti-ui_copy_normal.87a04d97.svg");
}

.theme-dark .f-icn-copy {
  background-image: url("forghetti-ui_copy_normal.db2db7fe.svg");
}

.theme-light .f-icn-delete {
  background-image: url("forghetti-ui_delete_normal.c59331e9.svg");
}

.theme-dark .f-icn-delete {
  background-image: url("forghetti-ui_delete_normal.222e4cd9.svg");
}

.theme-light .f-icn-delete-account {
  background-image: url("forghetti-ui_delete-account_normal.67790f6a.svg");
}

.theme-dark .f-icn-delete-account {
  background-image: url("forghetti-ui_delete-account_normal.070702e3.svg");
}

.theme-light .f-icn-dialpad {
  background-image: url("forghetti-ui_dialpad_normal.1deb922f.svg");
}

.theme-dark .f-icn-dialpad {
  background-image: url("forghetti-ui_dialpad_normal.8f2bf035.svg");
}

.theme-light .f-icn-doodlepad {
  background-image: url("forghetti-ui_doodlepad_normal.f290a660.svg");
}

.theme-dark .f-icn-doodlepad {
  background-image: url("forghetti-ui_doodlepad_normal.14229f36.svg");
}

.theme-light .f-icn-doodlepad-drawn {
  background-image: url("forghetti-ui_doodlepad-drawn_normal.be8df244.svg");
}

.theme-dark .f-icn-doodlepad-drawn {
  background-image: url("forghetti-ui_doodlepad-drawn_normal.3d0ab0bb.svg");
}

.theme-light .f-icn-dots {
  background-image: url("forghetti-ui_dots_normal.4bf92d81.svg");
}

.theme-dark .f-icn-dots {
  background-image: url("forghetti-ui_dots_normal.9c20566f.svg");
}

.theme-light .f-icn-down {
  background-image: url("forghetti-ui_down_normal.83624c95.svg");
}

.theme-dark .f-icn-down {
  background-image: url("forghetti-ui_down_normal.f9b4c309.svg");
}

.theme-light .f-icn-down-solid {
  background-image: url("forghetti-ui_down-solid_normal.274e87a4.svg");
}

.theme-dark .f-icn-down-solid {
  background-image: url("forghetti-ui_down-solid_normal.3b8eb99b.svg");
}

.theme-light .f-icn-email {
  background-image: url("forghetti-ui_email_normal.c9cbbd17.svg");
}

.theme-dark .f-icn-email {
  background-image: url("forghetti-ui_email_normal.34c929cf.svg");
}

.theme-light .f-icn-exit {
  background-image: url("forghetti-ui_exit_normal.11ffcbb7.svg");
}

.theme-dark .f-icn-exit {
  background-image: url("forghetti-ui_exit_normal.f1690f0c.svg");
}

.theme-light .f-icn-fingerprint {
  background-image: url("forghetti-ui_fingerprint_normal.07de51a3.svg");
}

.theme-dark .f-icn-fingerprint {
  background-image: url("forghetti-ui_fingerprint_normal.a968d668.svg");
}

.theme-colour-blue .f-icn-fingerprint-colour {
  background-image: url("forghetti-ui_fingerprint_normal.6b736f41.svg");
}

.theme-colour-green .f-icn-fingerprint-colour {
  background-image: url("forghetti-ui_fingerprint_normal.606783cb.svg");
}

.theme-colour-red .f-icn-fingerprint-colour {
  background-image: url("forghetti-ui_fingerprint_normal.8552042e.svg");
}

.theme-colour-orange .f-icn-fingerprint-colour {
  background-image: url("forghetti-ui_fingerprint_normal.1815d4c7.svg");
}

.theme-colour-yellow .f-icn-fingerprint-colour {
  background-image: url("forghetti-ui_fingerprint_normal.dac2c8b3.svg");
}

.theme-light .f-icn-folder {
  background-image: url("forghetti-ui_folder_normal.7a256cdf.svg");
}

.theme-dark .f-icn-folder {
  background-image: url("forghetti-ui_folder_normal.3b277322.svg");
}

.theme-light .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.a180f001.svg");
}

.theme-dark .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.1872adfc.svg");
}

.theme-light .f-icn-forghetticon-peaceful {
  background-image: url("forghetti-ui_forghetticon-peaceful_normal.212f7736.svg");
}

.theme-dark .f-icn-forghetticon-peaceful {
  background-image: url("forghetti-ui_forghetticon-peaceful_normal.64bd162d.svg");
}

.theme-light .f-icn-group-share {
  background-image: url("forghetti-ui_group-share_normal.c0ecb407.svg");
}

.theme-dark .f-icn-group-share {
  background-image: url("forghetti-ui_group-share_normal.8fcb84d8.svg");
}

.theme-light .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.7ef72016.svg");
}

.theme-dark .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.bf1aae60.svg");
}

.theme-light .f-icn-heart {
  background-image: url("forghetti-ui_heart_normal.cd7b722e.svg");
}

.theme-dark .f-icn-heart {
  background-image: url("forghetti-ui_heart_normal.d9ce0bf5.svg");
}

.theme-light .f-icn-help {
  background-image: url("forghetti-ui_help_normal.6d24ecd1.svg");
}

.theme-dark .f-icn-help {
  background-image: url("forghetti-ui_help_normal.eced81ab.svg");
}

.theme-light .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.1300aa60.svg");
}

.theme-dark .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.4a5d01b6.svg");
}

.theme-light .f-icn-hide {
  background-image: url("forghetti-ui_hide_normal.e78bb969.svg");
}

.theme-dark .f-icn-hide {
  background-image: url("forghetti-ui_hide_normal.b255a1a1.svg");
}

.theme-light .f-icn-hide-numbers {
  background-image: url("forghetti-ui_hide-numbers_normal.401e4804.svg");
}

.theme-dark .f-icn-hide-numbers {
  background-image: url("forghetti-ui_hide-numbers_normal.f680ade2.svg");
}

.theme-light .f-icn-home {
  background-image: url("forghetti-ui_home_normal.4d77a881.svg");
}

.theme-dark .f-icn-home {
  background-image: url("forghetti-ui_home_normal.79ba3db7.svg");
}

.theme-light .f-icn-image {
  background-image: url("forghetti-ui_image_normal.fb398ea7.svg");
}

.theme-dark .f-icn-image {
  background-image: url("forghetti-ui_image_normal.bb766810.svg");
}

.theme-light .f-icn-left {
  background-image: url("forghetti-ui_image_normal.fb398ea7.svg");
}

.theme-dark .f-icn-left {
  background-image: url("forghetti-ui_image_normal.bb766810.svg");
}

.theme-light .f-icn-left-solid {
  background-image: url("forghetti-ui_left-solid_normal.08f050d9.svg");
}

.theme-dark .f-icn-left-solid {
  background-image: url("forghetti-ui_left-solid_normal.206960c7.svg");
}

.theme-light .f-icn-lock {
  background-image: url("forghetti-ui_lock_normal.45ae7b4e.svg");
}

.theme-dark .f-icn-lock {
  background-image: url("forghetti-ui_lock_normal.08b10138.svg");
}

.theme-light .f-icn-lock-plus {
  background-image: url("forghetti-ui_lock-plus_normal.a02298e1.svg");
}

.theme-dark .f-icn-lock-plus {
  background-image: url("forghetti-ui_lock-plus_normal.a58bc886.svg");
}

.theme-light .f-icn-members {
  background-image: url("forghetti-ui_members_normal.90b11508.svg");
}

.theme-dark .f-icn-members {
  background-image: url("forghetti-ui_members_normal.2bcd445b.svg");
}

.theme-light .f-icn-mobile {
  background-image: url("forghetti-ui_mobile_normal.63ec8660.svg");
}

.theme-dark .f-icn-mobile {
  background-image: url("forghetti-ui_mobile_normal.6076f59a.svg");
}

.theme-light .f-icn-open-browser {
  background-image: url("forghetti-ui_open-browser_normal.6f9f53ab.svg");
}

.theme-dark .f-icn-open-browser {
  background-image: url("forghetti-ui_open-browser_normal.af307475.svg");
}

.theme-light .f-icn-password {
  background-image: url("forghetti-ui_password_normal.d77d457d.svg");
}

.theme-dark .f-icn-password {
  background-image: url("forghetti-ui_password_normal.b5d84143.svg");
}

.theme-colour-blue .f-icn-password-colour {
  background-image: url("forghetti-ui_password_normal.3cc7d613.svg");
}

.theme-colour-green .f-icn-password-colour {
  background-image: url("forghetti-ui_password_normal.a8c58621.svg");
}

.theme-colour-red .f-icn-password-colour {
  background-image: url("forghetti-ui_password_normal.a3446157.svg");
}

.theme-colour-orange .f-icn-password-colour {
  background-image: url("forghetti-ui_password_normal.3e197af7.svg");
}

.theme-colour-yellow .f-icn-password-colour {
  background-image: url("forghetti-ui_password_normal.f1a04136.svg");
}

.theme-colour-blue .f-icn-pc {
  background-image: url("forghetti-ui_pc_normal.80d65b77.svg");
}

.theme-colour-green .f-icn-pc {
  background-image: url("forghetti-ui_pc_normal.09194323.svg");
}

.theme-colour-red .f-icn-pc {
  background-image: url("forghetti-ui_pc_normal.e7043ee4.svg");
}

.theme-colour-orange .f-icn-pc {
  background-image: url("forghetti-ui_pc_normal.2a57acb2.svg");
}

.theme-colour-yellow .f-icn-pc {
  background-image: url("forghetti-ui_pc_normal.4d0b5233.svg");
}

.theme-light .f-icn-phone {
  background-image: url("forghetti-ui_phone_normal.8af4e9da.svg");
}

.theme-dark .f-icn-phone {
  background-image: url("forghetti-ui_phone_normal.22b8b632.svg");
}

.theme-light .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.237be4db.svg");
}

.theme-dark .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.9195939d.svg");
}

.theme-light .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.d3981efa.svg");
}

.theme-dark .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.494bb26d.svg");
}

.theme-colour-blue .f-icn-reset-colour {
  background-image: url("forghetti-ui_reset_normal.072c2c9e.svg");
}

.theme-colour-green .f-icn-reset-colour {
  background-image: url("forghetti-ui_reset_normal.55e4519f.svg");
}

.theme-colour-red .f-icn-reset-colour {
  background-image: url("forghetti-ui_reset_normal.164fd9e9.svg");
}

.theme-colour-orange .f-icn-reset-colour {
  background-image: url("forghetti-ui_reset_normal.853caba1.svg");
}

.theme-colour-yellow .f-icn-reset-colour {
  background-image: url("forghetti-ui_reset_normal.a9a7ff72.svg");
}

.theme-light .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.724aff73.svg");
}

.theme-dark .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.e2898523.svg");
}

.theme-light .f-icn-right {
  background-image: url("forghetti-ui_right_normal.56bc18ed.svg");
}

.theme-dark .f-icn-right {
  background-image: url("forghetti-ui_right_normal.4a41fa61.svg");
}

.theme-light .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.813d5b33.svg");
}

.theme-dark .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.bb5bf611.svg");
}

.theme-colour-blue .f-icn-right-colour {
  background-image: url("forghetti-ui_right_normal.7a9bc958.svg");
}

.theme-colour-green .f-icn-right-colour {
  background-image: url("forghetti-ui_right_normal.1e3328e3.svg");
}

.theme-colour-red .f-icn-right-colour {
  background-image: url("forghetti-ui_right_normal.41fd4070.svg");
}

.theme-colour-orange .f-icn-right-colour {
  background-image: url("forghetti-ui_right_normal.3b05a345.svg");
}

.theme-colour-yellow .f-icn-right-colour {
  background-image: url("forghetti-ui_right_normal.ef09a1ba.svg");
}

.theme-light .f-icn-search {
  background-image: url("forghetti-ui_search_normal.21496266.svg");
}

.theme-dark .f-icn-search {
  background-image: url("forghetti-ui_search_normal.d0df6cc4.svg");
}

.theme-light .f-icn-security {
  background-image: url("forghetti-ui_security_normal.c9589cc4.svg");
}

.theme-dark .f-icn-security {
  background-image: url("forghetti-ui_security_normal.8bcfc998.svg");
}

.theme-light .f-icn-settings {
  background-image: url("forghetti-ui_settings_normal.d8748f4a.svg");
}

.theme-dark .f-icn-settings {
  background-image: url("forghetti-ui_settings_normal.b52e146b.svg");
}

.theme-light .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.204e363a.svg");
}

.theme-dark .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.c3f13507.svg");
}

.theme-light .f-icn-show {
  background-image: url("forghetti-ui_show_normal.80985265.svg");
}

.theme-dark .f-icn-show {
  background-image: url("forghetti-ui_show_normal.f15192e8.svg");
}

.theme-light .f-icn-show-numbers {
  background-image: url("forghetti-ui_show-numbers_normal.60461340.svg");
}

.theme-dark .f-icn-show-numbers {
  background-image: url("forghetti-ui_show-numbers_normal.7ceb1494.svg");
}

.theme-light .f-icn-success {
  background-image: url("forghetti-ui_success_normal.336675cd.svg");
}

.theme-dark .f-icn-success {
  background-image: url("forghetti-ui_success_normal.53f2e62b.svg");
}

.theme-light .f-icn-swipe {
  background-image: url("forghetti-ui_swipe_normal.3df07152.svg");
}

.theme-dark .f-icn-swipe {
  background-image: url("forghetti-ui_swipe_normal.c402722c.svg");
}

.theme-light .f-icn-synchronising {
  background-image: url("forghetti-ui_synchronising_normal.b5ac97d0.svg");
}

.theme-dark .f-icn-synchronising {
  background-image: url("forghetti-ui_synchronising_normal.57019b79.svg");
}

.theme-light .f-icn-synchronising-solid {
  background-image: url("forghetti-ui_synchronising-solid_normal.335b449f.svg");
}

.theme-dark .f-icn-synchronising-solid {
  background-image: url("forghetti-ui_synchronising-solid_normal.0623485a.svg");
}

.theme-light .f-icn-up {
  background-image: url("forghetti-ui_up_normal.ca90982d.svg");
}

.theme-dark .f-icn-up {
  background-image: url("forghetti-ui_up_normal.90b89d3b.svg");
}

.theme-light .f-icn-up-solid {
  background-image: url("forghetti-ui_up-solid_normal.d8aea4dc.svg");
}

.theme-dark .f-icn-up-solid {
  background-image: url("forghetti-ui_up-solid_normal.75f37f09.svg");
}

.theme-light .f-icn-web {
  background-image: url("forghetti-ui_web_normal.80f12672.svg");
}

.theme-dark .f-icn-web {
  background-image: url("forghetti-ui_web_normal.940b358a.svg");
}

.theme-light .f-icn-switch {
  background-image: url("forghetti-ui_switch_normal.cfbfe345.svg");
}

.theme-dark .f-icn-switch {
  background-image: url("forghetti-ui_switch_normal.e2422758.svg");
}

.theme-colour-blue .f-icn-switch-colour {
  background-image: url("forghetti-ui_switch_normal.c1d4e3ad.svg");
}

.theme-colour-green .f-icn-switch-colour {
  background-image: url("forghetti-ui_switch_normal.fe89c295.svg");
}

.theme-colour-red .f-icn-switch-colour {
  background-image: url("forghetti-ui_switch_normal.4673a94b.svg");
}

.theme-colour-orange .f-icn-switch-colour {
  background-image: url("forghetti-ui_switch_normal.7d60579b.svg");
}

.theme-colour-yellow .f-icn-switch-colour {
  background-image: url("forghetti-ui_switch_normal.2168f7e5.svg");
}

.theme-light .f-icn-edit {
  background-image: url("forghetti-ui_edit_normal.2f67c907.svg");
}

.theme-dark .f-icn-edit {
  background-image: url("forghetti-ui_edit_normal.bee9af34.svg");
}

.theme-light .f-icn-shield {
  background-image: url("forghetti-ui_shield_normal.0ad0a062.svg");
}

.theme-dark .f-icn-shield {
  background-image: url("forghetti-ui_shield_normal.ab4ec840.svg");
}

.theme-light .f-icn-right-rotate {
  background-image: url("forghetti-ui_rotate_right_normal.8f5455fc.svg");
}

.theme-dark .f-icn-right-rotate {
  background-image: url("forghetti-ui_rotate_right_normal.0c4d3189.svg");
}

.theme-light .f-icn-enable-biometrics {
  background-image: url("forghetti-ui_enable-biometrics_normal.d2d01d29.svg");
}

.theme-dark .f-icn-enable-biometrics {
  background-image: url("forghetti-ui_enable-biometrics_normal.a509613d.svg");
}

.theme-light .f-icn-move-service-blank {
  background-image: url("forghetti-ui_move-service-blank_normal.fb664602.svg");
}

.theme-dark .f-icn-move-service-blank {
  background-image: url("forghetti-ui_move-service-blank_normal.2ed1ea27.svg");
}

.theme-colour-blue .f-icn-move-service-blank.f-icn-colour {
  background-image: url("forghetti-ui_move-service-blank_normal.5d4c0e8a.svg");
}

.theme-colour-green .f-icn-move-service-blank.f-icn-colour {
  background-image: url("forghetti-ui_move-service-blank_normal.c1568098.svg");
}

.theme-colour-red .f-icn-move-service-blank.f-icn-colour {
  background-image: url("forghetti-ui_move-service-blank_normal.9a903216.svg");
}

.theme-colour-orange .f-icn-move-service-blank.f-icn-colour {
  background-image: url("forghetti-ui_move-service-blank_normal.d54ba7ad.svg");
}

.theme-colour-yellow .f-icn-move-service-blank.f-icn-colour {
  background-image: url("forghetti-ui_move-service-blank_normal.fb15b3bf.svg");
}

.theme-colour-blue .f-icn-cancel-notification {
  background-image: url("forghetti-ui_cancel_normal.5baab04e.svg");
}

.theme-colour-green .f-icn-cancel-notification {
  background-image: url("forghetti-ui_cancel_normal.98ca1947.svg");
}

.theme-colour-red .f-icn-cancel-notification {
  background-image: url("forghetti-ui_cancel_normal.50287f16.svg");
}

.theme-colour-orange .f-icn-cancel-notification {
  background-image: url("forghetti-ui_cancel_normal.38afcb83.svg");
}

.theme-colour-yellow .f-icn-cancel-notification {
  background-image: url("forghetti-ui_cancel_normal.b6f132ac.svg");
}

.theme-colour-blue .f-icn-add {
  background-image: url("forghetti-ui_add_normal.be16bbfe.svg");
}

.theme-colour-green .f-icn-add {
  background-image: url("forghetti-ui_add_normal.5789af70.svg");
}

.theme-colour-red .f-icn-add {
  background-image: url("forghetti-ui_add_normal.1c0cf2ef.svg");
}

.theme-colour-orange .f-icn-add {
  background-image: url("forghetti-ui_add_normal.535ef86d.svg");
}

.theme-colour-yellow .f-icn-add {
  background-image: url("forghetti-ui_add_normal.09b4f7bd.svg");
}

.theme-colour-blue .cta:hover .icn-button .f-icn-add, .theme-colour-red .cta:hover .icn-button .f-icn-add, .theme-colour-orange .cta:hover .icn-button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.08a57831.svg");
}

.theme-colour-green.theme-dark .cta:hover .icn-button .f-icn-add, .theme-colour-yellow.theme-dark .cta:hover .icn-button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.53f0147e.svg");
}

.theme-colour-blue .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.072c2c9e.svg");
}

.theme-colour-green .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.55e4519f.svg");
}

.theme-colour-red .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.164fd9e9.svg");
}

.theme-colour-orange .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.853caba1.svg");
}

.theme-colour-yellow .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.a9a7ff72.svg");
}

.theme-colour-blue .cta:hover .icn-button .f-icn-reset, .theme-colour-red .cta:hover .icn-button .f-icn-reset, .theme-colour-orange .cta:hover .icn-button .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.3fb18413.svg");
}

.theme-colour-green.theme-dark .cta:hover .icn-button .f-icn-reset, .theme-colour-yellow.theme-dark .cta:hover .icn-button .f-icn-reset {
  background-image: url("forghetti-ui_reset_normal.d3981efa.svg");
}

.theme-colour-blue .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.d47cf28c.svg");
}

.theme-colour-green .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.5b978459.svg");
}

.theme-colour-red .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.201a7987.svg");
}

.theme-colour-orange .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.b3a7268a.svg");
}

.theme-colour-yellow .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.0f5154cf.svg");
}

.theme-colour-blue .cta:hover .icn-button .f-icn-restore, .theme-colour-red .cta:hover .icn-button .f-icn-restore, .theme-colour-orange .cta:hover .icn-button .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.e7502042.svg");
}

.theme-colour-green.theme-dark .cta:hover .icn-button .f-icn-restore, .theme-colour-yellow.theme-dark .cta:hover .icn-button .f-icn-restore {
  background-image: url("forghetti-ui_restore_normal.724aff73.svg");
}

.theme-colour-blue .f-icn-delete, .theme-colour-green .f-icn-delete, .theme-colour-red .f-icn-delete, .theme-colour-orange .f-icn-delete, .theme-colour-yellow .f-icn-delete {
  background-image: url("forghetti-ui_delete_normal.dbb4e2ec.svg");
}

.theme-light .red .column:hover .icn-button .f-icn-delete, .cta:hover .icn-button .f-icn-delete {
  background-image: url("forghetti-ui_delete_normal.f24a00a6.svg");
}

.theme-colour-blue .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.5906b8a8.svg");
}

.theme-colour-green .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.0fcdfa1d.svg");
}

.theme-colour-red .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.7c5ff95e.svg");
}

.theme-colour-orange .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.c679bf4a.svg");
}

.theme-colour-yellow .f-icn-check-solid {
  background-image: url("forghetti-ui_check-solid_normal.74436fbd.svg");
}

.theme-colour-blue .f-icn-uncheck-solid {
  background-image: url("forghetti-ui_unchecked-circle_normal.ebd378f9.svg");
}

.theme-colour-green .f-icn-uncheck-solid {
  background-image: url("forghetti-ui_unchecked-circle_normal.ebd378f9.svg");
}

.theme-colour-red .f-icn-uncheck-solid {
  background-image: url("forghetti-ui_unchecked-circle_normal.ebd378f9.svg");
}

.theme-colour-orange .f-icn-uncheck-solid {
  background-image: url("forghetti-ui_unchecked-circle_normal.ebd378f9.svg");
}

.theme-colour-yellow .f-icn-uncheck-solid {
  background-image: url("forghetti-ui_unchecked-circle_normal.ebd378f9.svg");
}

.theme-colour-blue .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.4eb3949b.svg");
}

.theme-colour-green .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.50751696.svg");
}

.theme-colour-red .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.4f638cae.svg");
}

.theme-colour-orange .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.e9705694.svg");
}

.theme-colour-yellow .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.b9b9e104.svg");
}

.f-icn-unchecked-circle {
  height: 1rem;
  width: 1rem;
  border: 1px solid;
  border-radius: 50%;
  margin: auto;
  display: inline-block;
}

.theme-light .f-icn-unchecked-circle {
  border-color: #202030;
}

.theme-dark .f-icn-unchecked-circle {
  border-color: #505064;
}

.f-icn-badge-premium {
  background-image: url("forghetti-ui_monthly_shield_normal.df5c837c.svg");
}

.f-icn-loading {
  background-image: url("forghetti-ui_theme-light_loading_normal.0c1d9918.svg");
}

.theme-dark .f-icn-loading {
  background-image: url("forghetti-ui_theme-dark_loading_normal.24170abb.svg");
}

.rotate-180 {
  transform-origin: 50%;
  transform: rotate(-180deg);
}

.context-menu {
  min-width: 10rem;
  z-index: 1000;
  color: #505064;
  background-color: #f8fafa;
  border-radius: .25rem;
  display: none;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 .5rem 1rem #8e8e9340;
}

.context-menu.context-menu--active {
  display: block;
}

.context-menu .context-menu__heading {
  text-align: center;
  padding: .25rem 1.25rem .5rem;
  font-weight: 600;
}

.context-menu .context-menu__item {
  text-align: center;
  margin-top: -.0625rem;
  display: block;
  position: relative;
  opacity: 1 !important;
}

.context-menu .context-menu__item:not(:last-child):after {
  content: "";
  height: .0625rem;
  background: #e0e4e4;
  display: block;
  position: absolute;
  bottom: 0;
  left: .625rem;
  right: 0;
}

.context-menu .context-menu__item:hover {
  color: #2f303b;
  background-color: #edf1f1;
}

.context-menu .context-menu__item:hover:after {
  background: none;
}

.context-menu .context-menu__link {
  color: inherit;
  cursor: pointer;
  padding: .75rem 1.25rem;
  display: block;
}

.context-menu .context-menu__link:hover {
  opacity: 1;
}

.context-menu .context-menu__link * {
  cursor: pointer;
}

.context-menu[data-position="center"] {
  min-width: 33%;
  top: 33%;
  left: 33%;
}

.doodlepad__overlay {
  max-width: 18.875rem;
  min-width: 14.4375rem;
  width: 37vh;
  margin: 1px auto;
  position: absolute;
  overflow: hidden;
}

.doodlepad__overlay.disabled {
  height: 18.875rem;
  min-height: 14.4375rem;
}

.doodlepad {
  max-width: 18.875rem;
  min-width: 14.4375rem;
  width: 37vh;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  border-radius: 19.0476%;
  margin: 1px auto;
  line-height: 0;
  overflow: hidden;
}

.doodlepad .doodlepad__svg {
  touch-action: none;
  cursor: pointer;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper {
  transform-origin: 50%;
  animation: .5s both transitionScaleUp;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(9) {
  animation-delay: .525s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(8) {
  animation-delay: .5s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(7) {
  animation-delay: .475s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(6) {
  animation-delay: .45s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(5) {
  animation-delay: .425s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(4) {
  animation-delay: .4s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(3) {
  animation-delay: .375s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(2) {
  animation-delay: .35s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-outer-wrapper:nth-child(1) {
  animation-delay: .325s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper {
  animation: 1s both transitionFadeIn;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(18) {
  animation-delay: .775s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(17) {
  animation-delay: .75s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(16) {
  animation-delay: .725s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(15) {
  animation-delay: .7s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(14) {
  animation-delay: .675s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(13) {
  animation-delay: .65s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(12) {
  animation-delay: .625s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(11) {
  animation-delay: .6s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(10) {
  animation-delay: .575s;
}

.doodlepad .doodlepad__svg g.dpad-dots .circle-inner-wrapper:nth-child(9) {
  animation-delay: .55s;
}

.doodlepad .doodlepad__svg g.dpad-dots circle {
  stroke: #0000;
  stroke-width: 1px;
}

.theme-light .doodlepad .doodlepad__svg g.dpad-dots circle {
  fill: #d8dddd;
}

.theme-dark .doodlepad .doodlepad__svg g.dpad-dots circle {
  fill: #2f303b;
}

.doodlepad .doodlepad__svg g.dpad-dots .dpad-text {
  opacity: 1;
  fill: gray;
  text-anchor: middle;
  pointer-events: none;
  transition: opacity .3s;
}

.theme-light .doodlepad .doodlepad__svg g.dpad-dots .dpad-text {
  fill: #f8fafa;
}

.theme-dark .doodlepad .doodlepad__svg g.dpad-dots .dpad-text {
  fill: #505064;
}

.doodlepad .doodlepad__svg g.dpad-lines circle {
  transform-origin: 50%;
  pointer-events: none;
  fill: var(--theme-colour);
  animation: .5s both transitionFadeIn;
}

.doodlepad .doodlepad__svg g.dpad-lines .dpad-line {
  opacity: .6;
}

.doodlepad .doodlepad__svg g.dpad-lines .dpad-line line {
  opacity: 1;
  stroke-width: 8px;
  stroke-linecap: round;
  pointer-events: none;
  stroke: var(--theme-colour);
  transition: opacity .3s;
  animation: .2s linear transitionLineIn;
}

.doodlepad .doodlepad__svg g.dpad-lines .dpad-lineGroup {
  transition: opacity .4s linear;
}

.doodlepad .doodlepad__svg.lines-hidden g.dpad-lines, .doodlepad .doodlepad__svg.numbers-hidden g.dpad-dots .dpad-text {
  opacity: 0;
  transition: opacity .3s;
}

.circle-animation {
  animation: .3s both circleBlip;
}

.theme-light .circle-animation {
  stroke: #d8dddd !important;
}

.theme-dark .circle-animation {
  stroke: #2f303b !important;
}

.transition-opacity-0 {
  transition: opacity .3s;
  opacity: 0 !important;
}

@keyframes transitionScaleUp {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes transitionLineIn {
  0% {
    opacity: 0;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
  }

  100% {
    opacity: 1;
    stroke-dashoffset: 0;
    stroke-dasharray: 50% 0;
  }
}

@keyframes circleBlip {
  0%, 100% {
    stroke-width: 0;
  }

  50% {
    stroke-width: 4px;
  }
}

@media screen and (max-height: 640px) {
  #doodle-pad .avatar-group-row + .primary-text, #moveService_fromDoodle .avatar-group-row + .primary-text, #moveService_toDoodle .avatar-group-row + .primary-text {
    padding-top: 0;
  }

  #doodle-pad .avatar-group-row .avatar-group, #moveService_fromDoodle .avatar-group-row .avatar-group, #moveService_toDoodle .avatar-group-row .avatar-group {
    margin-top: 0;
    margin-bottom: 0;
  }

  #doodle-pad .avatar-group-row .avatar-group .login-avatar, #moveService_fromDoodle .avatar-group-row .avatar-group .login-avatar, #moveService_toDoodle .avatar-group-row .avatar-group .login-avatar {
    width: 2.5rem;
    height: 2.5rem;
  }

  #doodle-pad .doodlepad, #moveService_fromDoodle .doodlepad, #moveService_toDoodle .doodlepad {
    min-width: 12.5rem;
    max-width: 12.5rem;
    width: 100%;
  }

  #doodle-pad .doodlepad-buttons, #moveService_fromDoodle .doodlepad-buttons, #moveService_toDoodle .doodlepad-buttons {
    flex-grow: 1;
  }

  #doodle-pad .doodlepad-buttons .icn-button-lrg .f-icn, #moveService_fromDoodle .doodlepad-buttons .icn-button-lrg .f-icn, #moveService_toDoodle .doodlepad-buttons .icn-button-lrg .f-icn {
    width: 3.75rem;
    height: 3.75rem;
  }
}

#doodle-pad .text-button, #moveService_fromDoodle .text-button, #moveService_toDoodle .text-button {
  width: 22%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

:root {
  --nav-width: 17.5rem;
}

#main-nav {
  color: #f8fafa;
  flex-shrink: 0;
  display: flex;
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  #main-nav {
    min-width: 4.625rem;
    max-width: 4.625rem;
  }
}

@media screen and (min-width: 1194px) {
  #main-nav {
    min-width: var(--nav-width);
    max-width: var(--nav-width);
  }
}

@media screen and (max-width: 768px) {
  #main-nav:before {
    content: "";
    opacity: 0;
    pointer-events: none;
    z-index: 4;
    background-color: #2f303ba6;
    transition: opacity .35s;
    position: absolute;
    inset: 0;
  }

  .menu-open #main-nav:before {
    opacity: 1;
    pointer-events: all;
  }
}

#main-nav .main-nav--inner {
  width: var(--nav-width);
  box-sizing: border-box;
  background-color: #505064;
  flex-direction: column;
  display: flex;
  position: relative;
}

@media screen and (max-width: 768px) {
  #main-nav .main-nav--inner {
    z-index: 1000;
    width: 280px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-280px);
  }

  .menu-open #main-nav .main-nav--inner {
    transform: translateX(0);
  }

  .opening #main-nav .main-nav--inner, .closing #main-nav .main-nav--inner {
    transition: transform .2s ease-out;
  }
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  #main-nav .main-nav--inner {
    width: 4.625rem;
  }
}

@media screen and (max-width: 412px) {
  #main-nav .main-nav--inner {
    width: calc(100vw - 50px);
    transform: translateX(calc(-100vw + 50px));
  }
}

#main-nav .main-nav--inner h1 {
  margin-bottom: 0;
  padding: .875rem 1.0625rem;
  font-size: 1.375rem;
  line-height: 2.23438rem;
  position: relative;
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  #main-nav .main-nav--inner h1 {
    display: none;
  }
}

#main-nav [data-notification] {
  top: .375rem;
}

.main-nav__middle {
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-nav__middle {
    display: none;
  }
}

.main-nav__middle:before {
  content: "";
  height: .0625rem;
  background-color: var(--border-colour);
  display: block;
  position: absolute;
  top: -1px;
  left: .4375rem;
  right: .4375rem;
}

.theme-light .main-nav__middle:before {
  background-color: #72728e;
}

.main-nav__middle > .row {
  border: 0;
}

.main-nav__middle [data-template="newGroup"] .secondary-heading {
  text-align: center;
}

.theme-colour-blue .main-nav__middle [data-template="newGroup"] .secondary-heading {
  color: #5a96ff;
}

.theme-colour-green .main-nav__middle [data-template="newGroup"] .secondary-heading {
  color: #5af0be;
}

.theme-colour-red .main-nav__middle [data-template="newGroup"] .secondary-heading {
  color: #ff556e;
}

.theme-colour-orange .main-nav__middle [data-template="newGroup"] .secondary-heading {
  color: #ff7d5a;
}

.theme-colour-yellow .main-nav__middle [data-template="newGroup"] .secondary-heading {
  color: #ffeb46;
}

.theme-light .main-nav__middle .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.1f410fc2.svg");
}

.theme-light .main-nav__middle .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.429f287f.svg");
}

.theme-light .main-nav__middle .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.f0849b8f.svg");
}

.main-nav__middle, .main-nav__bottom {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.main-nav__middle > .row > .column > .column-group, .main-nav__bottom > .row > .column > .column-group {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.main-nav__middle > .row > .column > .column-group .icn-button, .main-nav__bottom > .row > .column > .column-group .icn-button {
  margin-left: 0;
}

.main-nav__middle > .row > .column > .column-group .icn-button .f-icn, .main-nav__bottom > .row > .column > .column-group .icn-button .f-icn {
  background-size: 1.75rem 1.75rem;
}

.main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges), .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges) {
  cursor: pointer;
  padding-left: .875rem;
  position: relative;
}

.main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges):before, .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges):before {
  content: "";
  opacity: 0;
  background-color: #30303c;
  border-radius: 999px 0 0 999px;
  transition: opacity .2s, background-color .2s;
  display: block;
  position: absolute;
  inset: 0 0 0 .875rem;
}

@media (hover: hover) {
  .main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges):hover, .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges):hover {
    background: none !important;
  }

  .main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges):hover:before, .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges):hover:before {
    opacity: 1;
  }

  .main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges):hover .column, .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges):hover .column {
    background: none !important;
  }
}

.main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges) > .column, .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges) > .column {
  padding-left: 0;
  position: relative;
}

.main-nav__middle > .row:not(.main-nav__banner):not(.main-nav__badges) .primary-text-content, .main-nav__bottom > .row:not(.main-nav__banner):not(.main-nav__badges) .primary-text-content {
  -webkit-user-select: none;
  user-select: none;
  font-size: 1rem;
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  .main-nav__middle .text-group, .main-nav__bottom .text-group {
    display: none;
  }
}

.main-nav__bottom {
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 0;
  display: flex;
  position: relative;
}

@media screen and (min-width: 769px) and (max-width: 1193px), screen and (max-width: 768px) {
  .main-nav__bottom {
    padding-top: 0;
  }

  .main-nav__bottom:before {
    content: "";
    height: .0625rem;
    background-color: var(--border-colour);
    display: block;
    position: absolute;
    top: 0;
    left: .4375rem;
    right: .4375rem;
  }

  .theme-light .main-nav__bottom:before {
    background-color: #72728e;
  }
}

.main-nav__bottom #main-nav-banner {
  height: 0;
  border: 0;
  flex-grow: 1;
  align-items: flex-end;
  padding: 0;
  display: flex;
  position: relative;
}

.main-nav__bottom #main-nav-banner:after {
  content: "";
  height: .0625rem;
  background-color: var(--border-colour);
  display: block;
  position: absolute;
  bottom: 0;
  left: .4375rem;
  right: .4375rem;
}

.theme-light .main-nav__bottom #main-nav-banner:after {
  background-color: #72728e;
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  .main-nav__bottom #main-nav-banner {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-nav__bottom #main-nav-banner {
    display: none;
  }
}

.main-nav__bottom #main-nav-banner .column {
  padding-left: 1rem;
  padding-right: 1rem;
  display: none;
}

.main-nav__bottom #main-nav-banner .column .image-wrapper {
  width: 100%;
}

.main-nav__bottom #main-nav-banner .column .image-wrapper .image-wrapper--inner {
  width: 100%;
  height: 0;
  border-radius: .5em;
  padding-bottom: 42%;
  position: relative;
  overflow: hidden;
}

.main-nav__bottom #main-nav-banner .column .image-wrapper .image-wrapper--inner img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main-nav__bottom #main-nav-badges {
  min-height: 78px;
  background: none;
  border: 0;
  position: relative;
}

.main-nav__bottom #main-nav-badges:after {
  content: "";
  height: .0625rem;
  background-color: var(--border-colour);
  display: block;
  position: absolute;
  bottom: 0;
  left: .4375rem;
  right: .4375rem;
}

.theme-light .main-nav__bottom #main-nav-badges:after {
  background-color: #72728e;
}

@media (hover: hover) {
  .main-nav__bottom #main-nav-badges:hover {
    background: none;
  }
}

.main-nav__bottom #main-nav-badges .column .column-group {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper {
  max-width: 20%;
  flex-grow: 1;
  flex-basis: 20%;
}

@media screen and (min-width: 381px) and (max-width: 412px) {
  .main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  .main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper {
    max-width: 100%;
    flex-basis: 100%;
  }

  .main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper:nth-child(n+2) {
    display: none;
  }
}

@media (hover: hover) {
  .main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper:hover img {
    opacity: 1;
    transform: scale(1.2);
  }
}

.main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper + .badge-wrapper {
  padding: 0;
}

.main-nav__bottom #main-nav-badges .column .column-group .badge-wrapper img {
  transform-origin: 50%;
  transition: opacity .35s, transform .2s;
  transform: scale(1);
}

.main-nav__bottom .row:last-child {
  margin-top: .625rem;
}

.theme-light .main-nav__bottom .f-icn-exit {
  background-image: url("forghetti-ui_exit_normal.d9ef3372.svg");
}

#menu-icon {
  height: 1.5rem;
  width: 1.5rem;
  z-index: 1;
  cursor: pointer;
  background-image: url("menu-dark.f6bd81d6.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  padding: 2.0625rem 1.4375rem;
  position: absolute;
  top: 0;
  left: 0;
}

#menu-icon * {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  #menu-icon {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

@media (max-width: 640px) {
  #menu-icon {
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
  }
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-input {
  color: #505064;
  text-indent: 0;
  width: 100%;
  height: 1.5rem;
  box-sizing: border-box;
  background-color: #0000;
  border: 0;
  border-radius: .25rem;
  margin: 0;
  padding: 0;
  font-size: 1.1875rem;
  line-height: 1.5rem;
  transition: height .2s, text-indent .2s;
  display: block;
  box-shadow: inset 0 0 0 1pt #0000;
}

.focussed .text-input + .input-action {
  height: 2.875rem;
  width: 2.875rem;
  z-index: 2000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 2.75rem 2.75rem;
  display: block;
  position: absolute;
  top: 0;
  right: .25rem;
}

.focussed .text-input {
  height: 2.875rem;
  text-indent: .8125rem;
  background-color: #e0e4e4;
  border-color: #202030;
  line-height: 2.875rem;
  transition: height .2s, text-indent .2s;
}

.theme-dark .focussed .text-input {
  color: #fff;
  background-color: #202030;
}

.focussed.primary-heading .text-input {
  height: 3.25rem;
  text-indent: .9375rem;
  line-height: 3.25rem;
}

.focussed.with-action {
  position: relative;
}

.focussed.with-action .text-input {
  padding-right: 3.125rem;
}

.align-right input {
  text-align: right;
  padding-right: .625rem;
}

.small-input {
  flex-grow: .25;
}

.input-lowercase {
  text-transform: lowercase;
}

.h5-note {
  text-transform: none;
  letter-spacing: normal;
  color: #505062;
  box-sizing: border-box;
  background-color: #5a96ff66;
  border: 1px solid #5a96ff;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: .5rem;
  padding: .7rem;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
}

.h6-note {
  text-transform: none;
  letter-spacing: normal;
  color: #505062;
  box-sizing: border-box;
  background-color: #5af0be66;
  border: 1px solid #5af0be;
  border-radius: 5px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .7rem;
  font-size: .8rem;
}

#block-templates .row {
  border: 0;
}

.list-container {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.list-container .list-header, .list-container .search {
  flex-shrink: 0;
}

.list-container .login-list-wrapper {
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  animation: .3s .2s forwards fadeInSlideUp;
  overflow-x: hidden;
  overflow-y: scroll;
}

.login-list-page .login-list-page__header {
  cursor: pointer;
  padding-right: .25rem;
}

.login-list-page .login-list-page__header * {
  cursor: pointer;
}

.login-list-page .login-list-page__header h1 {
  margin-bottom: 0;
}

.login-list-page .login-list-page__group-icon-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: .75rem;
  display: flex;
}

.login-list-page .login-list-page__group-icon {
  width: 2.25rem;
  height: 2.25rem;
  border: solid 3px var(--theme-colour);
  border-radius: 999px;
}

.login-list {
  padding-bottom: 8.375rem;
  transform: translateY(-1px);
}

@media screen and (max-width: 768px) {
  .login-list {
    padding-bottom: .625rem;
  }
}

.login-list .alpha-list-heading {
  z-index: 3;
  height: 2.5rem;
  box-sizing: border-box;
  background-color: #f8fafa;
  margin: 0;
  padding-top: .375rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  line-height: 2.125rem;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 768px) {
  .login-list .alpha-list-heading {
    font-size: 1rem;
    top: 0;
  }
}

@media (max-width: 640px) {
  .login-list .alpha-list-heading {
    height: 1.875rem;
    padding-top: .25rem;
    font-size: .875rem;
    line-height: 1.625rem;
  }
}

.theme-dark .login-list .alpha-list-heading {
  background-color: #202030;
}

.login-item {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 1.0625rem;
  position: relative;
}

@media (min-width: 1025px) {
  .login-item {
    padding-left: 1.5625rem;
  }
}

@media (hover: hover) {
  .login-item:hover .login-item-inner {
    background-color: #e6eaea;
  }

  .login-item:hover .button-group {
    opacity: 1;
  }
}

.login-item-inner {
  box-sizing: border-box;
  will-change: background-color;
  background-color: #f8fafa;
  border-radius: 999px 0 0 999px;
  flex-direction: row;
  padding-left: .4375rem;
  transition: background-color .1s ease-in;
  display: flex;
}

.theme-dark .login-item-inner {
  background-color: #202030;
}

.login-item-inner .icn-button {
  min-width: 3.75rem;
  border-bottom: .0625rem solid var(--border-colour);
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .login-item-inner .icn-button {
    min-width: 2.875rem;
  }
}

.alpha-list:last-child .login-item:last-child .login-item-inner .icn-button {
  border: 0;
}

.login-avatar-wrapper {
  flex: 0 0 2.875rem;
  padding: .4375rem 0;
}

@media (min-width: 1025px) {
  .login-avatar-wrapper {
    flex: 0 0 3.75rem;
  }
}

.login-avatar-wrapper[data-selected="true"] .login-avatar-inner:after {
  opacity: 1;
}

.login-avatar-wrapper.exists {
  opacity: .5;
}

.login-avatar-wrapper .login-avatar-inner:after {
  content: "";
  opacity: 0;
  border-style: solid;
  border-width: .1875rem;
  border-radius: 999px;
  transition: opacity .15s;
  display: block;
  position: absolute;
  inset: 0;
}

.theme-colour-blue .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #5a96ff;
}

.theme-colour-green .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #5af0be;
}

.theme-colour-red .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #ff556e;
}

.theme-colour-orange .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #ff7d5a;
}

.theme-colour-yellow .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #ffeb46;
}

.login-avatar {
  transform-origin: 50%;
  animation: .5s .1s both transitionFadeIn;
}

.login-overview-wrapper {
  box-sizing: border-box;
  min-width: 0;
  border-bottom: .0625rem solid var(--border-colour);
  flex-direction: row;
  flex: auto;
  display: flex;
}

.alpha-list:last-child .login-item:last-child .login-overview-wrapper {
  border: 0;
}

.login-overview-wrapper .login-overview {
  min-width: 0;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  padding: .375rem 0 .625rem .625rem;
  display: flex;
}

@media (min-width: 1025px) {
  .login-overview-wrapper .login-overview {
    padding-top: .4375rem;
    padding-bottom: .75rem;
  }
}

.login-overview-wrapper .login-name, .login-overview-wrapper .login-username {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  margin: 0;
  overflow: hidden;
}

.login-overview-wrapper .login-name {
  margin-bottom: -1px;
  font-family: Nunito;
}

@media (min-width: 1025px) {
  .login-overview-wrapper .login-name {
    margin-bottom: -.25rem;
    font-size: 1.1875rem;
    line-height: 1.625rem;
  }

  .login-overview-wrapper .login-username {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.login-overview-wrapper .login-overview, .login-overview-wrapper .button-group {
  padding-left: .625rem;
}

.login-overview-wrapper .button-group {
  opacity: 0;
  flex: none;
  padding-right: .625rem;
  transition: opacity .2s;
}

.login-overview-wrapper .button-group-inner {
  height: 100%;
  display: table;
}

.login-overview-wrapper .button-group-inner .icn-button {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}

.login-overview-wrapper .button-group-inner .icn-button .f-icn {
  display: inline-block;
  position: relative;
  top: auto;
  right: auto;
}

@media (min-width: 1025px) {
  .login-overview-wrapper .button-group-inner .icn-button .f-icn {
    width: 2.5rem;
    height: 2.5rem;
    background-size: 2.5rem 2.5rem;
    line-height: 2.5rem;
  }
}

.list-header {
  box-sizing: border-box;
  height: 5.5625rem;
  z-index: 1;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  display: flex;
  top: 0;
}

.list-header h1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  align-items: center;
  margin: 0 .625rem auto auto;
  display: flex;
  overflow-x: hidden;
}

.list-header .nav-link {
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  position: static;
  top: 1.5625rem;
  right: .625rem;
}

@media screen and (max-width: 768px) {
  .list-header {
    height: 3.75rem;
    padding-top: .125rem;
  }

  .list-header h1 {
    margin-top: 0;
  }

  .list-header .nav-link {
    top: .625rem;
  }
}

.search {
  z-index: 4;
  height: 3.875rem;
  box-sizing: border-box;
  flex-direction: row;
  flex: 0 0 3.875rem;
  padding: .5625rem 1rem;
  display: flex;
  position: relative;
}

.search .help-link {
  margin-left: .75rem;
}

.table-view > .search {
  padding-left: 0;
  padding-right: 0;
}

.table-view > .search:first-child {
  margin-top: .625rem;
}

.row > .column > .column-group > .search {
  flex-grow: 1;
}

.search-input {
  text-transform: none;
  box-sizing: border-box;
  height: 2.875rem;
  text-indent: 1rem;
  background-color: #e0e4e4;
  background-position: 110%;
  background-repeat: no-repeat;
  background-size: 1.375rem 1.375rem;
  border: 1px solid #e0e4e4;
  padding-left: 0;
  padding-right: .375rem;
  line-height: 2.875rem;
  transition: text-indent .2s, background-position .3s .1s, background-color .3s .1s;
}

.search-input + .search-input-clear {
  height: 2.875rem;
  width: 2.875rem;
  z-index: 2000;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
  display: block;
  position: absolute;
  top: .5625rem;
  right: 1rem;
}

.search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.search-input::placeholder {
  color: #8e8e93;
}

.search-input:invalid {
  background-position-x: calc(100% - .75rem);
}

.search-input:focus {
  transition: text-indent .1s, background-position .1s;
}

.search__wrapper {
  justify-content: flex-start;
  display: flex;
}

.search__wrapper label.search {
  flex-grow: 1;
}

.search__wrapper [data-actionclick] {
  min-width: 5.375rem;
  z-index: 4;
  margin-left: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: none;
  position: relative;
  right: .1rem;
  overflow: visible;
}

.theme-colour-blue .search__wrapper [data-actionclick] {
  color: #5a96ff;
}

.theme-colour-green .search__wrapper [data-actionclick] {
  color: #5af0be;
}

.theme-colour-red .search__wrapper [data-actionclick] {
  color: #ff556e;
}

.theme-colour-orange .search__wrapper [data-actionclick] {
  color: #ff7d5a;
}

.theme-colour-yellow .search__wrapper [data-actionclick] {
  color: #ffeb46;
}

.search__wrapper [data-actionclick]:before {
  width: 3.875rem !important;
  height: 3.875rem !important;
}

.iti {
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type="text"], .iti input[type="tel"] {
  z-index: 0;
  height: 3rem;
  margin-right: 0;
  padding-right: 2.5rem;
  transition: background-color .35s;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti input:hover, .iti input[type="text"]:hover, .iti input[type="tel"]:hover, .iti input:focus, .iti input[type="text"]:focus, .iti input[type="tel"]:focus {
  background: #0000004d;
}

.theme-light .iti input:hover, .theme-light .iti input[type="text"]:hover, .theme-light .iti input[type="tel"]:hover, .theme-light .iti input:focus, .theme-light .iti input[type="text"]:focus, .theme-light .iti input[type="tel"]:focus {
  background: #e6eaea;
}

.iti__flag-container {
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-flag {
  z-index: 1;
  height: 100%;
  align-items: center;
  padding: 0 .5rem 0 .625rem;
  display: flex;
  position: relative;
}

.iti__arrow {
  width: 0;
  height: 0;
  border-top: .25rem solid #505064;
  border-left: 3px solid #0000;
  border-right: 3px solid #0000;
  margin-left: .5rem;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: .25rem solid #505064;
}

.iti__country-list {
  z-index: 2;
  text-align: left;
  white-space: nowrap;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  background-color: #40404c;
  border: 0 solid #505064;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-y: scroll;
  box-shadow: 1px 1px 4px #0003;
}

.iti__country-list--dropup {
  margin-bottom: 0;
  bottom: 100%;
}

.theme-light .iti__country-list {
  background: #f8fafa;
  box-shadow: 1px 1px 4px #0003;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

body:not(.iti-mobile) .overlay .iti__country-list {
  min-width: 24.375rem;
  max-width: 24.375rem;
}

@media screen and (max-width: 27.5rem) {
  body:not(.iti-mobile) .overlay .iti__country-list {
    min-width: calc(100vw - 3.125rem);
    max-width: calc(100vw - 3.125rem);
  }
}

@media screen and (max-height: 540px) {
  body:not(.iti-mobile) .overlay .iti__country-list {
    min-width: 0;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    inset: 5.625rem 30px 30px;
  }
}

body:not(.iti-mobile) #pane-2 .iti__country-list {
  min-width: calc(100vw - 3.25rem);
  max-width: calc(100vw - 3.25rem);
}

@media screen and (min-width: 769px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: calc(55vw - 40.7px - 3.25rem);
    max-width: calc(55vw - 40.7px - 3.25rem);
  }
}

@media screen and (min-width: 1025px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: calc(55vw - 40.7px - 4.25rem);
    max-width: calc(55vw - 40.7px - 4.25rem);
  }
}

@media screen and (min-width: 1194px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: calc(((100vw - var(--nav-width)) * .55)  - ((1rem * 1.5) * 2)  - (.625rem * 2));
    max-width: calc(((100vw - var(--nav-width)) * .55)  - ((1rem * 1.5) * 2)  - (.625rem * 2));
  }
}

@media screen and (min-width: 1681px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: calc(((100vw - var(--nav-width)) * .5)  - ((1rem * 1.5) * 2)  - (.625rem * 2));
    max-width: calc(((100vw - var(--nav-width)) * .5)  - ((1rem * 1.5) * 2)  - (.625rem * 2));
  }
}

@media screen and (min-width: 1801px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: calc(((1800px - var(--nav-width)) * .5)  - ((1rem * 1.5) * 2)  - (.625rem * 2));
    max-width: calc(((1800px - var(--nav-width)) * .5)  - ((1rem * 1.5) * 2)  - (.625rem * 2));
  }
}

@media (max-width: 412px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: calc(100vw - 2.25rem);
    max-width: calc(100vw - 2.25rem);
  }
}

@media screen and (max-height: 540px) {
  body:not(.iti-mobile) #pane-2 .iti__country-list {
    min-width: 0;
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    inset: 5.625rem 30px 30px;
  }
}

.iti__flag-box {
  width: 1.25rem;
  display: inline-block;
}

.iti__divider {
  border-bottom: 1px solid #505064;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.theme-light .iti__divider {
  border-color: var(--border-colour);
  background: #f8fafa;
}

.iti__country {
  outline: none;
  padding: 5px 10px;
}

body:not(.iti-mobile) .iti__country {
  min-height: 48px;
  align-items: center;
  display: flex;
}

body:not(.iti-mobile) .iti__country-name {
  white-space: normal;
  flex-grow: 1;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: #0000004d;
}

.theme-light .iti__country.iti__highlight {
  background: #e6eaea;
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--separate-dial-code input, .iti--allow-dropdown input[type="text"], .iti--separate-dial-code input[type="text"], .iti--allow-dropdown input[type="tel"], .iti--separate-dial-code input[type="tel"] {
  margin-left: 0;
  padding-left: 3.625rem;
  padding-right: .375rem;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

.iti--allow-dropdown {
  width: 100%;
  transition: background-color .35s;
  display: flex;
}

.iti--allow-dropdown:hover {
  background: #0000004d;
}

.theme-light .iti--allow-dropdown:hover {
  background: #e6eaea;
}

.iti--allow-dropdown .iti__flag-container .iti__selected-flag {
  background-color: #0000;
  transition: background-color .35s;
}

body:not(.iti-mobile) .iti--allow-dropdown .iti__flag-container .iti__selected-flag {
  outline: none;
}

.theme-light .iti--allow-dropdown .iti__flag-container .iti__selected-flag {
  background: none;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #0000004d;
}

.theme-light .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background: #d0d7d7;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag, .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: #0000;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: #0000;
  border-right: 1px solid #505064;
}

.theme-light .iti--separate-dial-code .iti__selected-flag {
  border-color: var(--border-colour);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: .5rem;
}

.iti--container {
  z-index: 1060;
  padding: 0;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  position: fixed;
  inset: 5.625rem 30px 30px;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
  display: none;
}

.iti__flag .iti__be {
  width: 18px;
}

.iti__flag .iti__ch {
  width: 15px;
}

.iti__flag .iti__mc {
  width: 19px;
}

.iti__flag .iti__ne {
  width: 18px;
}

.iti__flag .iti__np {
  width: 13px;
}

.iti__flag .iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0 0;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0;
}

.iti__flag {
  height: .9375rem;
  background-color: #dbdbdb;
  background-image: url("flags.c61229ae.png");
  background-position: 1.25rem 0;
  background-repeat: no-repeat;
  box-shadow: 0 0 1px #888;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("flags@2x.adefcad5.png");
  }
}

.iti__flag.iti__np {
  background-color: #0000;
}

.primary-heading {
  letter-spacing: -.03em;
  font-family: Nunito, sans-serif;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .primary-heading {
    font-size: 1.5rem;
  }
}

.primary-heading .text-input {
  height: 2.1875rem;
  font-family: Nunito, sans-serif;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 2.1875rem;
}

@media screen and (max-width: 768px) {
  .primary-heading {
    font-size: 1.625rem;
  }
}

.column.primary-heading {
  text-align: left;
  flex: 1;
  align-items: center;
  margin: 0;
}

.row > .column > .primary-heading {
  width: 100%;
  text-align: center;
  margin: 0;
  padding-top: 1rem;
}

.align-left.row > .column > .primary-heading {
  text-align: left;
}

.groups-nav-container {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: overlay;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.groups-nav-container::-webkit-scrollbar-track {
  background-color: #0000;
}

.groups-nav-container::-webkit-scrollbar-track-piece {
  background-color: #0000;
}

.theme-light .groups-nav-container::-webkit-scrollbar-thumb {
  background-color: #15151f;
}

.groups-nav-container::-webkit-scrollbar-button {
  display: none;
}

.groups-nav-container .groups-nav-inner-container {
  flex-grow: 0;
  padding-left: .875rem;
  position: relative;
}

.groups-nav-container, #groups-nav {
  width: 100%;
}

#groups-nav {
  opacity: 0;
}

@media screen and (min-width: 769px) {
  #groups-nav {
    padding: 0 0 .75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  #groups-nav {
    padding-top: 1.125rem;
  }
}

@media screen and (max-width: 768px) {
  #groups-nav {
    padding-bottom: .875rem;
  }
}

#groups-nav:nth-child(1) {
  animation: .4s .2s forwards fadeInSlideUp;
}

#groups-nav:nth-child(2) {
  animation: .4s .3s forwards fadeInSlideUp;
}

#groups-nav li {
  cursor: pointer;
  align-items: center;
  padding-top: .4375rem;
  padding-bottom: .4375rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

#groups-nav li * {
  cursor: pointer;
}

#groups-nav li:before {
  content: "";
  opacity: 0;
  background-color: #30303c;
  border-radius: 999px 0 0 999px;
  transition: opacity .2s, background-color .2s;
  display: block;
  position: absolute;
  inset: 0;
}

#groups-nav li.active {
  color: #f8fafa;
}

#groups-nav li.active:before {
  opacity: 1;
}

.theme-colour-blue #groups-nav li.active:before {
  background-color: #5a96ff;
}

.theme-colour-green #groups-nav li.active:before {
  background-color: #5af0be;
}

.theme-colour-red #groups-nav li.active:before {
  background-color: #ff556e;
}

.theme-colour-orange #groups-nav li.active:before {
  background-color: #ff7d5a;
}

.theme-colour-yellow #groups-nav li.active:before {
  background-color: #ffeb46;
}

.theme-colour-blue #groups-nav li.active:before {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green #groups-nav li.active:before {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red #groups-nav li.active:before {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange #groups-nav li.active:before {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow #groups-nav li.active:before {
  color: #505064;
  background-color: #ffeb46;
}

@media (hover: hover) {
  #groups-nav li:hover:before {
    opacity: 1;
  }
}

#groups-nav li.disabled {
  opacity: .3;
  pointer-events: none;
}

#groups-nav li .login-avatar {
  width: 2rem;
  height: 2rem;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 .75rem 0 .4375rem;
  display: block;
  position: relative;
}

#groups-nav li .icn-button-name {
  line-height: normal;
  position: relative;
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  #groups-nav li .icn-button-name {
    display: none;
  }
}

#groups-nav .text-group {
  margin-top: .75rem;
  padding: .875rem 1.0625rem .5rem .4375rem;
  position: relative;
}

#groups-nav .text-group:before {
  content: "";
  height: .0625rem;
  background-color: var(--border-colour);
  display: block;
  position: absolute;
  top: -1px;
  left: -.1875rem;
  right: .4375rem;
}

.theme-light #groups-nav .text-group:before {
  background-color: #72728e;
}

#groups-nav .text-group .primary-text-content {
  letter-spacing: -.03em;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: .875rem;
  font-size: .875rem !important;
}

@media screen and (max-width: 768px) {
  #groups-nav .text-group .primary-text-content {
    font-size: 1.5rem;
  }
}

#groups-nav .text-group .secondary-text-content {
  letter-spacing: -.03em;
  text-transform: none;
  color: #f8fafa;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.82813rem;
  font-size: 1.375rem !important;
}

@media screen and (max-width: 768px) {
  #groups-nav .text-group .secondary-text-content {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1193px) {
  #groups-nav .text-group {
    max-height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  body.pane-2-active #main-nav ul#groups-nav li.active:before, body.pane-3-active #main-nav ul#groups-nav li.active:before {
    opacity: .4;
  }
}

.lock-screen {
  z-index: 10;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #5af0be;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.lock-screen__title {
  letter-spacing: -.03em;
  text-align: center;
  margin-bottom: 4rem;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
  display: block;
}

@media screen and (max-width: 768px) {
  .lock-screen__title {
    font-size: 1.5rem;
  }
}

.lock-screen__icon {
  width: 64px;
  height: 64px;
  background-image: url("forghetti-ui_fingerprint_normal.07de51a3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

#premium .premium {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#premium .premium > .column {
  max-width: 100%;
  flex-direction: column;
  flex-grow: 0 !important;
}

#premium .premium__tab-row {
  width: 100%;
  height: 8rem;
  padding-top: 1rem;
  display: flex;
}

#premium .premium__tab {
  color: #505064;
  width: 1px;
  opacity: .7;
  width: calc(32% - 1rem);
  cursor: pointer;
  border-bottom: 4px solid #0000001a;
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  margin-top: 1rem;
  transition: opacity .1s, width .1s, margin .1s;
  display: flex;
}

.theme-colour-blue #premium .premium__tab {
  background-color: #5a96ff;
}

.theme-colour-green #premium .premium__tab {
  background-color: #5af0be;
}

.theme-colour-red #premium .premium__tab {
  background-color: #ff556e;
}

.theme-colour-orange #premium .premium__tab {
  background-color: #ff7d5a;
}

.theme-colour-yellow #premium .premium__tab {
  background-color: #ffeb46;
}

.theme-colour-blue #premium .premium__tab {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green #premium .premium__tab {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red #premium .premium__tab {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange #premium .premium__tab {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow #premium .premium__tab {
  color: #505064;
  background-color: #ffeb46;
}

#premium .premium__tab * {
  cursor: pointer;
}

#premium .premium__tab .premium__tab-title {
  text-align: center;
  flex-grow: 1;
  margin: 0;
  padding: .5rem 2rem;
  font-size: 1.2rem;
  line-height: 1.3rem;
  transition: font-size .1s, line-height .1s;
  display: block;
}

#premium .premium__tab--active {
  opacity: 1;
  width: 36%;
  margin-top: 0;
}

#premium .premium__tab--active .premium__tab-title {
  font-size: 1.6rem;
  line-height: 1.7rem;
}

#premium .premium__tab-detail {
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  height: 2.8rem;
  background-color: #7d7d7d1a;
  border: 3px solid;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  font-family: Nunito, sans-serif;
  font-size: .75rem;
  line-height: calc(2.8rem - 6px);
  display: flex;
}

.theme-colour-blue #premium .premium__tab-detail {
  border-color: #5a96ff;
}

.theme-colour-green #premium .premium__tab-detail {
  border-color: #5af0be;
}

.theme-colour-red #premium .premium__tab-detail {
  border-color: #ff556e;
}

.theme-colour-orange #premium .premium__tab-detail {
  border-color: #ff7d5a;
}

.theme-colour-yellow #premium .premium__tab-detail {
  border-color: #ffeb46;
}

#premium .premium__tab-price {
  font-size: 1.1rem;
  font-weight: bold;
}

#premium .premium__tab:not(:first-child) {
  margin-left: 1rem;
}

#premium .premium__tab-container {
  flex-grow: 1;
  margin-top: 1rem;
  position: relative;
}

#premium .premium__tab-content {
  opacity: 0;
  z-index: 1;
  flex-direction: column;
  transition: opacity .2s, transform .2s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(20px);
}

#premium .premium__tab-content--active {
  opacity: 1;
  z-index: 2;
  transform: translateY(0);
}

#premium .premium__tab-content-box {
  color: #fff;
  height: 100%;
  background-color: #505064;
  border: 1px solid #fff3;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3vw;
}

.theme-dark #premium .premium__tab-content-box {
  background-color: #2e2e3d;
}

#premium .premium__tab-content-box h2 {
  margin: 0 0 1.5rem;
  font-size: 1.7rem;
  line-height: 1.7rem;
  flex-grow: 0 !important;
}

#premium .premium__tab-content-box ul {
  margin: 0;
  list-style: none;
}

#premium .premium__tab-content-box li {
  margin-bottom: .5rem;
  font-size: 1.2rem;
}

#premium .premium__tab-content-box li:before {
  content: "";
  height: 2rem;
  width: 2rem;
  background-image: url("forghetti-ui_check_normal.99585696.svg");
  margin-right: .5rem;
  display: inline-block;
  transform: translate(0, 9px);
}

#premium .premium__avail-soon {
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
  display: block;
}

.nav-bar--overlay-active.theme-light {
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .nav-bar--overlay-active #body-inner {
    padding-bottom: 3.75rem;
  }
}

body:not(.menu-open) .nav-bar__wrapper {
  z-index: 499;
}

@media screen and (max-width: 768px) {
  .nav-bar__wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .nav-bar__wrapper body:not(.nav-bar--overlay-active) {
    height: 3.75rem;
  }
}

@media screen and (min-width: 769px) {
  .nav-bar__wrapper {
    position: fixed;
    bottom: 0;
    left: 74px;
    right: calc(55% - 40.7px);
  }
}

@media screen and (min-width: 1194px) {
  .nav-bar__wrapper {
    right: calc((100% - var(--nav-width)) * .55);
    left: var(--nav-width);
  }
}

@media (min-width: 1681px) {
  .nav-bar__wrapper {
    right: calc((100% - var(--nav-width)) * .5);
  }
}

@media screen and (min-width: 1801px) {
  .nav-bar__wrapper {
    width: calc((1800px - var(--nav-width)) * .5);
    right: auto;
  }
}

.nav-bar__wrapper.nav-bar__overlay {
  width: auto;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  inset: 0;
}

@media screen and (min-width: 769px) {
  .nav-bar__wrapper.nav-bar__overlay .nav-bar {
    width: calc(45% + 40.7px);
    right: auto;
  }
}

@media screen and (min-width: 1194px) {
  .nav-bar__wrapper.nav-bar__overlay .nav-bar {
    width: calc(((100% - var(--nav-width)) * .45)  + var(--nav-width));
  }
}

@media (min-width: 1681px) {
  .nav-bar__wrapper.nav-bar__overlay .nav-bar {
    width: calc(((100% - var(--nav-width)) * .5)  + var(--nav-width));
  }
}

@media screen and (min-width: 1801px) {
  .nav-bar__wrapper.nav-bar__overlay .nav-bar {
    width: calc(((1800px - var(--nav-width)) * .5)  + var(--nav-width));
  }
}

.nav-bar__wrapper .nav-bar__overlay__background {
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  position: absolute;
  inset: 0;
}

.nav-bar__overlay.nav-bar__wrapper .nav-bar__overlay__background {
  opacity: 1;
}

.theme-light .nav-bar__overlay.nav-bar__wrapper .nav-bar__overlay__background {
  background-color: #f8fafaf2;
}

.theme-dark .nav-bar__overlay.nav-bar__wrapper .nav-bar__overlay__background {
  background-color: #202030f2;
}

.nav-bar {
  width: 100%;
  background-color: #0000;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .nav-bar {
    height: 3.75rem;
  }
}

@media screen and (min-width: 769px) {
  .nav-bar {
    width: 6.875rem;
    height: 0;
    justify-content: flex-start;
    align-items: flex-end;
    display: flex;
    position: absolute;
    left: auto;
  }

  .nav-bar .nav-bar__home, .nav-bar .nav-bar__groups, .nav-bar .nav-bar__healthcheck, .nav-bar .nav-bar__profile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .nav-bar[activecontext="login-list"] > .nav-bar__popup .nav-bar__popup__item[data-template="joinGroup"], .nav-bar[activecontext="login-list"] > .nav-bar__popup .nav-bar__popup__item[data-template="newGroup"], .nav-bar[activecontext="login-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="newGroupGotoPremium"] {
    display: none;
  }

  .nav-bar[activecontext="login-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewPIN"] {
    order: 3;
    transition-delay: 60ms;
  }

  .nav-bar[activecontext="login-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewWord"] {
    order: 4;
    transition-delay: 90ms;
  }

  .nav-bar[activecontext="login-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewService"] {
    order: 5;
    transition-delay: .12s;
  }

  .nav-bar[activecontext="groups-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewService"], .nav-bar[activecontext="groups-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewPIN"], .nav-bar[activecontext="groups-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewWord"] {
    display: none;
  }

  .nav-bar[activecontext="groups-list"] > .nav-bar__popup .nav-bar__popup__item[data-template="joinGroup"] {
    order: 1;
    transition-delay: 60ms;
  }

  .nav-bar[activecontext="groups-list"] > .nav-bar__popup .nav-bar__popup__item[data-template="newGroup"], .nav-bar[activecontext="groups-list"] > .nav-bar__popup .nav-bar__popup__item[data-actionclick="newGroupGotoPremium"] {
    order: 2;
    transition-delay: 90ms;
  }
}

.theme-light .nav-bar {
  background-color: #f8fafa;
  border-top: 1px solid #d5d5de;
}

.nav-bar__overlay .nav-bar {
  width: 100%;
  height: auto;
  border: none;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.theme-light .nav-bar__overlay .nav-bar {
  background-color: #0000;
}

.nav-bar .nav-bar__popup {
  pointer-events: none;
  opacity: 0;
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 769px) {
  .nav-bar .nav-bar__popup {
    padding-bottom: 8.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-bar .nav-bar__popup {
    position: fixed;
    bottom: -3.75rem;
    left: 0;
    right: 0;
  }
}

.nav-bar .nav-bar__popup.active {
  pointer-events: all;
  opacity: 1;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .nav-bar .nav-bar__popup.active {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transform: translateY(-100px);
  }
}

@media screen and (max-width: 380px), screen and (max-width: 768px) and (max-height: 450px) {
  .nav-bar .nav-bar__popup.active {
    transform: translateY(-80px);
  }
}

.nav-bar .nav-bar__popup .nav-bar__popup__item {
  opacity: 0;
  cursor: pointer;
  border-radius: 0 999px 999px 0;
  flex-grow: 1;
  flex-basis: 33.3333%;
  align-items: center;
  margin: 0;
  padding: .3125rem 0;
  transition: transform .2s, opacity .2s 20ms;
  display: flex;
  position: relative;
  transform: none;
}

.nav-bar__overlay .nav-bar .nav-bar__popup .nav-bar__popup__item {
  transform: translate(0);
}

.nav-bar .nav-bar__popup .nav-bar__popup__item[data-template="joinGroup"] {
  order: 1;
  transition-delay: 60ms;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item[data-template="newGroup"], .nav-bar .nav-bar__popup .nav-bar__popup__item[data-actionclick="newGroupGotoPremium"] {
  order: 2;
  transition-delay: 90ms;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewPIN"] {
  order: 3;
  transition-delay: .12s;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewWord"] {
  order: 4;
  transition-delay: .15s;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item[data-actionclick="beginAddNewService"] {
  order: 5;
  transition-delay: .18s;
}

@media screen and (max-width: 768px) {
  .nav-bar .nav-bar__popup .nav-bar__popup__item {
    border-radius: 999px;
    flex-direction: row;
    margin-top: 10px;
    padding: .3125rem .625rem .4375rem 1.5rem;
    transition: transform .2s, opacity .2s 20ms, background .35s;
  }

  .theme-light .nav-bar .nav-bar__popup .nav-bar__popup__item {
    background-color: #f2f6f6;
  }

  .theme-dark .nav-bar .nav-bar__popup .nav-bar__popup__item {
    background-color: #1c1c2a;
  }

  .nav-bar .nav-bar__popup .nav-bar__popup__item .f-icn {
    width: 1rem;
    height: 1rem;
    background-size: 1.75rem 1.75rem;
    line-height: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-height: 500px) {
  .nav-bar .nav-bar__popup .nav-bar__popup__item .f-icn {
    width: 1.2rem;
    height: 1.2rem;
    background-size: 1.75rem 1.75rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 380px), screen and (max-width: 768px) and (max-height: 450px) {
  .nav-bar .nav-bar__popup .nav-bar__popup__item {
    padding: .125rem .375rem .25rem 1.25rem;
  }

  .nav-bar .nav-bar__popup .nav-bar__popup__item .f-icn {
    background-size: 1.5rem 1.5rem;
  }
}

.theme-light .nav-bar .nav-bar__popup .nav-bar__popup__item:hover {
  background-color: #dae5e5;
}

.theme-dark .nav-bar .nav-bar__popup .nav-bar__popup__item:hover {
  background-color: #0c0c11;
}

.theme-light .nav-bar .nav-bar__popup .nav-bar__popup__item:hover .nav-bar__popup__icon {
  background-color: #dae5e5;
}

.theme-dark .nav-bar .nav-bar__popup .nav-bar__popup__item:hover .nav-bar__popup__icon {
  background-color: #0c0c11;
}

.nav-bar__overlay .nav-bar .nav-bar__popup .nav-bar__popup__item {
  opacity: 1;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__icon {
  cursor: pointer;
  border-radius: 50%;
  flex-grow: 0;
  order: 2;
  margin: 0;
  padding: .5rem;
  transition: background .35s;
  transform: none;
}

@media screen and (max-width: 768px) {
  .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__icon {
    background: none !important;
  }
}

.theme-light .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__icon {
  background-color: #f2f6f6;
}

.theme-dark .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__icon {
  background-color: #1c1c2a;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__icon .f-icn-sharing {
  margin-top: 0;
}

.nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__text {
  white-space: nowrap;
  text-align: center;
  flex-grow: 1;
  order: 1;
  font-size: 1.25rem;
  transform: none;
}

@media screen and (min-width: 769px) {
  .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__text {
    text-align: right;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 380px), screen and (max-width: 768px) and (max-height: 450px) {
  .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__text {
    font-size: 1rem;
  }
}

.theme-dark .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__text {
  color: #f8fafa;
}

.theme-light .nav-bar .nav-bar__popup .nav-bar__popup__item .nav-bar__popup__text {
  color: #202030;
}

@media screen and (min-width: 769px) {
  .nav-bar .nav-bar__button-group {
    width: 100%;
    height: 0;
    display: flex;
    background: none !important;
  }
}

.nav-bar .nav-bar__button-group.popup {
  background-color: #0000 !important;
}

.nav-bar ul {
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nav-bar li {
  text-align: center;
  color: #f8fafa;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 20%;
  padding-bottom: .25rem;
  display: block;
  position: relative;
  opacity: 1 !important;
}

.nav-bar li:before {
  content: "";
  opacity: 0;
  transition: opacity .2s;
  display: block;
  position: absolute;
  inset: 0;
}

.theme-colour-blue .nav-bar li:before {
  color: #5a96ff;
}

.theme-colour-green .nav-bar li:before {
  color: #5af0be;
}

.theme-colour-red .nav-bar li:before {
  color: #ff556e;
}

.theme-colour-orange .nav-bar li:before {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-bar li:before {
  color: #ffeb46;
}

.theme-colour-blue .nav-bar li.active:before {
  color: #5a96ff;
}

.theme-colour-green .nav-bar li.active:before {
  color: #5af0be;
}

.theme-colour-red .nav-bar li.active:before {
  color: #ff556e;
}

.theme-colour-orange .nav-bar li.active:before {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-bar li.active:before {
  color: #ffeb46;
}

.nav-bar li:hover:before {
  opacity: 1;
}

.nav-bar li button {
  margin: 0 auto;
  display: block;
}

.nav-bar li .icn-button-name {
  color: #8e8e93;
  font-size: .5625rem;
  display: block;
  position: relative;
  top: -.1875rem;
}

.nav-bar .f-icn-home {
  background-image: url("forghetti-ui_home_normal.79ba3db7.svg");
}

.nav-bar__overlay .nav-bar .nav-bar__home {
  pointer-events: none;
  opacity: .1 !important;
}

.theme-colour-blue .nav-bar .nav-bar__home.active .icn-button-name {
  color: #5a96ff;
}

.theme-colour-green .nav-bar .nav-bar__home.active .icn-button-name {
  color: #5af0be;
}

.theme-colour-red .nav-bar .nav-bar__home.active .icn-button-name {
  color: #ff556e;
}

.theme-colour-orange .nav-bar .nav-bar__home.active .icn-button-name {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-bar .nav-bar__home.active .icn-button-name {
  color: #ffeb46;
}

.theme-colour-blue .nav-bar .nav-bar__home.active .f-icn-home {
  background-image: url("forghetti-ui_home_normal.12f0e9dd.svg");
}

.theme-colour-green .nav-bar .nav-bar__home.active .f-icn-home {
  background-image: url("forghetti-ui_home_normal.582b0d96.svg");
}

.theme-colour-red .nav-bar .nav-bar__home.active .f-icn-home {
  background-image: url("forghetti-ui_home_normal.87e94c83.svg");
}

.theme-colour-orange .nav-bar .nav-bar__home.active .f-icn-home {
  background-image: url("forghetti-ui_home_normal.8e2f3ca0.svg");
}

.theme-colour-yellow .nav-bar .nav-bar__home.active .f-icn-home {
  background-image: url("forghetti-ui_home_normal.10ca9344.svg");
}

@media (hover: hover) and (pointer: fine) {
  .theme-colour-blue .nav-bar .nav-bar__home:hover .icn-button-name {
    color: #5a96ff;
  }

  .theme-colour-green .nav-bar .nav-bar__home:hover .icn-button-name {
    color: #5af0be;
  }

  .theme-colour-red .nav-bar .nav-bar__home:hover .icn-button-name {
    color: #ff556e;
  }

  .theme-colour-orange .nav-bar .nav-bar__home:hover .icn-button-name {
    color: #ff7d5a;
  }

  .theme-colour-yellow .nav-bar .nav-bar__home:hover .icn-button-name {
    color: #ffeb46;
  }

  .theme-colour-blue .nav-bar .nav-bar__home:hover .f-icn-home {
    background-image: url("forghetti-ui_home_normal.12f0e9dd.svg");
  }

  .theme-colour-green .nav-bar .nav-bar__home:hover .f-icn-home {
    background-image: url("forghetti-ui_home_normal.582b0d96.svg");
  }

  .theme-colour-red .nav-bar .nav-bar__home:hover .f-icn-home {
    background-image: url("forghetti-ui_home_normal.87e94c83.svg");
  }

  .theme-colour-orange .nav-bar .nav-bar__home:hover .f-icn-home {
    background-image: url("forghetti-ui_home_normal.8e2f3ca0.svg");
  }

  .theme-colour-yellow .nav-bar .nav-bar__home:hover .f-icn-home {
    background-image: url("forghetti-ui_home_normal.10ca9344.svg");
  }
}

.nav-bar .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.c3f13507.svg");
}

.theme-light .nav-bar .nav-bar__popup__icon .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.204e363a.svg");
}

.theme-dark .nav-bar .nav-bar__popup__icon .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.c3f13507.svg");
}

.nav-bar__overlay .nav-bar .nav-bar__groups {
  pointer-events: none;
  opacity: .1 !important;
}

.theme-colour-blue .nav-bar .nav-bar__groups.active .icn-button-name {
  color: #5a96ff;
}

.theme-colour-green .nav-bar .nav-bar__groups.active .icn-button-name {
  color: #5af0be;
}

.theme-colour-red .nav-bar .nav-bar__groups.active .icn-button-name {
  color: #ff556e;
}

.theme-colour-orange .nav-bar .nav-bar__groups.active .icn-button-name {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-bar .nav-bar__groups.active .icn-button-name {
  color: #ffeb46;
}

.theme-colour-blue .nav-bar .nav-bar__groups.active .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.11e2f603.svg");
}

.theme-colour-green .nav-bar .nav-bar__groups.active .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.4c2b1775.svg");
}

.theme-colour-red .nav-bar .nav-bar__groups.active .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.a775b619.svg");
}

.theme-colour-orange .nav-bar .nav-bar__groups.active .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.f774428e.svg");
}

.theme-colour-yellow .nav-bar .nav-bar__groups.active .f-icn-sharing {
  background-image: url("forghetti-ui_sharing_normal.1b1edb2a.svg");
}

@media (hover: hover) and (pointer: fine) {
  .theme-colour-blue .nav-bar .nav-bar__groups:hover .icn-button-name {
    color: #5a96ff;
  }

  .theme-colour-green .nav-bar .nav-bar__groups:hover .icn-button-name {
    color: #5af0be;
  }

  .theme-colour-red .nav-bar .nav-bar__groups:hover .icn-button-name {
    color: #ff556e;
  }

  .theme-colour-orange .nav-bar .nav-bar__groups:hover .icn-button-name {
    color: #ff7d5a;
  }

  .theme-colour-yellow .nav-bar .nav-bar__groups:hover .icn-button-name {
    color: #ffeb46;
  }

  .theme-colour-blue .nav-bar .nav-bar__groups:hover .f-icn-sharing {
    background-image: url("forghetti-ui_sharing_normal.11e2f603.svg");
  }

  .theme-colour-green .nav-bar .nav-bar__groups:hover .f-icn-sharing {
    background-image: url("forghetti-ui_sharing_normal.4c2b1775.svg");
  }

  .theme-colour-red .nav-bar .nav-bar__groups:hover .f-icn-sharing {
    background-image: url("forghetti-ui_sharing_normal.a775b619.svg");
  }

  .theme-colour-orange .nav-bar .nav-bar__groups:hover .f-icn-sharing {
    background-image: url("forghetti-ui_sharing_normal.f774428e.svg");
  }

  .theme-colour-yellow .nav-bar .nav-bar__groups:hover .f-icn-sharing {
    background-image: url("forghetti-ui_sharing_normal.1b1edb2a.svg");
  }
}

.nav-bar .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.bf1aae60.svg");
}

.nav-bar__overlay .nav-bar .nav-bar__healthcheck {
  pointer-events: none;
  opacity: .1 !important;
}

.theme-colour-blue .nav-bar .nav-bar__healthcheck.active .icn-button-name {
  color: #5a96ff;
}

.theme-colour-green .nav-bar .nav-bar__healthcheck.active .icn-button-name {
  color: #5af0be;
}

.theme-colour-red .nav-bar .nav-bar__healthcheck.active .icn-button-name {
  color: #ff556e;
}

.theme-colour-orange .nav-bar .nav-bar__healthcheck.active .icn-button-name {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-bar .nav-bar__healthcheck.active .icn-button-name {
  color: #ffeb46;
}

.theme-colour-blue .nav-bar .nav-bar__healthcheck.active .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.7be38069.svg");
}

.theme-colour-green .nav-bar .nav-bar__healthcheck.active .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.04923d0a.svg");
}

.theme-colour-red .nav-bar .nav-bar__healthcheck.active .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.58a94d3d.svg");
}

.theme-colour-orange .nav-bar .nav-bar__healthcheck.active .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.7932f0d4.svg");
}

.theme-colour-yellow .nav-bar .nav-bar__healthcheck.active .f-icn-healthcheck {
  background-image: url("forghetti-ui_healthcheck_normal.f01f8243.svg");
}

@media (hover: hover) and (pointer: fine) {
  .theme-colour-blue .nav-bar .nav-bar__healthcheck:hover .icn-button-name {
    color: #5a96ff;
  }

  .theme-colour-green .nav-bar .nav-bar__healthcheck:hover .icn-button-name {
    color: #5af0be;
  }

  .theme-colour-red .nav-bar .nav-bar__healthcheck:hover .icn-button-name {
    color: #ff556e;
  }

  .theme-colour-orange .nav-bar .nav-bar__healthcheck:hover .icn-button-name {
    color: #ff7d5a;
  }

  .theme-colour-yellow .nav-bar .nav-bar__healthcheck:hover .icn-button-name {
    color: #ffeb46;
  }

  .theme-colour-blue .nav-bar .nav-bar__healthcheck:hover .f-icn-healthcheck {
    background-image: url("forghetti-ui_healthcheck_normal.7be38069.svg");
  }

  .theme-colour-green .nav-bar .nav-bar__healthcheck:hover .f-icn-healthcheck {
    background-image: url("forghetti-ui_healthcheck_normal.04923d0a.svg");
  }

  .theme-colour-red .nav-bar .nav-bar__healthcheck:hover .f-icn-healthcheck {
    background-image: url("forghetti-ui_healthcheck_normal.58a94d3d.svg");
  }

  .theme-colour-orange .nav-bar .nav-bar__healthcheck:hover .f-icn-healthcheck {
    background-image: url("forghetti-ui_healthcheck_normal.7932f0d4.svg");
  }

  .theme-colour-yellow .nav-bar .nav-bar__healthcheck:hover .f-icn-healthcheck {
    background-image: url("forghetti-ui_healthcheck_normal.f01f8243.svg");
  }
}

.nav-bar .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.9195939d.svg");
}

.nav-bar__overlay .nav-bar .nav-bar__profile {
  pointer-events: none;
  opacity: .1 !important;
}

.theme-colour-blue .nav-bar .nav-bar__profile.active .icn-button-name {
  color: #5a96ff;
}

.theme-colour-green .nav-bar .nav-bar__profile.active .icn-button-name {
  color: #5af0be;
}

.theme-colour-red .nav-bar .nav-bar__profile.active .icn-button-name {
  color: #ff556e;
}

.theme-colour-orange .nav-bar .nav-bar__profile.active .icn-button-name {
  color: #ff7d5a;
}

.theme-colour-yellow .nav-bar .nav-bar__profile.active .icn-button-name {
  color: #ffeb46;
}

.theme-colour-blue .nav-bar .nav-bar__profile.active .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.f9194545.svg");
}

.theme-colour-green .nav-bar .nav-bar__profile.active .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.5fe507ec.svg");
}

.theme-colour-red .nav-bar .nav-bar__profile.active .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.48079556.svg");
}

.theme-colour-orange .nav-bar .nav-bar__profile.active .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.b3d24e3f.svg");
}

.theme-colour-yellow .nav-bar .nav-bar__profile.active .f-icn-profile {
  background-image: url("forghetti-ui_profile_normal.2576644f.svg");
}

@media (hover: hover) and (pointer: fine) {
  .theme-colour-blue .nav-bar .nav-bar__profile:hover .icn-button-name {
    color: #5a96ff;
  }

  .theme-colour-green .nav-bar .nav-bar__profile:hover .icn-button-name {
    color: #5af0be;
  }

  .theme-colour-red .nav-bar .nav-bar__profile:hover .icn-button-name {
    color: #ff556e;
  }

  .theme-colour-orange .nav-bar .nav-bar__profile:hover .icn-button-name {
    color: #ff7d5a;
  }

  .theme-colour-yellow .nav-bar .nav-bar__profile:hover .icn-button-name {
    color: #ffeb46;
  }

  .theme-colour-blue .nav-bar .nav-bar__profile:hover .f-icn-profile {
    background-image: url("forghetti-ui_profile_normal.f9194545.svg");
  }

  .theme-colour-green .nav-bar .nav-bar__profile:hover .f-icn-profile {
    background-image: url("forghetti-ui_profile_normal.5fe507ec.svg");
  }

  .theme-colour-red .nav-bar .nav-bar__profile:hover .f-icn-profile {
    background-image: url("forghetti-ui_profile_normal.48079556.svg");
  }

  .theme-colour-orange .nav-bar .nav-bar__profile:hover .f-icn-profile {
    background-image: url("forghetti-ui_profile_normal.b3d24e3f.svg");
  }

  .theme-colour-yellow .nav-bar .nav-bar__profile:hover .f-icn-profile {
    background-image: url("forghetti-ui_profile_normal.2576644f.svg");
  }
}

.nav-bar #nav-bar-add {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

@media screen and (min-width: 769px) {
  .nav-bar #nav-bar-add {
    height: 5rem;
    width: 5rem;
    margin: 0;
    transition: transform .35s;
    position: absolute;
    bottom: 1.75rem;
    right: 1.5rem;
    transform: scale(1);
  }
}

.nav-bar #nav-bar-add:before {
  display: none;
}

@media (hover: hover) and (pointer: fine) {
  .nav-bar #nav-bar-add:hover {
    background-color: none;
  }

  .nav-bar #nav-bar-add:before {
    opacity: 0;
  }
}

@media (hover: hover) and (pointer: fine) and (min-width: 769px) {
  .nav-bar #nav-bar-add:hover {
    transform: scale(1.25);
  }
}

.nav-bar #nav-bar-add button {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 999px;
  transition: transform .2s;
}

@media screen and (min-width: 769px) {
  .nav-bar #nav-bar-add button {
    height: 5rem;
    width: 5rem;
  }
}

.nav-bar__overlay .nav-bar #nav-bar-add button {
  transform: rotate(45deg);
}

.nav-bar #nav-bar-add button:before {
  content: "";
  background-color: var(--theme-colour);
  border-radius: 999px;
  display: block;
  position: absolute;
  inset: 0;
}

.nav-bar #nav-bar-add button .f-icn-add {
  height: 2rem;
  width: 2rem;
  position: relative;
}

.theme-light .nav-bar #nav-bar-add button .f-icn-add, .theme-dark .nav-bar #nav-bar-add button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.08a57831.svg");
}

.theme-colour-green.theme-dark .nav-bar #nav-bar-add button .f-icn-add, .theme-colour-yellow.theme-dark .nav-bar #nav-bar-add button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.53f0147e.svg");
}

@media screen and (min-width: 769px) {
  .nav-bar #nav-bar-add button .f-icn-add {
    width: 2.25rem;
    height: 2.25rem;
    background-size: contain;
  }
}

@keyframes bounce-in-bottom {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.table-view .tabbed-picker {
  width: 100%;
  display: flex;
}

.table-view .tabbed-picker > .column {
  text-align: center;
  cursor: pointer;
  border-style: solid;
  border-width: .0625rem;
  flex: 1;
  padding: .3125rem;
  font-size: 1.125rem;
}

.theme-colour-blue .table-view .tabbed-picker > .column {
  border-color: #5a96ff;
}

.theme-colour-green .table-view .tabbed-picker > .column {
  border-color: #5af0be;
}

.theme-colour-red .table-view .tabbed-picker > .column {
  border-color: #ff556e;
}

.theme-colour-orange .table-view .tabbed-picker > .column {
  border-color: #ff7d5a;
}

.theme-colour-yellow .table-view .tabbed-picker > .column {
  border-color: #ffeb46;
}

.theme-colour-blue .table-view .tabbed-picker > .column {
  color: #5a96ff;
}

.theme-colour-green .table-view .tabbed-picker > .column {
  color: #5af0be;
}

.theme-colour-red .table-view .tabbed-picker > .column {
  color: #ff556e;
}

.theme-colour-orange .table-view .tabbed-picker > .column {
  color: #ff7d5a;
}

.theme-colour-yellow .table-view .tabbed-picker > .column {
  color: #ffeb46;
}

.table-view .tabbed-picker > .column + .column {
  margin-left: -.0625rem;
}

.table-view .tabbed-picker > .column:first-child {
  border-radius: .1875rem 0 0 .1875rem;
}

.table-view .tabbed-picker > .column:last-child {
  border-radius: 0 .1875rem .1875rem 0;
}

.theme-colour-blue .table-view .tabbed-picker > .column.active, .theme-colour-blue .table-view .tabbed-picker > .column:hover {
  background-color: #5a96ff !important;
}

.theme-colour-green .table-view .tabbed-picker > .column.active, .theme-colour-green .table-view .tabbed-picker > .column:hover {
  background-color: #5af0be !important;
}

.theme-colour-red .table-view .tabbed-picker > .column.active, .theme-colour-red .table-view .tabbed-picker > .column:hover {
  background-color: #ff556e !important;
}

.theme-colour-orange .table-view .tabbed-picker > .column.active, .theme-colour-orange .table-view .tabbed-picker > .column:hover {
  background-color: #ff7d5a !important;
}

.theme-colour-yellow .table-view .tabbed-picker > .column.active, .theme-colour-yellow .table-view .tabbed-picker > .column:hover {
  background-color: #ffeb46 !important;
}

.theme-colour-blue .table-view .tabbed-picker > .column.active, .theme-colour-blue .table-view .tabbed-picker > .column:hover {
  color: #f8fafa !important;
  background-color: #5a96ff !important;
}

.theme-colour-green .table-view .tabbed-picker > .column.active, .theme-colour-green .table-view .tabbed-picker > .column:hover {
  color: #505064 !important;
  background-color: #5af0be !important;
}

.theme-colour-red .table-view .tabbed-picker > .column.active, .theme-colour-red .table-view .tabbed-picker > .column:hover {
  color: #f8fafa !important;
  background-color: #ff556e !important;
}

.theme-colour-orange .table-view .tabbed-picker > .column.active, .theme-colour-orange .table-view .tabbed-picker > .column:hover {
  color: #f8fafa !important;
  background-color: #ff7d5a !important;
}

.theme-colour-yellow .table-view .tabbed-picker > .column.active, .theme-colour-yellow .table-view .tabbed-picker > .column:hover {
  color: #505064 !important;
  background-color: #ffeb46 !important;
}

.theme-light .table-view .tabbed-picker > .column.active, .theme-light .table-view .tabbed-picker > .column:hover {
  color: #f8fafa;
}

.activation-group {
  width: 100%;
  letter-spacing: -.03em;
  text-align: center;
  justify-content: center;
  font-family: Nunito, sans-serif;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.1;
  display: flex;
}

@media screen and (max-width: 768px) {
  .activation-group {
    font-size: 1.5rem;
  }
}

.activation-group .column:not(label) {
  letter-spacing: -.03em;
  height: 3.75rem;
  width: 1.5625rem;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.75rem;
  padding: 0 !important;
}

@media screen and (max-width: 768px) {
  .activation-group .column:not(label) {
    font-size: 1.5rem;
  }
}

@media (min-width: 1281px) {
  .activation-group .column:not(label) {
    height: 5.625rem;
    width: 2.3125rem;
    font-size: 3.1875rem;
    line-height: 5.625rem;
  }
}

.activation-group label {
  z-index: 0;
  flex: 0;
  margin: 0 -.0625rem 0 0;
  position: relative;
  box-shadow: inset 0 0 0 .0625rem #e0e4e4;
  padding: 0 !important;
}

.activation-group label:first-child {
  margin-left: 0;
}

.activation-group label:first-child, .activation-group label:nth-child(5) {
  border-radius: .3125rem 0 0 .3125rem;
}

.activation-group label:last-child, .activation-group label:nth-child(3) {
  border-radius: 0 .3125rem .3125rem 0;
}

.activation-group label.current-focus {
  z-index: 1;
}

.theme-colour-blue .activation-group label.current-focus {
  box-shadow: inset 0 0 0 .0625rem #5a96ff;
}

.theme-colour-green .activation-group label.current-focus {
  box-shadow: inset 0 0 0 .0625rem #5af0be;
}

.theme-colour-red .activation-group label.current-focus {
  box-shadow: inset 0 0 0 .0625rem #ff556e;
}

.theme-colour-orange .activation-group label.current-focus {
  box-shadow: inset 0 0 0 .0625rem #ff7d5a;
}

.theme-colour-yellow .activation-group label.current-focus {
  box-shadow: inset 0 0 0 .0625rem #ffeb46;
}

.radio-button {
  min-height: 2.75rem;
  width: 2.75rem;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

#view-user-preferences .radio-button {
  opacity: .6;
}

#view-user-preferences .radio-button .fill {
  width: 100%;
  height: 100%;
}

#view-user-preferences .radio-button.active {
  opacity: 1;
}

.radio-button .border {
  width: 1.625rem;
  height: 1.625rem;
  box-sizing: border-box;
  border: .125rem solid #e0e4e4;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 1025px) {
  .radio-button .border {
    width: 32px;
    height: 32px;
    border-width: 3px;
  }
}

.radio-button .fill {
  width: .875rem;
  height: .875rem;
  border-radius: 999px;
}

.theme-colour-blue .radio-button .fill {
  background-color: #5a96ff;
}

.theme-colour-green .radio-button .fill {
  background-color: #5af0be;
}

.theme-colour-red .radio-button .fill {
  background-color: #ff556e;
}

.theme-colour-orange .radio-button .fill {
  background-color: #ff7d5a;
}

.theme-colour-yellow .radio-button .fill {
  background-color: #ffeb46;
}

.theme-colour-blue .radio-button .fill {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .radio-button .fill {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .radio-button .fill {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .radio-button .fill {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .radio-button .fill {
  color: #505064;
  background-color: #ffeb46;
}

@media (min-width: 1025px) {
  .radio-button .fill {
    width: 18px;
    height: 18px;
  }
}

.radio-button.blue .border, .radio-button.orange .border, .radio-button.red .border {
  background-color: #f8fafa;
}

.radio-button.green .border, .radio-button.yellow .border {
  background-color: #202030;
  border-color: #505064;
}

.radio-button.blue .fill {
  background-color: #5a96ff;
}

.radio-button.blue.active .border {
  border-color: #5a96ff;
}

.radio-button.green .fill {
  background-color: #5af0be;
}

.radio-button.green.active .border {
  border-color: #5af0be;
}

.radio-button.red .fill {
  background-color: #ff556e;
}

.radio-button.red.active .border {
  border-color: #ff556e;
}

.radio-button.orange .fill {
  background-color: #ff7d5a;
}

.radio-button.orange.active .border {
  border-color: #ff7d5a;
}

.radio-button.yellow .fill {
  background-color: #ffeb46;
}

.radio-button.yellow.active .border {
  border-color: #ffeb46;
}

.radio-button.dark__green .fill {
  background-image: linear-gradient(125deg, #202030 50%, #5af0be 50%);
}

.radio-button.dark__yellow .fill {
  background-image: linear-gradient(125deg, #202030 50%, #ffeb46 50%);
}

.radio-button.dark__blue .fill {
  background-image: linear-gradient(125deg, #202030 50%, #5a96ff 50%);
}

.radio-button.dark__red .fill {
  background-image: linear-gradient(125deg, #202030 50%, #ff556e 50%);
}

.radio-button.dark__orange .fill {
  background-image: linear-gradient(125deg, #202030 50%, #ff7d5a 50%);
}

.radio-button.light__blue .fill {
  background-image: linear-gradient(125deg, #f8fafa 50%, #5a96ff 50%);
}

.radio-button.light__red .fill {
  background-image: linear-gradient(125deg, #f8fafa 50%, #ff556e 50%);
}

.radio-button.light__orange .fill {
  background-image: linear-gradient(125deg, #f8fafa 50%, #ff7d5a 50%);
}

.switch {
  z-index: 1;
  width: 60px;
  height: 1.875rem;
  display: inline-block;
  position: relative;
  background: none !important;
}

.switch input {
  display: none;
}

.switch input:checked + .slider {
  background-color: #4ad763;
  border-color: #4ad763;
}

.switch input:checked + .slider:before {
  transform: translateX(30px);
}

.switch .slider {
  cursor: pointer;
  box-sizing: border-box;
  background-color: #b4b4b8;
  border: 1px solid #b4b4b8;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.switch .slider:before {
  content: "";
  height: 1.875rem;
  width: 1.875rem;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  top: -.0625rem;
  bottom: 0;
  left: -.0625rem;
  box-shadow: 0 .125rem .25rem #0003;
}

.switch .slider.round, .switch .slider.round:before {
  border-radius: 999px;
}

.cta-button {
  width: 86%;
  max-width: 25rem;
  box-sizing: border-box;
  color: #505064;
  border-style: solid;
  border-width: .09375rem;
  border-radius: 999px;
  margin: 1.375rem auto 0;
  padding: 1.0625rem 1.25rem 1.1875rem;
  font-size: 1.3125rem;
  font-weight: 500;
  transition: background-color .2s, color .2s;
  display: block;
}

.theme-colour-blue .cta-button {
  background-color: #5a96ff;
}

.theme-colour-green .cta-button {
  background-color: #5af0be;
}

.theme-colour-red .cta-button {
  background-color: #ff556e;
}

.theme-colour-orange .cta-button {
  background-color: #ff7d5a;
}

.theme-colour-yellow .cta-button {
  background-color: #ffeb46;
}

.theme-colour-blue .cta-button {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .cta-button {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .cta-button {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .cta-button {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .cta-button {
  color: #505064;
  background-color: #ffeb46;
}

.theme-colour-blue .cta-button {
  border-color: #5a96ff;
}

.theme-colour-green .cta-button {
  border-color: #5af0be;
}

.theme-colour-red .cta-button {
  border-color: #ff556e;
}

.theme-colour-orange .cta-button {
  border-color: #ff7d5a;
}

.theme-colour-yellow .cta-button {
  border-color: #ffeb46;
}

@media screen and (max-width: 380px) {
  .cta-button {
    width: calc(100% - 1.25rem);
  }
}

.cta-button:hover:not(.disabled) {
  background-color: #0000;
}

.theme-colour-blue .cta-button:hover:not(.disabled) {
  color: #5a96ff;
}

.theme-colour-green .cta-button:hover:not(.disabled) {
  color: #5af0be;
}

.theme-colour-red .cta-button:hover:not(.disabled) {
  color: #ff556e;
}

.theme-colour-orange .cta-button:hover:not(.disabled) {
  color: #ff7d5a;
}

.theme-colour-yellow .cta-button:hover:not(.disabled) {
  color: #ffeb46;
}

.cta-button.text-button {
  margin: 0;
}

.cta-button.text-button.column {
  padding: 1.0625rem 0 1.1875rem;
}

.theme-dark .cta-button.danger {
  background-color: #ff556e;
  border-color: #ff556e;
  color: #f8fafa !important;
}

.theme-dark .cta-button.danger:hover:not(.disabled) {
  color: #ff556e;
  background-color: #0000;
}

.icn-button-med + .column, .icn-button-lrg + .column {
  align-items: center;
  display: flex;
}

.theme-colour-blue .icn-button.highlight .label {
  color: #5a96ff;
}

.theme-colour-green .icn-button.highlight .label {
  color: #5af0be;
}

.theme-colour-red .icn-button.highlight .label {
  color: #ff556e;
}

.theme-colour-orange .icn-button.highlight .label {
  color: #ff7d5a;
}

.theme-colour-yellow .icn-button.highlight .label {
  color: #ffeb46;
}

@media (hover: hover) {
  #login-list .icn-button:not(.icn-button-lrg):before, #groups-list .icn-button:not(.icn-button-lrg):before, #pane-2 .section-pane:not(#view-profile-account, .suggested-add-button) .icn-button:not(.icn-button-lrg):before {
    content: "";
    width: 4.5rem;
    height: 4.5rem;
    opacity: 0;
    pointer-events: none;
    background: #40404c;
    border-radius: 999px;
    transition: transform .15s, opacity .15s;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)scale(.8);
  }

  .theme-light #login-list .icn-button:not(.icn-button-lrg):before, .theme-light #groups-list .icn-button:not(.icn-button-lrg):before, .theme-light #pane-2 .section-pane:not(#view-profile-account, .suggested-add-button) .icn-button:not(.icn-button-lrg):before {
    background: #d8dddd;
  }

  #login-list .has-label.icn-button:not(.icn-button-lrg):before, #groups-list .has-label.icn-button:not(.icn-button-lrg):before, #pane-2 .section-pane:not(#view-profile-account) .has-label.icn-button:not(.icn-button-lrg):before {
    top: calc(50% + .0625rem);
  }

  #login-list .login-item-inner .icn-button:not(.icn-button-lrg):before {
    width: 3.75rem;
    height: 3.75rem;
  }

  .cta .icn-button:not(.icn-button-lrg):before, .loading-password .icn-button:not(.icn-button-lrg):before {
    display: none !important;
  }

  #login-list .icn-button:not(.icn-button-lrg):hover:before, #groups-list .icn-button:not(.icn-button-lrg):hover:before, #pane-2 .section-pane:not(#view-profile-account, #suggested-add-button) .icn-button:not(.icn-button-lrg):hover:before {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1);
  }

  .icn-button:not(.icn-button-lrg) * {
    position: relative;
  }

  .icn-button:not(.icn-button-lrg) .icon-accessory-dot {
    width: .5rem;
    height: .5rem;
    background-color: #ff556e;
    border-radius: 50%;
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
}

@media (hover: hover) and (max-width: 1024px) {
  #login-list .login-item-inner .icn-button:not(.icn-button-lrg):before {
    width: 2.75rem;
    height: 2.75rem;
  }
}

.align-left {
  text-align: left;
}

.primary-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  font-size: 1.125rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.primary-text.column {
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 0;
  flex: 1;
}

.table-view > .primary-text.column {
  text-overflow: initial;
  white-space: initial;
  flex-grow: 0;
  overflow: visible;
}

.table-view > .primary-text.column:first-child, .row:not(.onboarding-image) + .primary-text.column {
  padding-top: 1rem;
}

.primary-text.wrap-text {
  text-overflow: initial;
  white-space: initial;
  flex-grow: 0;
  overflow: visible;
}

.primary-text span:not(.secondary-text):not(.tag):not(.country-name):not(.dial-code) {
  display: block;
}

.primary-text span:not(.secondary-text):not(.tag):not(.country-name):not(.dial-code) .tag:not(.small) {
  top: 0;
}

.secondary-text {
  font-size: 1rem;
  line-height: 1rem;
}

.theme-light .secondary-text {
  color: #8e8e93;
}

.secondary-heading {
  letter-spacing: -.02em;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  margin: 0;
  padding-bottom: .25rem;
  font-family: Nunito, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
  overflow: visible;
}

.third-heading {
  border-bottom: none;
}

.third-heading .secondary-heading {
  text-transform: uppercase;
  letter-spacing: .025em;
  opacity: .65;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125rem;
}

.info-text {
  font-size: 1rem;
  line-height: 1.625rem;
}

.centered-text .info-text {
  text-align: center;
}

.align-left .info-text {
  text-align: left;
}

.info-text.column {
  padding-right: .5rem;
}

.theme-light .info-text {
  color: #8e8e93;
}

.help-text {
  font-size: .8125rem;
  line-height: 1rem;
}

.theme-light .help-text {
  color: #b4b4b8;
}

.help-text a {
  font-weight: 700;
  text-decoration: none;
}

.theme-light .help-text a {
  color: #8e8e93;
}

.message .help-text {
  color: #b4b4b8;
}

.text-group {
  min-width: 0;
  flex-direction: row;
  flex: 1;
  align-items: center;
  display: flex;
}

.text-group:first-child:last-child .primary-heading {
  text-align: center;
}

.text-group-inner {
  width: 100%;
  display: inline-grid;
}

.text-group-inner span {
  display: inline;
}

.text-group-inner .column-group {
  min-width: 0;
}

.text-group-inner .column-group .column + .column {
  padding-left: .5rem;
}

.member-name-wrapper {
  white-space: nowrap;
  overflow: hidden;
}

.text-button, .text-button-left {
  opacity: 1;
  transition: opacity .1s !important;
}

@media (hover: hover) {
  .text-button:hover {
    opacity: .5;
  }

  .row.cta .text-button:hover {
    opacity: 1;
  }
}

.secondary-text-content {
  font-size: .65rem;
}

.secondary-text-content:not(.nocolour) {
  color: var(--theme-colour);
}

.secondary-text-content:not(.no-transform) {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.text-group-inner {
  flex-direction: column;
  display: flex;
}

.text-group-inner .primary-text {
  max-width: 100%;
  overflow-wrap: break-word;
  flex-grow: 1;
}

.text-group-inner .primary-text .primary-text-content {
  white-space: normal;
}

.text-group.monospace {
  align-items: flex-start;
}

.text-group.monospace .primary-text {
  min-height: 2.1875rem;
}

.row:not(.password-visible) .text-group.monospace .primary-text {
  display: flex !important;
}

.text-group.monospace .primary-text-content {
  letter-spacing: .2em;
  font-family: Ubuntu Mono, monospace;
  font-size: .875rem;
  line-height: 1.25;
}

.row:not(.password-visible) .text-group.monospace .primary-text-content {
  height: 1.8125rem;
  line-height: 1.8125rem;
  display: block !important;
}

.password-visible .text-group.monospace .primary-text-content {
  letter-spacing: .1em;
  font-size: 1.375rem;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .password-visible .text-group.monospace .primary-text-content {
    font-size: 1em;
  }
}

span.monospace {
  letter-spacing: .2em;
  letter-spacing: .1em;
  color: #8e8e93;
  font-family: Ubuntu Mono, monospace;
  font-size: 1.375rem;
  line-height: 1.25;
  line-height: inherit;
  margin: 0 .3125rem;
}

.theme-dark span.monospace {
  color: #40404c;
}

.numbered-character-list {
  counter-reset: item;
  flex-wrap: wrap;
  margin: 0 -.25rem;
  padding: 0;
  list-style: none;
  display: flex !important;
}

.numbered-character-list > li, .numbered-character-list > span {
  counter-increment: item;
  height: 1.6875rem;
  min-width: 1.1875rem;
  max-width: 1.1875rem;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.125rem;
  position: relative;
  display: flex !important;
}

.password-visible .numbered-character-list > li, .password-visible .numbered-character-list > span {
  font-size: 1.625rem;
}

.numbered-character-list > li:nth-child(n+1), .numbered-character-list > span:nth-child(n+1) {
  border-left: .125rem solid #0000;
}

.numbered-character-list > li:nth-child(n+1):after, .numbered-character-list > span:nth-child(n+1):after {
  content: "";
  width: 1px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.0625rem;
}

.password-visible .numbered-character-list > li, .password-visible .numbered-character-list > span {
  animation: 1s forwards revealPassword;
}

.password-visible .numbered-character-list > li:after, .password-visible .numbered-character-list > span:after {
  background: linear-gradient(0deg, var(--border-colour) 0%, transparent 60%);
}

.numbered-character-list > li:before, .numbered-character-list > span:before {
  content: counter(item);
  color: #b4b4b8;
  letter-spacing: -.05em;
  opacity: 0;
  font-size: .875rem;
  display: flex;
}

.theme-dark .numbered-character-list > li:before, .theme-dark .numbered-character-list > span:before {
  color: #8e8e93;
}

.password-visible .numbered-character-list > li:before, .password-visible .numbered-character-list > span:before {
  animation: .3s .75s forwards fadeInSlideDown;
}

.numbered-character-list > li span, .numbered-character-list > span span {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: relative;
  left: .0625rem;
}

.secondary-text-label {
  margin-top: .625rem;
}

.onboarding.no-title .section-pane-header {
  display: none;
}

.onboarding .table-view {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.carousel.onboarding .table-view {
  padding-left: 0;
  padding-right: 0;
}

.onboarding .column {
  padding: 0;
}

.onboarding .primary-heading {
  max-width: 20rem;
  padding-top: 0;
}

.onboarding .align-left .primary-heading {
  align-self: flex-start;
}

.onboarding .column.info-text {
  padding: 0;
  line-height: normal;
}

.theme-dark .onboarding .column.info-text {
  color: #f8fafa;
}

.onboarding .onboarding-close {
  z-index: 200;
  align-items: center;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.onboarding .onboarding-close .f-icn-add {
  z-index: 200;
  transform: rotateZ(45deg);
}

.onboarding .onboarding-image {
  flex-grow: 1;
  flex-basis: 60%;
  align-items: center;
  display: flex;
}

.onboarding .onboarding-image img, .onboarding .onboarding-image svg {
  width: 100%;
  max-width: 75%;
  height: auto;
  max-height: 35vh;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
  display: block;
}

#onboarding_stage2.onboarding .onboarding-image img, #onboarding_stage2.onboarding .onboarding-image svg {
  max-width: 68%;
}

#onboarding_existingUser.onboarding .onboarding-image img, #onboarding_existingUser.onboarding .onboarding-image svg {
  max-width: 100%;
  max-height: 19.625rem;
}

@media screen and (max-height: 829px) {
  #onboarding_existingUser.onboarding .onboarding-image img, #onboarding_existingUser.onboarding .onboarding-image svg {
    max-width: 90%;
    max-height: 35vh;
  }
}

.onboarding .onboarding-image > .column {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.onboarding .banner-image {
  flex-basis: 60%;
  align-items: center;
  display: flex;
}

.onboarding .banner-image img, .onboarding .banner-image svg {
  width: 100%;
  height: auto;
  max-height: 35vh;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
  display: block;
}

#onboarding_stage2.onboarding .banner-image img, #onboarding_stage2.onboarding .banner-image svg {
  max-width: 68%;
}

#onboarding_existingUser.onboarding .banner-image img, #onboarding_existingUser.onboarding .banner-image svg {
  max-width: 100%;
  max-height: 19.625rem;
}

@media screen and (max-height: 829px) {
  #onboarding_existingUser.onboarding .banner-image img, #onboarding_existingUser.onboarding .banner-image svg {
    max-width: 90%;
    max-height: 35vh;
  }
}

.onboarding .banner-image > .column {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

#onboarding_stage1.onboarding .slick-slide:first-child .onboarding-image img {
  max-width: 56%;
}

.onboarding .onboarding-text {
  min-height: 9.375rem;
  flex-direction: column;
  flex-basis: 30%;
  display: flex;
}

#onboarding_stage2.onboarding .onboarding-text {
  min-height: 3.125rem;
}

.onboarding .onboarding-text + .has-cta-button {
  flex: 0 10%;
}

.onboarding .onboarding-text > .column {
  flex-flow: column;
  align-items: center;
}

.onboarding .onboarding-text > .column > .primary-heading {
  flex-grow: 0;
}

.onboarding .onboarding-text > .column > .column-group {
  flex-direction: column;
  flex-grow: 1;
}

.onboarding .section-pane-inner {
  height: 100%;
  position: relative;
}

.onboarding .section-pane-inner .table-view {
  padding-top: 1.25rem;
}

.onboarding .section-pane-inner .table-view > .slick-arrows {
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0 -3.125rem;
}

.onboarding .section-pane-inner .table-view > .slick-arrows .slick-arrow {
  flex-grow: 1;
  flex-basis: 50%;
  align-items: center;
  display: flex;
}

.onboarding .section-pane-inner .table-view > .slick-arrows .slick-arrow.slick-arrow-prev {
  justify-content: flex-start;
}

.onboarding .section-pane-inner .table-view > .slick-arrows .slick-arrow.slick-arrow-next {
  justify-content: flex-end;
}

.onboarding .section-pane-inner .table-view > .row > .column:not(.justify-content-start) {
  justify-content: center;
}

.onboarding .slick-slider, .onboarding .slick-slider .slick-list, .onboarding .slick-slider .slick-list .slick-track {
  flex-grow: 1;
  display: flex;
}

.onboarding .slick-slider .slick-list .slick-track .slick-slide {
  outline: none;
  flex-grow: 1;
  display: flex;
}

.onboarding .slick-slider .slick-list .slick-track .slick-slide > div {
  flex-grow: 1;
  display: flex;
}

.onboarding .slick-slider .slick-list .slick-track .slick-slide > div > div {
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 1.5625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  display: flex !important;
}

.onboarding .slick-slider .slick-list .slick-track .slick-slide > div > div .onboarding-text > .column {
  padding-top: .625rem;
}

.onboarding .has-cta-button:first-child:not(:last-child) {
  align-items: flex-end;
}

.onboarding .watch-again {
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.875rem;
  display: flex;
}

.onboarding label {
  background-color: #0000 !important;
}

[id^="onboarding-doodle"].section-pane .row > .column > .primary-heading {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

[id^="onboarding-doodle"].section-pane .row > .column .secondary-heading {
  text-align: center;
}

[id^="onboarding-doodle"].section-pane .column.text-button {
  text-align: center !important;
}

.onboard__list {
  opacity: .6;
  padding-left: 2rem;
}

.onboard__list.checked {
  opacity: 1;
}

.theme-colour-blue .onboard__list .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.670b231d.svg");
}

.theme-colour-green .onboard__list .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.f5595b8f.svg");
}

.theme-colour-red .onboard__list .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.e58f3496.svg");
}

.theme-colour-orange .onboard__list .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.477dcdfc.svg");
}

.theme-colour-yellow .onboard__list .f-icn-check-circle {
  background-image: url("forghetti-ui_check-circle_normal.6ddd8a9d.svg");
}

.onboarding .table-view .centered-text .column-group {
  padding-bottom: 0;
}

#create-account-onboarding-5 .primary-text-content {
  white-space: normal;
}

.overlay.theme-dark {
  background: #15151f;
}

.overlay > .section-pane-wrapper > .section-pane {
  align-items: center;
  display: flex;
}

@media screen and (min-height: 830px) and (min-width: 481px) {
  .overlay > .section-pane-wrapper > .section-pane {
    background: #15151f;
  }
}

.overlay > .section-pane-wrapper > .section-pane::-webkit-scrollbar-button {
  display: none;
}

@media screen and (min-height: 830px) and (min-width: 481px) {
  .overlay > .section-pane-wrapper > .section-pane .section-pane-inner {
    max-height: 43.75rem;
    min-height: 43.75rem;
    background: #202030;
    border-radius: 1rem;
  }
}

#verify-device-1 .row {
  border: none !important;
}

#onboarding_stage1 .section-pane-inner .slick-slide[data-slick-index="1"] .onboarding-image img {
  max-width: 65%;
}

#onboarding-doodletutorial1 .section-pane-header {
  display: none;
}

#onboarding-doodletutorial1 .table-view > .row:nth-of-type(2) > .column:first-child {
  flex-grow: 1;
}

#onboarding-doodletutorial1 .table-view > .row:nth-of-type(2) > .column:first-child .primary-heading {
  height: auto;
}

#onboarding-doodletutorial1 .table-view > .row:nth-of-type(2) > .column:first-child .column-group {
  display: none;
}

#onboarding_stage2 .section-pane-inner {
  height: 100%;
}

#onboarding_stage2 .table-view > .third-heading {
  flex-grow: 1;
  display: flex;
}

#onboarding_stage2 .table-view > .third-heading .column {
  flex-grow: 1;
  align-items: center;
}

#onboarding_stage2 .table-view > .slick-slider {
  flex-basis: 75%;
}

#onboarding_stage2 .onboarding-image img, #onboarding_stage2 .onboarding-image svg {
  max-width: 68%;
}

#onboarding_stage2 .onboarding-text {
  min-height: 3.125rem;
}

#onboarding_stage2 .onboarding-text .primary-heading {
  padding-top: 0;
  padding-bottom: .9375rem;
}

#onboarding_stage2 .watch-again {
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.875rem;
  display: flex;
}

@media screen and (max-height: 829px) {
  #addService_search-onboarding {
    align-items: flex-start;
  }
}

#addService_search-onboarding .section-pane-inner {
  overflow: auto;
}

#addService_search-onboarding .section-pane-inner::-webkit-scrollbar-button {
  display: none;
}

#addService_search-onboarding .section-pane-inner .section-pane-header {
  display: none;
}

#addService_search-onboarding .section-pane-inner > .table-view {
  position: relative;
}

#addService_search-onboarding .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group), #addService_search-onboarding .section-pane-inner > .table-view .search {
  padding-left: 0;
  padding-right: 0;
}

#addService_search-onboarding .section-pane-inner > .table-view .search {
  height: auto;
  padding-bottom: 1.25rem;
  position: relative;
  top: auto;
}

#addService_search-onboarding .section-pane-inner > .table-view .search .search-input {
  height: 3.75rem;
}

#addService_search-onboarding .section-pane-inner > .table-view .search .search-input:focus {
  color: #f8fafa;
  box-shadow: inset 0 0 0 1pt #40404c;
}

#addService_search-onboarding .section-pane-inner > .table-view .search .search-input-clear {
  width: 3.75rem;
  height: 3.75rem;
  top: .5625rem;
  right: 0;
}

#addService_search-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .column-group {
  padding-top: .625rem;
  padding-bottom: 1.25rem;
}

#addService_search-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .info-text {
  letter-spacing: -.02em;
  color: #f8fafa;
  font-family: Nunito, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
}

#addService_search-onboarding .section-pane-inner > .table-view .suggested-add-button {
  padding-left: 0;
  padding-right: 0;
}

#addService_search-onboarding .section-pane-inner > .table-view .suggested-add-button .column > .column-group > .icn-button {
  background: var(--theme-colour);
}

#addService_search-onboarding .section-pane-inner > .table-view .suggested-add-button .column > .column-group > .icn-button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.53f0147e.svg");
}

#addService_search-onboarding .section-pane-inner > .table-view .suggested-add-button:hover > .column {
  background: none !important;
}

#addService_search-onboarding .section-pane-inner > .table-view .suggested-add-button:hover > .column > .column-group .primary-text-content {
  text-decoration: underline;
}

#addService_search-onboarding .section-pane-inner > .table-view .suggested-add-button > .column > .column-group > .icn-button:first-child {
  margin: 0;
}

@media screen and (max-height: 829px) {
  #add-service-group-onboarding {
    align-items: flex-start;
  }
}

#add-service-group-onboarding .section-pane-inner .section-pane-header {
  display: none;
}

#add-service-group-onboarding .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group) {
  padding-left: 0;
  padding-right: 0;
}

#add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) {
  border: 0;
  padding-bottom: 2.5rem;
}

#add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(2) .column-group, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(3) .column-group, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(4) .column-group, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) .column-group {
  padding-top: .625rem;
  padding-bottom: 1.25rem;
}

#add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(2) .column-group .login-avatar-wrapper, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(3) .column-group .login-avatar-wrapper, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(4) .column-group .login-avatar-wrapper, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) .column-group .login-avatar-wrapper {
  min-width: 25%;
  max-width: 25%;
  flex-shrink: 0;
  flex-basis: 25%;
  margin: 0;
}

#add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(2) .column-group .login-avatar-wrapper .login-avatar-label, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(3) .column-group .login-avatar-wrapper .login-avatar-label, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(4) .column-group .login-avatar-wrapper .login-avatar-label, #add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) .column-group .login-avatar-wrapper .login-avatar-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  overflow: hidden;
}

#add-service-group-onboarding .section-pane-inner > .table-view > section:nth-of-type(n+2) {
  margin-left: -1rem;
  margin-right: -1rem;
}

@media screen and (max-height: 829px) {
  #addService_username-onboarding {
    align-items: flex-start;
  }
}

#addService_username-onboarding .section-pane-inner .section-pane-header {
  display: none;
}

#addService_username-onboarding .section-pane-inner > .table-view {
  overflow: hidden;
}

#addService_username-onboarding .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group) {
  padding-left: 0;
  padding-right: 0;
}

#addService_username-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) {
  padding-bottom: 2.5rem;
}

#addService_username-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .input {
  height: auto;
  padding-left: 0;
  padding-right: 0;
}

#addService_username-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .input.focussed .input-box {
  color: #f8fafa;
}

#addService_username-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .input .input-box {
  height: 3.75rem;
  text-indent: 1rem;
  background: #2f303b;
  border: 0;
}

#addService_username-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .input .input-box:focus {
  color: #f8fafa;
  box-shadow: inset 0 0 0 1pt #40404c;
}

#addService_username-onboarding [data-actionclick="addService_submitUsername"], #addService_username-onboarding [data-actionclick="addService_submitUsername"] .f-icn {
  margin: 0;
  position: relative;
  transform: none !important;
}

@media screen and (max-height: 829px) {
  #webSearchForServiceIcon.onboarding {
    align-items: flex-start;
  }
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view {
  justify-content: flex-start;
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group) {
  padding-left: 0;
  padding-right: 0;
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .column-group {
  padding-top: .625rem;
  padding-bottom: 1.25rem;
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .info-text {
  letter-spacing: -.03em;
  color: #f8fafa;
  font-family: Nunito, sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  #webSearchForServiceIcon.onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .info-text {
    font-size: 1.5rem;
  }
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view .search-result-row + .search-result-row .column-group {
  padding-top: 0;
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view .search-result-row .column-group {
  min-width: 0;
  max-width: 20rem;
  flex-grow: 1;
}

#webSearchForServiceIcon.onboarding .section-pane-inner > .table-view .search-result-row .column-group .login-avatar-wrapper {
  flex-grow: 1;
  margin: 0;
}

#doodle-pad-onboarding .section-pane-inner .section-pane-header, #doodle-pad-onboarding-confirm .section-pane-inner .section-pane-header {
  display: none;
}

#doodle-pad-onboarding .section-pane-inner > .table-view, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view {
  justify-content: center;
}

#doodle-pad-onboarding .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group), #doodle-pad-onboarding-confirm .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group) {
  padding-left: 0;
  padding-right: 0;
}

#doodle-pad-onboarding .section-pane-inner > .table-view > section:nth-of-type(1), #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:nth-of-type(1) {
  align-items: center;
  display: flex;
}

#doodle-pad-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .column-group, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:nth-of-type(1) .column-group {
  padding-top: .3125rem;
}

#doodle-pad-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) .info-text, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:nth-of-type(1) .info-text {
  letter-spacing: -.02em;
  color: #f8fafa;
  font-family: Nunito, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;
}

#doodle-pad-onboarding .section-pane-inner > .table-view > section:nth-of-type(3), #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:nth-of-type(3) {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

#doodle-pad-onboarding .section-pane-inner > .table-view > section:nth-of-type(5), #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:nth-of-type(5) {
  flex-grow: 1;
}

#doodle-pad-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) .column-group, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:nth-of-type(5) .column-group, #doodle-pad-onboarding .section-pane-inner > .table-view > section:last-of-type .button-group, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view > section:last-of-type .button-group {
  padding: 0;
}

#doodle-pad-onboarding .section-pane-inner > .table-view .avatar-group, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view .avatar-group {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

#doodle-pad-onboarding .section-pane-inner > .table-view .avatar-group .login-avatar, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view .avatar-group .login-avatar {
  border: 0;
}

#doodle-pad-onboarding .section-pane-inner > .table-view .avatar-group .login-avatar-label, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view .avatar-group .login-avatar-label {
  margin-top: .3125rem;
}

#doodle-pad-onboarding .section-pane-inner > .table-view .avatar-group .f-icn, #doodle-pad-onboarding-confirm .section-pane-inner > .table-view .avatar-group .f-icn {
  transform: none;
}

#view-service-onboarding {
  align-items: center;
  display: flex;
}

@media screen and (min-height: 830px) and (min-width: 481px) {
  #view-service-onboarding {
    background: #15151f;
  }
}

@media screen and (max-height: 829px) {
  #view-service-onboarding {
    align-items: flex-start;
  }
}

#view-service-onboarding::-webkit-scrollbar-button {
  display: none;
}

#view-service-onboarding .section-pane-inner .section-pane-header .section-pane-nav {
  flex-wrap: wrap;
}

#view-service-onboarding .section-pane-inner .section-pane-header .section-pane-nav .nav-link {
  min-width: 50%;
  box-sizing: border-box;
}

#view-service-onboarding .section-pane-inner .section-pane-header .section-pane-nav .nav-link.nav-left {
  order: 1;
}

#view-service-onboarding .section-pane-inner .section-pane-header .section-pane-nav .nav-link.nav-right {
  order: 2;
}

#view-service-onboarding .section-pane-inner .section-pane-header .section-pane-nav .section-pane-nav__title {
  order: 3;
}

#view-service-onboarding .section-pane-inner > .table-view {
  padding-top: 1.25rem;
}

#view-service-onboarding .section-pane-inner > .table-view .avatar-group {
  margin: 0 auto;
  padding: 0;
}

#view-service-onboarding .section-pane-inner > .table-view .avatar-group .login-avatar {
  border: 0;
}

#view-service-onboarding .section-pane-inner > .table-view .avatar-group .login-avatar-label {
  margin-top: .3125rem;
}

#view-service-onboarding .section-pane-inner > .table-view .info-text {
  line-height: normal;
}

#view-service-onboarding .section-pane-inner > .table-view > section:nth-of-type(1) {
  width: 100%;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

#view-service-onboarding .section-pane-inner > .table-view > section:nth-of-type(1).avatar-group-row {
  flex-grow: 0;
}

#view-service-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) {
  transform-origin: 50%;
  flex-grow: 1;
  align-items: center;
  animation: .5s .8s both transitionScaleUp;
  display: flex;
}

#view-service-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) > .column {
  box-sizing: border-box;
  min-width: 100%;
  flex-grow: 1;
}

#view-service-onboarding .section-pane-inner > .table-view > section:nth-of-type(5) > .column .info-text {
  font-size: 1.25rem;
}

@media screen and (max-height: 829px) {
  #onboard_complete {
    align-items: flex-start;
  }
}

#onboard_complete::-webkit-scrollbar-button {
  display: none;
}

#onboard_complete .section-pane-inner .section-pane-header {
  display: none;
}

#onboard_complete .section-pane-inner > .table-view .column:not(.icn-button):not(.text-group) {
  padding-left: 0;
  padding-right: 0;
}

#onboard_complete .section-pane-inner > .table-view .info-text {
  line-height: normal;
}

#onboard_complete .section-pane-inner > .table-view > section:nth-of-type(1) {
  border: 0;
}

#onboard_complete .section-pane-inner > .table-view > section:nth-of-type(1) > .column > .column-group {
  padding-bottom: 1.5rem;
}

#onboard_complete .section-pane-inner > .table-view > section:nth-of-type(1) .text-group .primary-heading {
  text-align: left;
  max-width: 100%;
  line-height: 1.2;
}

@media screen and (max-width: 370px) {
  #onboard_complete .section-pane-inner > .table-view > section:nth-of-type(1) .text-group .primary-heading {
    font-size: 1.5rem;
  }
}

#onboard_complete .section-pane-inner > .table-view .onboard__list {
  padding-left: .3125rem;
}

@media screen and (max-width: 370px) {
  #onboard_complete .section-pane-inner > .table-view .onboard__list {
    padding-left: 0;
  }
}

#onboard_complete .section-pane-inner > .table-view .onboard__list.checked .info-text {
  color: #f8fafa;
}

.theme-light #onboard_complete .section-pane-inner > .table-view .onboard__list.checked .info-text {
  color: #505064;
}

.theme-dark #onboard_complete .section-pane-inner > .table-view .onboard__list.checked .info-text {
  color: #f8fafa;
}

#onboard_complete .section-pane-inner > .table-view .onboard__list .column-group {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

#onboard_complete .section-pane-inner > .table-view .onboard__list .column-group .info-text {
  padding-left: .3125rem;
  font-size: 1.25rem;
}

@media screen and (max-width: 370px) {
  #onboard_complete .section-pane-inner > .table-view .onboard__list .column-group .info-text {
    font-size: 1.125rem;
  }
}

#onboard_complete .section-pane-inner > .table-view > .primary-text {
  transform-origin: 50%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  animation: .5s .8s both transitionScaleUp;
  display: flex;
}

#onboard_complete .section-pane-inner > .table-view > .primary-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#onboard_complete .section-pane-inner > .table-view > .primary-text ul li {
  list-style: none;
  display: block;
}

#onboard_complete .section-pane-inner > .table-view > section:last-of-type {
  flex-grow: 0;
  align-items: flex-end;
  display: flex;
}

#onboard_complete .section-pane-inner > .table-view > section:last-of-type > .column {
  box-sizing: border-box;
  min-width: 100%;
  flex-grow: 1;
}

.theme-dark.overlay {
  color: #f8fafa;
}

.theme-dark .search, .theme-dark #pane-1, .theme-dark #pane-2, .theme-dark #pane-3, .theme-dark .list-header, .theme-dark .login-list-wrapper .login-list .login-item-inner, .theme-dark .image-uploader__main {
  background-color: #202030;
}

.theme-dark .image-uploader__overlay {
  background-color: #15151faa;
}

.theme-dark .search-input {
  color: #f8fafa;
  background-color: #30303c;
  border: 1px solid #202030;
}

.theme-dark .search-input:focus {
  box-shadow: none;
}

.theme-dark .text-input {
  color: #f8fafa;
}

.theme-dark .focussed .text-input {
  color: #505064;
  color: #f8fafa;
  background-color: #2f303b;
}

.theme-dark #main-nav .main-nav--inner, body.theme-dark {
  background-color: #15151f;
}

.theme-dark #main-nav {
  color: #f8fafa;
}

.theme-dark #groups-nav li:before {
  background-color: #3e3e4d;
}

.theme-colour-yellow.theme-dark #groups-nav li.active .icn-button-name, .theme-colour-green.theme-dark #groups-nav li.active .icn-button-name {
  color: #505064;
}

.theme-dark #menu-icon {
  background-image: url("menu-light.ecc3cb39.png");
}

.theme-dark button, .theme-dark .nav-bar li .icn-button-name {
  color: #f8fafa;
}

.theme-dark .tabbed-picker .column.active, .theme-dark .tabbed-picker .column:hover {
  color: #202030;
}

.theme-dark .row, .theme-dark .section-pane-nav, .theme-dark .row-group:last-child .row:last-child, .theme-dark .login-list-wrapper .login-list .login-overview-wrapper, .theme-dark .login-list-wrapper .login-list .login-item-inner .icn-button {
  border-color: #30303c;
}

@media (hover: hover) {
  .theme-dark .row[data-actionclick]:not(.cta):hover > .column, .theme-dark .row[data-nav]:not(.cta):hover > .column, .theme-dark label:not(.search):hover, .theme-dark label:not(.search):hover.small-input:before {
    background-color: #2f303b;
  }
}

.theme-dark #groups-nav li:not(.active):before, .theme-dark .main-nav_middle > .row:before, .theme-dark .main-nav__bottom > .row:before {
  background-color: #202030;
}

@media (hover: hover) {
  .theme-dark #groups-nav li:not(.active):hover:before, .theme-dark .main-nav_middle > .row:hover:before, .theme-dark .main-nav__bottom > .row:hover:before, .theme-dark .login-list-wrapper .login-list .login-item:hover .login-item-inner {
    background-color: #2f303b;
  }
}

.theme-dark ::-webkit-scrollbar-track, .theme-dark ::-webkit-scrollbar-button, .theme-dark ::-webkit-scrollbar-corner, .theme-dark ::-webkit-scrollbar-resizer, .theme-dark ::-webkit-scrollbar-track-piece {
  background-color: #15151f;
}

.theme-dark ::-webkit-scrollbar-thumb {
  background-color: #2f303b;
}

.theme-dark .dialogMgr_toastBox {
  color: #f8fafa;
  background-color: #000;
}

.theme-dark .context-menu {
  color: #f8fafad9;
  background-color: #252537;
  box-shadow: 0 .5rem 1rem #15151fbf;
}

.theme-dark .context-menu .context-menu__item:not(:last-child):after {
  background: #30303c;
}

.theme-dark .context-menu .context-menu__item:hover {
  color: #f8fafa;
  background-color: #2f303b;
}

.theme-dark .context-menu .context-menu__item:hover:after {
  background: none;
}

.theme-dark #pane-1, .theme-dark #pane-2, .theme-dark #pane-3, .theme-dark .image-uploader__main, .theme-dark .list-header, .theme-dark .search {
  background-color: #202030;
}

.theme-dark .row, .theme-dark .login-list-wrapper .login-list .login-overview-wrapper, .theme-dark .row-group:last-child .row:last-child, .theme-dark .section-pane-nav {
  border-color: #30303c;
}

.theme-dark .nav-bar__button-group {
  background-color: #15151f;
}

.theme-dark .nav-bar__button-group ul {
  border: none;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-error .tooltipster-box {
  background: #e42020;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-error.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #e42020;
}

.column input.text-input.field-in-error {
  border-color: #e42020;
  box-shadow: inset 0 0 0 1pt #e42020;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info .tooltipster-box {
  background: #e0e4e4;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info .tooltipster-box .tooltipster-content {
  color: #000;
  white-space: pre-wrap;
  padding: .9375rem 1.875rem;
  font-size: .875rem;
  line-height: 1.1875rem;
  box-shadow: 0 2px 2px #0003;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #e0e4e4;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #e0e4e4;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #e0e4e4;
}

.text-button-left {
  text-align: left;
}

.theme-colour-blue .column.text-button-left {
  color: #5a96ff;
}

.theme-colour-green .column.text-button-left {
  color: #5af0be;
}

.theme-colour-red .column.text-button-left {
  color: #ff556e;
}

.theme-colour-orange .column.text-button-left {
  color: #ff7d5a;
}

.theme-colour-yellow .column.text-button-left {
  color: #ffeb46;
}

.column-group .text-button-left {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

.text-button.column.highlight {
  color: #505064;
  height: 50%;
  border-radius: 2rem;
  margin: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.theme-colour-blue .text-button.column.highlight {
  background-color: #5a96ff;
}

.theme-colour-green .text-button.column.highlight {
  background-color: #5af0be;
}

.theme-colour-red .text-button.column.highlight {
  background-color: #ff556e;
}

.theme-colour-orange .text-button.column.highlight {
  background-color: #ff7d5a;
}

.theme-colour-yellow .text-button.column.highlight {
  background-color: #ffeb46;
}

.theme-colour-blue .text-button.column.highlight {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .text-button.column.highlight {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .text-button.column.highlight {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .text-button.column.highlight {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .text-button.column.highlight {
  color: #505064;
  background-color: #ffeb46;
}

.theme-dark .text-button.column.highlight__disabled {
  color: #f8fafa;
  height: 40%;
  cursor: auto;
  opacity: .6;
  background-color: #40404c;
  border-radius: 2rem;
  margin: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.section-pane.full-colour {
  color: #f8fafa;
}

.section-pane.full-colour .section-pane-nav {
  border-bottom: .3125rem solid #ffffff80;
}

.section-pane.full-colour .nav-link.nav-left {
  color: #f8fafa;
}

.section-pane.full-colour {
  background-color: var(--theme-colour);
}

#profile-landing-page .login-avatar-wrapper, #edit-profile-name .login-avatar-wrapper {
  margin-right: .875rem;
}

.radio-button.active {
  opacity: 1;
}

.theme-dark .radio-button.active .border {
  border-color: #e0e4e4;
}

.theme-light .radio-button.active .border {
  border-color: #8e8e93;
}

.radio-button.blue .border, .radio-button.orange .border, .radio-button.red .border, .radio-button.green .border, .radio-button.yellow .border {
  background-color: #f8fafa;
  border-color: #e0e4e4;
}

.radio-button.dark .fill {
  background-color: #202030;
}

.radio-button.light .fill {
  background-color: #f8fafa;
}

.busy .lochy-busy {
  z-index: 200;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.busy .lochy-loading {
  width: 100%;
  background: url("09-Lochy-Weights.1af768d5.gif") bottom / 200px 200px no-repeat;
  flex-grow: 1.7;
}

.lochy-loading-text {
  display: none;
}

.busy .lochy-loading-text {
  flex-grow: 1;
  display: block;
}

.busy {
  cursor: wait;
}

.busy > * > * {
  opacity: 1;
  pointer-events: none;
}

.busy .section-pane {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  body:not(.pane-2-active):not(.pane-3-active) .main {
    box-sizing: border-box;
    padding-bottom: 3.75rem;
  }
}

.primary-text-content {
  -webkit-user-select: text;
  user-select: text;
}

.primary-text span:not(.secondary-text):not(.tag):not(.country-name):not(.dial-code) {
  display: inline;
}

.row[data-actionclick]:not(.cta):hover .column, [data-actionclick]:not(:disabled), [data-actionclick]:not(:disabled) * {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  #pane-2 {
    box-shadow: 0 0 20px #00000040;
    z-index: 4 !important;
    transform: translateX(100%)translateY(0) !important;
  }

  #pane-2.active {
    transform: translateX(0)translateY(0) !important;
  }

  #pane-3 {
    z-index: 5 !important;
  }
}

.loading-icon-row {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading-icon-row .column {
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading-icon {
  width: 128px;
  height: 128px;
  opacity: .3;
}

.theme-dark .loading-icon {
  background: url("loading-202030-128.fd7e18e7.gif");
}

.theme-light .loading-icon {
  background: url("loading-F8FAFA-128.0413759f.gif");
}

.full-colour .loading-icon {
  height: 160px;
  width: 160px;
  opacity: 1;
}

.theme-colour-red .full-colour .loading-icon {
  background: url("loading-white-red.a46c81c8.gif");
}

.theme-colour-blue .full-colour .loading-icon {
  background: url("loading-white-blue.57a18022.gif");
}

.theme-colour-green .full-colour .loading-icon {
  background: url("loading-white-green.01b76f93.gif");
}

.theme-colour-orange .full-colour .loading-icon {
  background: url("loading-white-orange.077d7cbb.gif");
}

.theme-colour-yellow .full-colour .loading-icon {
  background: url("loading-white-yellow.5cf9acb5.gif");
}

#pane-3 {
  z-index: 3;
  transition: transform .3s 50ms;
  transform: translateX(0)translateY(100%);
}

@media screen and (min-width: 769px) {
  #pane-3 {
    z-index: 5;
    width: 55%;
    left: auto;
  }
}

.no-items-row span {
  text-align: center;
  width: 100%;
  max-width: 31.25rem;
  opacity: .6;
  margin-top: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 .75rem;
  font-size: 1.25rem;
  display: block;
}

@media screen and (min-height: 641px) {
  .no-items-row span {
    margin-top: 5rem;
  }
}

.no-items-column span {
  display: block;
}

#hidden-file-input {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.centered-text {
  text-align: center;
}

.centered-text .icn-button {
  width: 100%;
}

.row.centered-text h2 {
  white-space: normal;
}

.row.centered-text .column-group {
  justify-content: center;
}

.wrapped-text, .wrapped-text .primary-text, .wrapped-text .secondary-text {
  white-space: normal;
}

.icn-button {
  margin-left: .3125rem;
}

.theme-light button.cta-button:not(:hover), .theme-dark button.cta-button:not(:hover), .theme-light button.cta-button.column.text-button:not(:hover), .theme-dark button.cta-button.column.text-button:not(:hover) {
  color: #f8fafa;
}

.theme-light.theme-colour-green button.cta-button:not(:hover), .theme-dark.theme-colour-green button.cta-button:not(:hover), .theme-light.theme-colour-yellow button.cta-button:not(:hover), .theme-dark.theme-colour-yellow button.cta-button:not(:hover), .theme-light.theme-colour-green button.cta-button.column.text-button:not(:hover), .theme-dark.theme-colour-green button.cta-button.column.text-button:not(:hover), .theme-light.theme-colour-yellow button.cta-button.column.text-button:not(:hover), .theme-dark.theme-colour-yellow button.cta-button.column.text-button:not(:hover), .theme-light.theme-colour-green .cta:hover .column.text-button, .theme-dark.theme-colour-green .cta:hover .column.text-button, .theme-light.theme-colour-yellow .cta:hover .column.text-button, .theme-dark.theme-colour-yellow .cta:hover .column.text-button {
  color: #505064;
}

.theme-light.theme-colour-green .red.cta:hover .column.text-button, .theme-dark.theme-colour-green .red.cta:hover .column.text-button, .theme-light.theme-colour-yellow .red.cta:hover .column.text-button, .theme-dark.theme-colour-yellow .red.cta:hover .column.text-button, .cta:hover, .cta:hover .column.text-button {
  color: #f8fafa;
}

.cta-button:hover {
  opacity: 1;
}

.red .column.text-button {
  color: #ff3b30;
}

.red .column.text-button:hover .column.text-button {
  color: #f8fafa;
  opacity: 1;
}

.f-icn-star {
  width: 5rem;
  height: 5rem;
  background-image: url("star.63eb5903.png");
  background-size: 5rem 5rem;
}

[data-context] {
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.row.collapsed {
  display: none;
}

.theme-colour-red .group-avatar .login-avatar {
  background-color: #ff556e;
}

.theme-colour-green .group-avatar .login-avatar {
  background-color: #5af0be;
}

.theme-colour-blue .group-avatar .login-avatar {
  background-color: #5a96ff;
}

.theme-colour-yellow .group-avatar .login-avatar {
  background-color: #ffeb46;
}

.theme-colour-orange .group-avatar .login-avatar {
  background-color: #ff7d5a;
}

.group-avatar img[data-actionclick] {
  cursor: pointer;
}

.section-pane-inner {
  height: auto;
  min-height: 100%;
}

.theme-colour-blue .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.46b96c0d.svg");
}

.theme-colour-green .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.94cef1b2.svg");
}

.theme-colour-red .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.409078fe.svg");
}

.theme-colour-orange .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.66e9f37c.svg");
}

.theme-colour-yellow .f-icn-right-solid {
  background-image: url("forghetti-ui_right-solid_normal.4c032976.svg");
}

.progress-dots .column-group {
  justify-content: center;
}

.progress-dots .progress-dots__dot {
  width: .625rem;
  height: .625rem;
  opacity: .3;
  border-radius: 50%;
  margin: .3125rem;
}

.progress-dots .progress-dots__dot.active {
  opacity: 1;
  width: .75rem;
  height: .75rem;
  margin: .25rem;
}

.theme-colour-red .progress-dots .progress-dots__dot {
  background-color: #ff556e;
}

.theme-colour-blue .progress-dots .progress-dots__dot {
  background-color: #5a96ff;
}

.theme-colour-yellow .progress-dots .progress-dots__dot {
  background-color: #ffeb46;
}

.theme-colour-orange .progress-dots .progress-dots__dot {
  background-color: #ff7d5a;
}

.theme-colour-green .progress-dots .progress-dots__dot {
  background-color: #5af0be;
}

@media screen and (max-width: 768px) {
  .progress-dots {
    display: none;
  }
}

button[data-actionclick="startViewPasswordTimer"] {
  width: 50px;
  color: inherit;
  font-size: 1.1em;
}

.row.disabled > * {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}

.theme-dark .row.disabled:hover, .theme-light .row.disabled:hover {
  cursor: default;
  background-color: #0000;
}

.icn-big {
  width: 100%;
}

.icn-big .f-icn {
  width: 100%;
  height: 4.1875rem;
  background-size: contain;
}

.info-list-row {
  border: none;
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 1.1875rem;
  line-height: 1.5rem;
}

.overlay .section-pane-wrapper {
  max-height: 100%;
  max-width: 100%;
}

.overlay .section-pane-wrapper .section-pane {
  padding-left: calc(50vw - 13.75rem);
  padding-right: calc(50vw - 13.75rem);
}

body {
  position: fixed;
}

.theme-light li.active .f-icn-forghetti-icon, .theme-dark li.active .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.6088a6e1.svg");
}

.appstore-link, .playstore-link {
  text-align: center;
  flex: 1;
}

.appstore-link img, .playstore-link img {
  width: 200px;
  max-width: 90%;
}

.help-link {
  display: none;
}

.userprefs-tooltips .help-link {
  display: block;
}

.help-link:not([data-id]) {
  display: none;
}

@media screen and (min-width: 769px) {
  .section-pane-nav .nav-link {
    flex-basis: 50%;
  }
}

.section-pane-nav__title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  order: 3;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.section-pane-nav__title h1 {
  order: initial;
  flex-grow: 0;
  flex-basis: auto;
}

@media screen and (min-width: 769px) {
  .section-pane-nav__title h1 {
    transform: translate(0, -4px);
  }
}

.section-pane-nav__title .help-link {
  width: 0;
  position: relative;
  top: 0;
  left: .9375rem;
  transform: translate(0, 2px);
}

@media (max-width: 412px) {
  .section-pane-nav__title .help-link {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .section-pane-nav .help-link {
    order: 2;
  }
}

.f-icn-help-solid {
  width: 13px;
  height: 13px;
  background-size: contain;
}

.theme-colour-blue .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.e5143767.svg");
}

.theme-colour-green .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.72e19fbb.svg");
}

.theme-colour-red .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.541b5577.svg");
}

.theme-colour-orange .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.6d60216a.svg");
}

.theme-colour-yellow .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.70e03fd2.svg");
}

.theme-light .cta:hover .f-icn-help-solid, .theme-dark .cta:hover .f-icn-help-solid {
  background-image: url("forghetti-ui_help-solid_normal.b189dcf7.svg");
}

.tabbed-picker-column {
  flex-grow: 1;
}

.row > .column {
  flex-flow: wrap;
  display: flex;
}

.row > .column h1, .row > .column h2 {
  flex-grow: 1;
}

.row > .column .column-group {
  min-width: 100%;
}

.row > .column .help-link {
  top: .5625rem;
}

.row > .column > .column-group:empty {
  display: none;
}

.onboarding-image > .column {
  display: block;
}

.onboarding-image > .column > img {
  width: 100%;
  height: auto;
  max-height: 35vh;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
}

.onboarding-image > .column > img.small {
  max-height: 25vh;
}

.onboarding-image {
  flex-grow: 1;
  flex-basis: 68%;
  align-items: center;
  display: flex;
}

.onboarding-image.lochy-loading {
  flex-basis: auto;
}

.onboarding-image img, .onboarding-image svg {
  width: 100%;
  max-width: 75%;
  height: auto;
  max-height: 35vh;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
  display: block;
}

.onboarding-image img.small, .onboarding-image svg.small {
  max-height: 25vh;
}

.onboarding-image > .column {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.row > .column > .primary-heading {
  order: 1;
}

.row > .column > .secondary-heading {
  order: 2;
}

.row > .column > .help-link {
  order: 3;
}

.row > .column > .column-group {
  order: 4;
}

.row.greyed-out {
  opacity: .5;
}

#profile-landing-page .banner.loading-safe img {
  display: none;
}

.theme-light #profile-landing-page .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.a180f001.svg");
}

.theme-dark #profile-landing-page .f-icn-forghetti-icon {
  background-image: url("forghetti-ui_forghetti-icon_normal.1872adfc.svg");
}

.login-avatar-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.login-avatar-wrapper .login-avatar-label {
  text-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  word-break: break-word;
  margin-top: 3px;
  font-size: .9em;
  overflow: hidden;
}

#main-nav .login-avatar-wrapper .login-avatar-label {
  white-space: nowrap;
}

.big-avatar .login-avatar-inner, .big-avatar .login-avatar {
  width: 5.75rem;
  height: 5.75rem;
}

@media (min-width: 1025px) {
  .big-avatar .login-avatar-inner, .big-avatar .login-avatar {
    width: 7.5rem;
    height: 7.5rem;
  }
}

#create-account-add-login .login-avatar-wrapper {
  width: 17%;
  cursor: pointer;
}

.theme-colour-blue #create-account-add-login .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.e01dfbc1.svg");
}

.theme-colour-green #create-account-add-login .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.1a375a2d.svg");
}

.theme-colour-red #create-account-add-login .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.09edc1f5.svg");
}

.theme-colour-orange #create-account-add-login .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.11818f12.svg");
}

.theme-colour-yellow #create-account-add-login .f-icn-add-solid {
  background-image: url("forghetti-ui_add-solid_normal.21c32c12.svg");
}

.search-result-row > .column > .column-group {
  padding: 0 !important;
}

.search-result-row .login-avatar, .search-result-row .login-avatar-inner {
  height: 4rem;
  width: 4rem;
}

.search-result-row .login-avatar-wrapper {
  padding: 1rem;
}

.icon-row > .column > .column-group {
  justify-content: center;
  display: flex;
}

[data-slick] {
  margin: auto 0;
}

[data-actionclick="addService_submitUsername"] {
  transition: transform .5s;
  position: absolute;
  transform: translateX(110vw);
}

[data-actionclick="addService_submitUsername"] .f-icn {
  transform: scale(.5);
}

[data-actionclick="addService_submitUsername"].active {
  transition: transform .5s;
  position: relative;
  transform: translateX(0);
}

[data-actionclick="addService_submitUsername"].active .f-icn {
  transform: scale(1);
}

.dialogMgr_footer {
  display: none;
}

.dialogMgr_frame {
  text-align: center;
  max-width: 92%;
  background-color: #0000;
  border-radius: 10px;
}

.theme-dark .dialogMgr_frame {
  background-color: #15151f;
}

.theme-light .dialogMgr_frame {
  background-color: #f8fafa;
}

.dialogMgr_frame .primary-heading {
  font-size: 2rem;
}

@media screen and (max-width: 412px) {
  .dialogMgr_frame .primary-text {
    font-size: 1rem;
    line-height: 1.35;
  }
}

.dialogMgr_frame .cta-button {
  width: 100%;
  max-width: 23.75rem;
}

.dialogMgr_content {
  max-height: calc(100vh - 8vw);
}

.theme-dark .dialogMgr_toastBox {
  background: #15151f;
}

.theme-light .dialogMgr_toastBox {
  background: #2f303b;
}

.dialogMgr_toastBox, .dialogMgr_contentPadding {
  padding: 1.5rem 1.5rem 1.75rem;
}

@media screen and (max-width: 412px) {
  .dialogMgr_toastBox, .dialogMgr_contentPadding {
    padding: 1.125rem 1rem 1.25rem;
  }
}

.dialogMgr_toastRoot.verticalAlign {
  box-sizing: border-box;
  z-index: 990;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  background-color: #40404080;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 1rem;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.dialogMgr_toastRoot.verticalAlign .dialogMgr_toastBox > div {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.dialogMgr_toastRoot.verticalAlign .toast--title {
  margin-bottom: .3125rem;
  font-weight: 700;
  display: block;
}

.dialogMgr_toastRoot.verticalAlign .toast--clipboard-item {
  background: #40404c;
  border-radius: 5px;
  padding: .2em .5em;
  font-size: 1.25rem;
  display: block;
}

.dialogMgr_toastRoot.verticalAlign .toast--clipboard-item + .toast--link {
  margin-top: .9375rem;
}

.dialogMgr_toastRoot.verticalAlign .toast--text {
  color: #f8fafa;
  display: block;
}

.dialogMgr_toastRoot.verticalAlign .toast--text + .toast--text {
  margin-top: .3125rem;
}

.dialogMgr_toastRoot.verticalAlign .toast--link {
  display: block;
}

.dialogMgr_toastRoot.verticalAlign .toast--link:hover {
  opacity: 1;
}

.recents.row > .column .column-group {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.recents.row > .column .column-group .column {
  max-width: 16.6667%;
  flex-grow: 1;
  margin: .5rem 0;
  padding: 0;
  font-size: .9rem;
}

@media screen and (max-width: 460px), screen and (min-width: 921px) and (max-width: 1193px), screen and (min-width: 1194px) and (max-width: 1580px) {
  .recents.row > .column .column-group .column:nth-child(n) {
    max-width: 20%;
  }
}

@media screen and (max-width: 350px), screen and (min-width: 769px) and (max-width: 920px) {
  .recents.row > .column .column-group .column:nth-child(n) {
    max-width: 25%;
  }
}

@media screen and (max-width: 290px) {
  .recents.row > .column .column-group .column:nth-child(n) {
    max-width: 33.3333%;
  }

  .recents.row > .column .column-group .column:nth-child(n+4) {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .recents.row > .column .column-group .column:nth-child(n+5) {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 920px) {
  .recents.row > .column .column-group .column:nth-child(n+5) {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .recents.row > .column .column-group .column:nth-child(n+6) {
    display: none;
  }
}

@media screen and (min-width: 921px) and (max-width: 1193px) {
  .recents.row > .column .column-group .column:nth-child(n+6) {
    display: none;
  }
}

@media screen and (min-width: 1194px) and (max-width: 1580px) {
  .recents.row > .column .column-group .column:nth-child(n+6) {
    display: none;
  }
}

.recents.row > .column .column-group .login-avatar-wrapper {
  cursor: pointer;
}

.recents.row > .column .column-group .login-avatar-label {
  max-width: 90%;
}

.banner.image-wrapper {
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  transition: transform .35s;
  transform: scale(1);
}

.banner.image-wrapper:nth-of-type(1) {
  align-items: flex-end;
  display: flex;
}

.banner.image-wrapper:nth-of-type(2) {
  align-items: flex-start;
  display: flex;
}

.banner.image-wrapper:hover:not([banner-context="menu"]):not([banner-context="profile"]):not([banner-context="landingPage"]) {
  transform: scale(1.03);
}

.banner.image-wrapper + .banner.image-wrapper {
  margin-top: 1rem;
}

.banner.image-wrapper .image-wrapper--inner {
  width: 100%;
  height: 0;
  border-radius: .5em;
  padding-bottom: 42%;
  position: relative;
  overflow: hidden;
}

[banner-context="landingPage"].banner.image-wrapper .image-wrapper--inner {
  transition: transform .35s;
  transform: scale(1);
}

[banner-context="landingPage"].banner.image-wrapper .image-wrapper--inner:hover {
  transform: scale(1.03);
}

.banner.image-wrapper .image-wrapper--inner[data-id="your_journey"] {
  height: auto;
  padding-bottom: initial;
}

.banner.image-wrapper .image-wrapper--inner img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#landing-page.loading-landing-page .banner, #landing-page .section-pane-header {
  display: none;
}

#landing-page .table-view, #landing-page .banner-wrapper {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

#landing-page .banner-wrapper {
  padding: 0 .5rem;
}

#landing-page .table-view .column:first-child:last-child {
  flex-grow: 0;
}

.spaghetti-bg {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.green-bg {
  background-color: #5af0be;
}

.green-bg.spaghetti-bg {
  background-image: url("spaghetti-green.e76f6fd0.svg");
}

.blue-bg {
  background-color: #5a96ff;
}

.blue-bg.spaghetti-bg {
  background-image: url("spaghetti-blue.d1c0c6b8.svg");
}

.pink-bg {
  background-color: #ff556e;
}

.pink-bg.spaghetti-bg {
  background-image: url("spaghetti-pink.726de406.svg");
}

.orange-bg {
  background-color: #ff7d5a;
}

.orange-bg.spaghetti-bg {
  background-image: url("spaghetti-orange.e339d9c1.svg");
}

.yellow-bg {
  background-color: #ffeb46;
}

.yellow-bg.spaghetti-bg {
  background-image: url("spaghetti-yellow.77d39b31.svg");
}

.grey-bg {
  background-color: #505064;
}

.grey-bg.spaghetti-bg {
  background-image: url("spaghetti-grey.94227f11.svg");
}

.banner {
  color: #f8fafa;
}

@media (min-width: 813px) {
  .banner .content-block-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 412px) {
  .banner .content-block-wrapper {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.banner .content-block {
  text-align: center;
  border-radius: .5em;
  padding: 1.5rem 1.25rem 1.85rem;
  animation-name: fadeInSlideUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  overflow: hidden;
}

.avatar-badge-premium:after {
  content: "";
  height: 2rem;
  width: 2rem;
  background-image: url("forghetti-ui_annual_shield_normal.be217c0a.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  position: absolute;
  bottom: -10px;
  right: -5px;
}

.login-avatar-wrapper.disabled {
  opacity: .7;
}

.label {
  font-size: .6rem;
}

#login-list .login-list-page__header, #login-list .search__wrapper, #login-list .alpha-list {
  transform-origin: 50%;
  animation: .5s both fadeInSlideUp;
}

#login-list .login-list-wrapper {
  opacity: 1;
  animation: none;
}

#login-list > :nth-child(2) {
  animation-delay: .105s;
}

#login-list > :nth-child(1) {
  animation-delay: 70ms;
}

#login-list .alpha-list:nth-child(27) {
  animation-delay: 1.05s;
}

#login-list .alpha-list:nth-child(26) {
  animation-delay: 1.015s;
}

#login-list .alpha-list:nth-child(25) {
  animation-delay: .98s;
}

#login-list .alpha-list:nth-child(24) {
  animation-delay: .945s;
}

#login-list .alpha-list:nth-child(23) {
  animation-delay: .91s;
}

#login-list .alpha-list:nth-child(22) {
  animation-delay: .875s;
}

#login-list .alpha-list:nth-child(21) {
  animation-delay: .84s;
}

#login-list .alpha-list:nth-child(20) {
  animation-delay: .805s;
}

#login-list .alpha-list:nth-child(19) {
  animation-delay: .77s;
}

#login-list .alpha-list:nth-child(18) {
  animation-delay: .735s;
}

#login-list .alpha-list:nth-child(17) {
  animation-delay: .7s;
}

#login-list .alpha-list:nth-child(16) {
  animation-delay: .665s;
}

#login-list .alpha-list:nth-child(15) {
  animation-delay: .63s;
}

#login-list .alpha-list:nth-child(14) {
  animation-delay: .595s;
}

#login-list .alpha-list:nth-child(13) {
  animation-delay: .56s;
}

#login-list .alpha-list:nth-child(12) {
  animation-delay: .525s;
}

#login-list .alpha-list:nth-child(11) {
  animation-delay: .49s;
}

#login-list .alpha-list:nth-child(10) {
  animation-delay: .455s;
}

#login-list .alpha-list:nth-child(9) {
  animation-delay: .42s;
}

#login-list .alpha-list:nth-child(8) {
  animation-delay: .385s;
}

#login-list .alpha-list:nth-child(7) {
  animation-delay: .35s;
}

#login-list .alpha-list:nth-child(6) {
  animation-delay: .315s;
}

#login-list .alpha-list:nth-child(5) {
  animation-delay: .28s;
}

#login-list .alpha-list:nth-child(4) {
  animation-delay: .245s;
}

#login-list .alpha-list:nth-child(3) {
  animation-delay: .21s;
}

#login-list .alpha-list:nth-child(2) {
  animation-delay: .175s;
}

#login-list .alpha-list:nth-child(1) {
  animation-delay: .14s;
}

#groups-list .section-pane-header, #profile-landing-page .section-pane-header, #healthcheck_welcome .section-pane-header, #groups-list .table-view > *, #profile-landing-page .table-view > *, #healthcheck_welcome .table-view > * {
  transform-origin: 50%;
  animation: .5s both fadeInSlideUp;
}

#groups-list .table-view > :nth-child(15), #profile-landing-page .table-view > :nth-child(15), #healthcheck_welcome .table-view > :nth-child(15) {
  animation-delay: .56s;
}

#groups-list .table-view > :nth-child(14), #profile-landing-page .table-view > :nth-child(14), #healthcheck_welcome .table-view > :nth-child(14) {
  animation-delay: .525s;
}

#groups-list .table-view > :nth-child(13), #profile-landing-page .table-view > :nth-child(13), #healthcheck_welcome .table-view > :nth-child(13) {
  animation-delay: .49s;
}

#groups-list .table-view > :nth-child(12), #profile-landing-page .table-view > :nth-child(12), #healthcheck_welcome .table-view > :nth-child(12) {
  animation-delay: .455s;
}

#groups-list .table-view > :nth-child(11), #profile-landing-page .table-view > :nth-child(11), #healthcheck_welcome .table-view > :nth-child(11) {
  animation-delay: .42s;
}

#groups-list .table-view > :nth-child(10), #profile-landing-page .table-view > :nth-child(10), #healthcheck_welcome .table-view > :nth-child(10) {
  animation-delay: .385s;
}

#groups-list .table-view > :nth-child(9), #profile-landing-page .table-view > :nth-child(9), #healthcheck_welcome .table-view > :nth-child(9) {
  animation-delay: .35s;
}

#groups-list .table-view > :nth-child(8), #profile-landing-page .table-view > :nth-child(8), #healthcheck_welcome .table-view > :nth-child(8) {
  animation-delay: .315s;
}

#groups-list .table-view > :nth-child(7), #profile-landing-page .table-view > :nth-child(7), #healthcheck_welcome .table-view > :nth-child(7) {
  animation-delay: .28s;
}

#groups-list .table-view > :nth-child(6), #profile-landing-page .table-view > :nth-child(6), #healthcheck_welcome .table-view > :nth-child(6) {
  animation-delay: .245s;
}

#groups-list .table-view > :nth-child(5), #profile-landing-page .table-view > :nth-child(5), #healthcheck_welcome .table-view > :nth-child(5) {
  animation-delay: .21s;
}

#groups-list .table-view > :nth-child(4), #profile-landing-page .table-view > :nth-child(4), #healthcheck_welcome .table-view > :nth-child(4) {
  animation-delay: .175s;
}

#groups-list .table-view > :nth-child(3), #profile-landing-page .table-view > :nth-child(3), #healthcheck_welcome .table-view > :nth-child(3) {
  animation-delay: .14s;
}

#groups-list .table-view > :nth-child(2), #profile-landing-page .table-view > :nth-child(2), #healthcheck_welcome .table-view > :nth-child(2) {
  animation-delay: .105s;
}

#groups-list .table-view > :nth-child(1), #profile-landing-page .table-view > :nth-child(1), #healthcheck_welcome .table-view > :nth-child(1) {
  animation-delay: 70ms;
}

#groups-list .table-view {
  padding-bottom: 8.375rem;
}

@media screen and (max-width: 768px) {
  #groups-list .table-view {
    padding-bottom: .625rem;
  }
}

#groups-list .groups-shared-by-heading .primary-text-content {
  letter-spacing: -.03em;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: .875rem;
  font-size: .875rem !important;
}

@media screen and (max-width: 768px) {
  #groups-list .groups-shared-by-heading .primary-text-content {
    font-size: 1.5rem;
  }
}

#groups-list .groups-shared-by-heading .secondary-text-content {
  letter-spacing: -.03em;
  text-transform: none;
  color: #505064;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.82813rem;
  font-size: 1.375rem !important;
}

@media screen and (max-width: 768px) {
  #groups-list .groups-shared-by-heading .secondary-text-content {
    font-size: 1.5rem;
  }
}

.theme-dark #groups-list .groups-shared-by-heading .secondary-text-content {
  color: #f8fafa;
}

.loading-wheel, .loading-nav-badges {
  min-height: 4.375rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 38px 38px;
}

.theme-light .loading-wheel, .theme-light .loading-nav-badges {
  background-image: url("loading-dark.0c1d9918.svg");
}

.theme-dark .loading-wheel, .theme-dark .loading-nav-badges {
  background-image: url("loading.24170abb.svg");
}

.loading-safe {
  min-height: 13.25rem;
  background-image: url("11.7d6ff3c7.gif");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 180px 180px;
}

.image-uploader__main.busy .tick i.f-icn.f-icn-check-solid {
  min-height: 4.375rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 38px 38px;
}

.theme-light .image-uploader__main.busy .tick i.f-icn.f-icn-check-solid {
  background-image: url("loading-dark.0c1d9918.svg");
}

.theme-dark .image-uploader__main.busy .tick i.f-icn.f-icn-check-solid {
  background-image: url("loading.24170abb.svg");
}

.centered {
  justify-content: center;
}

.image-uploader {
  display: none;
}

.image-uploader__header {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.image-uploader__main {
  width: 400px;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
  box-shadow: 0 0 40px #0006;
}

.image-uploader__overlay {
  z-index: 999;
  background-color: #ffffffb3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.image-uploader__container, .image-uploader__container.croppie-container {
  width: 300px;
  height: 340px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.image-uploader__image {
  width: 300px;
  height: 300px;
}

#download-app-blocker {
  z-index: 2000;
  background-color: #5af0be;
  background-image: url("splash-screen-background.287a685f.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

#download-app-blocker #download-app-inner {
  width: 100%;
  max-width: 26.25rem;
}

#download-app-blocker .forghetti-logo {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 3.125rem;
  display: block;
}

@media (max-height: 420px) and (orientation: landscape) {
  #download-app-blocker .forghetti-logo {
    max-width: 23.75rem;
  }
}

#download-app-blocker .forghetti-logo path {
  fill: #505064;
}

.column-group .country-select, .column-group .country-select.inside .selected-flag {
  width: 100%;
}

.column-group .country-select.inside .selected-flag:before {
  content: "|";
  color: #8e8e93;
  position: absolute;
  top: 10px;
  left: 37px;
}

.theme-dark .column-group .country-select.inside .selected-flag:before {
  color: #8e8e93;
}

.column-group .country-select .selected-flag .arrow {
  border-width: 6px;
  right: 1.5rem;
}

.theme-dark .column-group .country-select .selected-flag .arrow {
  border-top-color: #8e8e93;
}

.theme-dark .column-group .country-select .selected-flag .arrow.up {
  border-width: 6px;
  border-bottom-color: #8e8e93;
}

.column-group .country-select .flag-dropdown, .column-group .country-select .country-list {
  width: 100%;
}

.column-group .country-select .country-list .country {
  min-height: 44px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.iti-mobile .column-group .country-select .country-list .country {
  padding: 10px;
  line-height: 1.5em;
}

.country-input {
  text-indent: 0;
  width: 100%;
  height: 1.5rem;
  box-sizing: border-box;
  height: 2.875rem;
  border: 0;
  border-radius: .25rem;
  margin: 0;
  font-size: 1.1875rem;
  line-height: 2.875rem;
  display: block;
}

.theme-dark .country-input {
  color: #f8fafa;
  background-color: #505064;
  box-shadow: inset 0 0 0 1pt #30303c;
}

.theme-light .country-input {
  color: #505064;
  background-color: #fff;
  box-shadow: inset 0 0 0 1pt #e0e4e4;
}

.theme-dark .country-select .country-list {
  color: #f8fafa;
  background-color: #40404c;
  border: #202030;
}

.theme-light .country-select .country-list {
  color: #505064;
  background-color: #fff;
}

#view-user-preferences .country-select {
  margin-top: .7rem;
}

.country-select .flag.zh, .country-select .flag.zh_hant {
  height: 14px;
  background-position: -1049px 0;
}

.suggested-add-button {
  border-bottom: none;
  display: none;
}

.suggested-add-button .icn-button {
  height: 4rem;
  width: 4rem;
  border: solid 2px var(--theme-colour);
  background-color: var(--theme-colour);
  border-radius: 999px;
}

.suggested-add-button .column.text-group {
  padding-left: 1rem;
}

.theme-light .suggested-add-button .f-icn-add, .theme-dark .suggested-add-button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.08a57831.svg");
}

.theme-colour-green.theme-dark .suggested-add-button .f-icn-add, .theme-colour-yellow.theme-dark .suggested-add-button .f-icn-add {
  background-image: url("forghetti-ui_add_normal.53f0147e.svg");
}

@media (hover: hover) {
  .suggested-add-button .icn-button:before {
    display: none !important;
  }
}

.suggested-presets.row {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.suggested-presets.row > .column .column-group {
  flex-wrap: wrap;
  padding-top: .6875rem;
  padding-bottom: 0;
  display: flex;
}

.suggested-presets.row > .column .column-group .column {
  max-width: 25%;
  min-width: 25%;
  min-height: 6.25rem;
  flex-basis: 25%;
  margin: .9375rem 0 0;
  padding: 0;
  font-size: .9rem;
}

@media screen and (max-height: 829px) {
  .suggested-presets.row > .column .column-group .column {
    margin-top: .625rem;
  }
}

@media screen and (max-width: 370px) {
  .suggested-presets.row > .column .column-group .column {
    max-width: 33.3333%;
    min-width: 33.3333%;
    flex-basis: 33.3333%;
  }
}

.suggested-presets.row .login-avatar-wrapper {
  cursor: pointer;
}

.suggested-presets.row .login-avatar-wrapper:hover .login-avatar-inner:after, .suggested-presets.row .login-avatar-wrapper[data-selected="true"] .login-avatar-inner:after {
  opacity: 1;
}

.suggested-presets.row .login-avatar-wrapper.exists {
  opacity: .7;
}

.suggested-presets.row .login-avatar-wrapper .login-avatar-inner:after {
  content: "";
  opacity: 0;
  border-style: solid;
  border-width: .1875rem;
  border-radius: 999px;
  transition: opacity .15s;
  display: block;
  position: absolute;
  inset: 0;
}

.theme-colour-blue .suggested-presets.row .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #5a96ff;
}

.theme-colour-green .suggested-presets.row .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #5af0be;
}

.theme-colour-red .suggested-presets.row .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #ff556e;
}

.theme-colour-orange .suggested-presets.row .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #ff7d5a;
}

.theme-colour-yellow .suggested-presets.row .login-avatar-wrapper .login-avatar-inner:after {
  border-color: #ffeb46;
}

.suggested-presets.row .login-avatar-wrapper .login-avatar-inner, .suggested-presets.row .login-avatar-wrapper .login-avatar {
  width: 4rem;
  height: 4rem;
}

.suggested-presets.row .login-avatar-wrapper .login-avatar-label {
  max-width: 90%;
}

.row .column .column-group.avatar-group {
  min-width: auto;
  justify-content: space-around;
  margin: 1rem auto .6rem;
}

.row .column .column-group.avatar-group .login-avatar {
  border: 3px solid #e0e4e4;
}

.theme-dark .row .column .column-group.avatar-group .login-avatar {
  border: 3px solid #2a2a3d;
}

.row .column .column-group.avatar-group .login-avatar-wrapper {
  margin: .2rem;
}

.row .column .column-group.avatar-group .login-avatar-wrapper .login-avatar-label {
  max-width: 4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  font-size: .75rem;
  line-height: 1rem;
  overflow: hidden;
}

.row .column .column-group.avatar-group .f-icn {
  margin: 0;
  padding: 0;
  display: block;
  transform: translateY(1rem);
}

.avatar-group-row {
  border: none;
}

.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group {
  padding-left: 0;
  padding-right: 0;
}

.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group .f-icn {
  display: none !important;
}

.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group .login-avatar-wrapper:first-child {
  z-index: 1;
  align-items: center;
  position: relative;
}

.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group .login-avatar-wrapper:first-child .login-avatar-label {
  text-align: center;
  width: 4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  overflow: hidden;
}

.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group .login-avatar-wrapper:last-child {
  align-items: center;
}

.avatar-group-row:not(.with-arrow) .column .column-group.avatar-group .login-avatar-wrapper:last-child .login-avatar-label {
  text-align: center;
  width: 4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  white-space: inherit;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

.progressbar {
  appearance: none;
  width: 90%;
  height: 4vh;
  margin: 0 auto;
}

.progressbar:first-child {
  border-left-width: 1px;
}

.progressbar:first-child::-webkit-progress-bar, .progressbar:first-child::-webkit-progress-value {
  border-radius: .25em 0 0 .25em;
}

.progressbar:last-child::-webkit-progress-bar, .progressbar:last-child::-webkit-progress-value {
  border-radius: 0 .25em .25em 0;
}

.theme-light .progressbar::-webkit-progress-bar {
  background-color: #e6eaea;
}

.theme-dark .progressbar::-webkit-progress-bar {
  background-color: #40404c;
}

.theme-colour-blue .progressbar::-webkit-progress-value {
  background-color: #5a96ff;
}

.theme-colour-green .progressbar::-webkit-progress-value {
  background-color: #5af0be;
}

.theme-colour-red .progressbar::-webkit-progress-value {
  background-color: #ff556e;
}

.theme-colour-orange .progressbar::-webkit-progress-value {
  background-color: #ff7d5a;
}

.theme-colour-yellow .progressbar::-webkit-progress-value {
  background-color: #ffeb46;
}

.theme-colour-blue .progressbar::-webkit-progress-value {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .progressbar::-webkit-progress-value {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .progressbar::-webkit-progress-value {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .progressbar::-webkit-progress-value {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .progressbar::-webkit-progress-value {
  color: #505064;
  background-color: #ffeb46;
}

.progressbar + .progressbar {
  border-left: 1px solid #fff;
}

.theme-dark .progressbar + .progressbar {
  border-color: #30303c;
}

.row-nav {
  display: flex;
}

@media screen and (min-width: 769px) {
  .row-nav {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .row-nav {
    flex-direction: row;
    align-items: center;
  }
}

.row-nav__left {
  text-align: left;
  order: 1;
  padding-right: 1rem;
}

.row-nav__right {
  text-align: right;
  order: 2;
  padding-right: 1rem;
}

@media screen and (max-width: 768px) {
  .row-nav__right {
    order: 3;
  }
}

.row-nav__link {
  flex: 1;
  align-items: flex-start;
  font-size: 1.3125rem;
}

.theme-colour-blue .row-nav__link {
  color: #5a96ff;
}

.theme-colour-green .row-nav__link {
  color: #5af0be;
}

.theme-colour-red .row-nav__link {
  color: #ff556e;
}

.theme-colour-orange .row-nav__link {
  color: #ff7d5a;
}

.theme-colour-yellow .row-nav__link {
  color: #ffeb46;
}

@media (max-width: 640px) {
  .row-nav__link {
    font-size: 1.0625rem;
  }
}

.row.mergeWithNextRow {
  border-bottom: none;
}

.row.mergeWithNextRow > .column > .column-group {
  margin-bottom: 0;
  padding-bottom: 0;
}

.row .hidden {
  opacity: 0;
}

.profile-banner {
  width: 95%;
  overflow: none;
  justify-content: center;
}

.profile-banner__container {
  width: 100%;
  color: #f8fafa;
  background-color: #40404c;
  background-position: 50%;
  background-size: cover;
  border-radius: 10px;
  flex-direction: row;
  margin: auto;
  display: flex;
}

.profile-banner__text {
  flex-direction: column;
  flex-basis: 60%;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
}

.profile-banner__header {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
}

.profile-banner__detail {
  font-size: 1.1rem;
}

.input {
  z-index: 4;
  height: 3.875rem;
  box-sizing: border-box;
  flex-basis: 3.875rem;
  padding: .5625rem 1rem;
  animation: .3s .1s forwards fadeInSlideUp;
  position: sticky;
  top: 5.5625rem;
}

.theme-light .login-list-page .input {
  background-color: #e0e4e4 !important;
}

.theme-dark .login-list-page .input {
  background-color: #202030 !important;
}

@media screen and (max-width: 768px) {
  .input {
    top: 3.75rem;
  }
}

.input {
  flex-direction: row;
  flex-grow: 0;
  display: flex;
}

.input .help-link {
  margin-left: .75rem;
}

#pane-2 .input {
  padding-top: .3125rem;
}

.row > .column > .column-group > .input {
  flex-grow: 1;
}

input.input-box {
  height: 2.875rem;
}

.textarea {
  z-index: 4;
  box-sizing: border-box;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 12.375rem;
  padding: .5625rem 1rem;
  animation: .3s .1s forwards fadeInSlideUp;
  display: flex;
  position: sticky;
  top: 5.5625rem;
}

.theme-light .login-list-page .textarea {
  background-color: #e0e4e4 !important;
}

.theme-dark .login-list-page .textarea {
  background-color: #202030 !important;
}

@media screen and (max-width: 768px) {
  .textarea {
    top: 3.75rem;
  }
}

.textarea .help-link {
  margin-left: .75rem;
}

#pane-2 .textarea {
  padding-top: .3125rem;
}

textarea.input-box {
  height: 12.375rem;
}

.input-box {
  box-sizing: border-box;
  text-indent: 1rem;
  border: 1px solid #e0e4e4;
  padding-left: 0;
  padding-right: .75rem;
  line-height: 2.875rem;
  transition: text-indent .2s, background-position .3s .1s, background-color .3s .1s;
}

.input-box:focus {
  transition: text-indent .1s, background-position .1s;
  box-shadow: inset 0 0 0 1pt #c5cccc;
}

.popup-menu {
  z-index: 2000;
  color: #fff;
  min-height: 100%;
  min-width: 100%;
  background-color: #202030cc;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 0;
}

.popup-menu__item {
  width: 10rem;
  background-color: #202030;
  border: 1px solid;
  margin: 0 auto 1rem;
  padding: 1rem;
}

.popup-menu__item:hover {
  background-color: #505064;
}

.popup-menu__item:last-child {
  margin-bottom: 6rem;
}

.row.lochy {
  margin: auto;
}

.row.lochy .column {
  padding: 0;
}

.row.lochy .column img {
  object-fit: contain;
  object-position: center;
  height: 35vh;
  width: 100%;
  border-radius: 25px;
  margin: auto;
}

.row.lochy .column img.small {
  height: 25vh;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid #0000;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

.slick-prev, .slick-next {
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-next:hover, .slick-prev:focus, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-next:hover:before, .slick-prev:focus:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  color: #fff;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted .slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  height: 20px;
  width: 20px;
  color: #0000;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  width: 20px;
  height: 20px;
  text-align: center;
  color: #000;
  opacity: .25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: .75;
}

.slick-custom__dots {
  text-align: center;
  flex-grow: 1;
  order: 2;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.slick-custom__dots li {
  height: .625rem;
  width: .625rem;
  cursor: pointer;
  margin-left: .3125rem;
  margin-right: .3125rem;
  padding: 0;
  transition: width .35s;
  display: inline-block;
  position: relative;
}

.slick-custom__dots li button {
  width: 100%;
  height: 100%;
  color: #0000;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-custom__dots li button:hover, .slick-custom__dots li button:focus {
  outline: none;
}

.slick-custom__dots li button:hover:before {
  opacity: 1;
}

.slick-custom__dots li button:before {
  content: "";
  width: 100%;
  height: 100%;
  text-align: center;
  color: #505064;
  opacity: .25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #505064;
  border-radius: 999px;
  font-size: 1rem;
  line-height: 1rem;
  transition: background-color .35s;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-custom__dots li.slick-active {
  width: 1.875rem;
}

.slick-custom__dots li.slick-active button:before {
  opacity: 1;
  background-color: #f8fafa;
}

.slick-nav-bar {
  justify-content: space-around;
  display: flex;
}

.slick-arrow {
  opacity: 1;
  transition: opacity .35s;
}

.slick-arrow.nav-right {
  order: 3;
}

.slick-arrow.nav-left {
  order: 1;
}

.slick-arrow.slick-disabled {
  opacity: 0;
}

.slick-track {
  transition: transform .5s;
}

.import-item {
  cursor: pointer;
  box-sizing: border-box;
  list-style-type: none;
  position: relative;
}

@media (hover: hover) {
  .import-item:hover .import-item-inner {
    background-color: #202030;
  }

  .import-item:hover .button-group {
    opacity: 1;
  }
}

.import-item-inner {
  box-sizing: border-box;
  will-change: background-color;
  background-color: #f8fafa;
  border-radius: 999px 0 0 999px;
  flex-direction: row;
  padding-left: .4375rem;
  transition: background-color .1s ease-in;
  display: flex;
}

.theme-dark .import-item-inner {
  background-color: #202030;
}

.import-item-inner .icn-button {
  border-bottom: .0625rem solid var(--border-colour);
}

.import-avatar-wrapper {
  flex: 0 0 2.875rem;
  padding: .4375rem 0;
}

@media (min-width: 1025px) {
  .import-avatar-wrapper {
    flex: 0 0 3.75rem;
  }
}

.import-overview-wrapper {
  box-sizing: border-box;
  min-width: 0;
  border-bottom: .0625rem solid var(--border-colour);
  flex-direction: row;
  flex: auto;
  display: flex;
}

.import-overview-wrapper .import-overview {
  min-width: 0;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  padding: .375rem 0 .625rem .625rem;
  display: flex;
}

@media (min-width: 1025px) {
  .import-overview-wrapper .import-overview {
    padding-top: .4375rem;
    padding-bottom: .75rem;
  }
}

.import-overview-wrapper .import-name, .import-overview-wrapper .import-username {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  margin: 0;
  overflow: hidden;
}

.import-overview-wrapper .import-name {
  margin-bottom: -1px;
  font-family: Nunito;
}

@media (min-width: 1025px) {
  .import-overview-wrapper .import-name {
    margin-bottom: -.25rem;
    font-size: 1.1875rem;
    line-height: 1.625rem;
  }

  .import-overview-wrapper .import-username {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.import-overview-wrapper .import-overview, .import-overview-wrapper .button-group {
  padding-left: .625rem;
}

.import-overview-wrapper .button-group {
  opacity: 0;
  flex: none;
  padding-right: .625rem;
  transition: opacity .2s;
}

.import-overview-wrapper .button-group-inner {
  height: 100%;
  display: table;
}

.import-overview-wrapper .button-group-inner .icn-button {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}

.import-overview-wrapper .button-group-inner .icn-button .f-icn {
  display: inline-block;
  position: relative;
  top: auto;
  right: auto;
}

@media (min-width: 1025px) {
  .import-overview-wrapper .button-group-inner .icn-button .f-icn {
    width: 2.5rem;
    height: 2.5rem;
    background-size: 2.5rem 2.5rem;
    line-height: 2.5rem;
  }
}

.import-avatar-wrapper {
  margin-right: .4375rem;
  position: relative;
}

.import-avatar-inner {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.import-avatar-inner, .import-avatar {
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 50%;
}

@media (min-width: 1025px) {
  .import-avatar-inner, .import-avatar {
    width: 3.75rem;
    height: 3.75rem;
  }
}

.import-avatar {
  object-fit: cover;
  background-color: #fff;
}

.avatar-heading .import-avatar {
  transition: all .3s;
  transform: scale(1);
}

.avatar-heading .login-avatar-inner:hover .import-avatar {
  transform: scale(1.2);
}

.profile-badges.row > .column .column-group {
  flex-wrap: wrap;
  padding-top: .6875rem;
  display: flex;
}

.profile-badges.row > .column .column-group .column {
  min-width: 16.6667%;
  max-width: 16.6667%;
  flex-grow: 1;
  margin: 1rem 0;
  padding: 0;
  font-size: .9rem;
}

@media screen and (max-width: 460px), screen and (min-width: 921px) and (max-width: 1193px), screen and (min-width: 1194px) and (max-width: 1580px) {
  .profile-badges.row > .column .column-group .column {
    min-width: 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 350px), screen and (min-width: 769px) and (max-width: 920px) {
  .profile-badges.row > .column .column-group .column {
    min-width: 25%;
    max-width: 25%;
  }
}

@media screen and (max-width: 290px) {
  .profile-badges.row > .column .column-group .column {
    min-width: 33.3333%;
    max-width: 33.3333%;
  }
}

.profile-badges.row > .column .column-group .column .badge {
  width: 4rem;
  height: 4rem;
  transform-origin: 50%;
  transition: opacity .35s, transform .2s;
  transform: scale(1);
}

@media (min-width: 1025px) {
  .profile-badges.row > .column .column-group .column .badge {
    width: 5rem;
    height: 5rem;
  }
}

@media (hover: hover) {
  .profile-badges.row > .column .column-group .column .badge:hover {
    opacity: 1;
    transform: scale(1.2);
  }
}

.badge-wrapper {
  min-width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge-wrapper .badge {
  width: 2.875rem;
  height: 2.875rem;
}

.badge-wrapper .badge.disabled {
  opacity: .35;
  -webkit-filter: grayscale();
  filter: grayscale();
}

.special-character-table {
  grid-gap: .0625rem;
  background: var(--border-colour);
  border-radius: .1875rem;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 3.75rem;
  margin-bottom: .625rem;
  display: grid;
  overflow: hidden;
}

.theme-dark .special-character-table {
  background: #40404c;
}

.special-character-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
  letter-spacing: .2em;
  letter-spacing: .1em;
  background: #f8fafa;
  justify-content: center;
  align-items: center;
  padding: .5rem .375rem;
  font-family: Ubuntu Mono, monospace;
  font-size: 1.375rem;
  line-height: 1.3;
  transition: background-color .2s;
  display: flex;
  overflow: hidden;
}

.theme-colour-blue .special-character-cell {
  color: #5a96ff;
}

.theme-colour-green .special-character-cell {
  color: #5af0be;
}

.theme-colour-red .special-character-cell {
  color: #ff556e;
}

.theme-colour-orange .special-character-cell {
  color: #ff7d5a;
}

.theme-colour-yellow .special-character-cell {
  color: #ffeb46;
}

.special-character-cell.disabled {
  pointer-events: none;
  color: #e0e4e4;
}

.theme-dark .special-character-cell.disabled {
  color: #40404c;
}

.theme-dark .special-character-cell {
  background: #202030;
}

.theme-colour-blue .special-character-cell[data-selected="true"] {
  background-color: #5a96ff;
}

.theme-colour-green .special-character-cell[data-selected="true"] {
  background-color: #5af0be;
}

.theme-colour-red .special-character-cell[data-selected="true"] {
  background-color: #ff556e;
}

.theme-colour-orange .special-character-cell[data-selected="true"] {
  background-color: #ff7d5a;
}

.theme-colour-yellow .special-character-cell[data-selected="true"] {
  background-color: #ffeb46;
}

.theme-colour-blue .special-character-cell[data-selected="true"] {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .special-character-cell[data-selected="true"] {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .special-character-cell[data-selected="true"] {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .special-character-cell[data-selected="true"] {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .special-character-cell[data-selected="true"] {
  color: #505064;
  background-color: #ffeb46;
}

.special-character-cell[data-selected="true"].disabled {
  color: #e0e4e4;
  background: #f8fafa;
}

.theme-dark .special-character-cell[data-selected="true"].disabled {
  color: #40404c;
  background: #202030;
}

.special-character-cell:hover {
  background: #e6eaea;
}

.theme-colour-blue .special-character-cell:hover[data-selected="true"] {
  background-color: #5a96ff;
}

.theme-colour-green .special-character-cell:hover[data-selected="true"] {
  background-color: #5af0be;
}

.theme-colour-red .special-character-cell:hover[data-selected="true"] {
  background-color: #ff556e;
}

.theme-colour-orange .special-character-cell:hover[data-selected="true"] {
  background-color: #ff7d5a;
}

.theme-colour-yellow .special-character-cell:hover[data-selected="true"] {
  background-color: #ffeb46;
}

.theme-colour-blue .special-character-cell:hover[data-selected="true"] {
  color: #f8fafa;
  background-color: #5a96ff;
}

.theme-colour-green .special-character-cell:hover[data-selected="true"] {
  color: #505064;
  background-color: #5af0be;
}

.theme-colour-red .special-character-cell:hover[data-selected="true"] {
  color: #f8fafa;
  background-color: #ff556e;
}

.theme-colour-orange .special-character-cell:hover[data-selected="true"] {
  color: #f8fafa;
  background-color: #ff7d5a;
}

.theme-colour-yellow .special-character-cell:hover[data-selected="true"] {
  color: #505064;
  background-color: #ffeb46;
}

.theme-dark .special-character-cell:hover {
  color: #f8fafa;
  background: #40404c;
}

.special-character-cell:nth-child(1) {
  border-top-left-radius: .1875rem;
}

.special-character-cell:nth-child(6) {
  border-top-right-radius: .1875rem;
}

.special-character-cell:nth-child(6n+1):nth-last-child(-n+6) {
  border-bottom-left-radius: .1875rem;
}

.special-character-cell:nth-child(6n):nth-last-child(-n+6) {
  border-bottom-right-radius: .1875rem;
}

.special-character-cell:nth-child(-n+6) {
  border-top: .0625rem solid var(--border-colour);
}

.special-character-cell:nth-child(-n+6):hover {
  border-top-color: #e6eaea;
}

.theme-dark .special-character-cell:nth-child(-n+6):hover {
  border-top-color: #40404c;
}

.special-character-cell:nth-last-child(-n+6) {
  border-bottom: .0625rem solid var(--border-colour);
}

.special-character-cell:nth-last-child(-n+6):hover {
  border-bottom-color: #e6eaea;
}

.theme-dark .special-character-cell:nth-last-child(-n+6):hover {
  border-bottom-color: #40404c;
}

.special-character-cell:nth-child(6n+1) {
  border-left: .0625rem solid var(--border-colour);
}

.special-character-cell:nth-child(6n+1):hover {
  border-left-color: #e6eaea;
}

.theme-dark .special-character-cell:nth-child(6n+1):hover {
  border-left-color: #40404c;
}

.special-character-cell:nth-child(6n) {
  border-right: .0625rem solid var(--border-colour);
}

.special-character-cell:nth-child(6n):hover {
  border-right-color: #e6eaea;
}

.theme-dark .special-character-cell:nth-child(6n):hover {
  border-right-color: #40404c;
}

.transition-collapsible-row {
  max-height: 5rem;
  transition: max-height .8s;
}

#symbolsWrapper.transition-collapsible-row, #wordsCustomLengthWrapper.transition-collapsible-row, #letterNumberSymbolCustomLengthWrapper.transition-collapsible-row {
  display: block;
}

#symbolsWrapper.transition-collapsible-row {
  max-height: 25rem;
}

.transition-collapsible-row.collapsed {
  overflow: hidden;
  max-height: 0 !important;
}

.no-border + .transition-collapsible-row.collapsed {
  max-height: .0625rem !important;
}

/*# sourceMappingURL=index.6c161de7.css.map */
