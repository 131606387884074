/* This is how you would create a custom secondary theme on top of tooltipster-noir: */
@tooltipBackgroundColour: #e0e4e4;

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info {
  .tooltipster-box {
    background: @tooltipBackgroundColour;
    .tooltipster-content {
      color: black;
      font-size: @14px;
      line-height: @19px;
      padding: @15px @30px;
      white-space: pre-wrap;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info.tooltipster-top .tooltipster-arrow-border {
  border-top-color: @tooltipBackgroundColour;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info.tooltipster-right .tooltipster-arrow-border {
  border-right-color: @tooltipBackgroundColour;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-borderless-info.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: @tooltipBackgroundColour;
}
