.doodlepad__overlay {
  .px(max-width,302px);
  .px(min-width,231px);
  margin: 1px auto;
  width: 37vh;
  overflow: hidden;
  position: absolute;

  &.disabled {
    .px(height, 302px);
    .px(min-height, 231px);
  }
}
.doodlepad {
  .px(max-width,302px);
  .px(min-width,231px);
  margin: 1px auto;
  width: 37vh;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 0;
  // border-width: 2px;
  // border-style: solid;
  // border-color: var(--border-colour);
  border-radius: 19.04761905%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  .doodlepad__svg {
    touch-action: none;
    cursor: pointer;
    g.dpad-dots {
      .circle-outer-wrapper {
        transform-origin: 50% 50%;
        animation: 500ms ease transitionScaleUp;
        animation-fill-mode: both;
        //Apply increasing animation delay up to a specific nth-child
        .delay(@nthChild; @increment; @index: 1) when (@index =< @nthChild) {
          .delay(@nthChild; @increment; (@index + 1));
          &:nth-child(@{index}) {
            animation-delay: 300ms + @increment * @index;
          }
        }
        .delay(9,25ms); //Up to nth-child(9)
      }
      .circle-inner-wrapper {
        animation: 1000ms ease transitionFadeIn;
        animation-fill-mode: both;
        //Apply increasing animation delay up to a specific nth-child
        .delay(@nthChild; @increment; @index: 9) when (@index =< @nthChild) {
          .delay(@nthChild; @increment; (@index + 1));
          &:nth-child(@{index}) {
            animation-delay: 300ms + @increment + @increment * @index;
          }
        }
        .delay(18,25ms); //Up to nth-child(9)
      }
      circle {
        stroke: transparent;
        stroke-width: 1;
        .theme-light & {
          fill: darken(@grey-3, 3%);
        }
        .theme-dark & {
          fill: @grey-8;
        }
      }
      .dpad-text {
        opacity: 1;
        fill: grey;
        text-anchor: middle;
        pointer-events: none;
        transition: opacity 0.3s;
        .theme-light & {
          fill: @white-1;
        }
        .theme-dark & {
          fill: @grey-1;
        }
      }
    }
    g.dpad-lines {
      circle {
        animation: 500ms both transitionFadeIn;
        transform-origin: 50% 50%;
        pointer-events: none;
        fill: var(--theme-colour);
      }
      .dpad-line {
        opacity: 0.6;
        line {
          opacity: 1;
          stroke-width: 8;
          stroke-linecap: round;
          animation: 200ms linear transitionLineIn;
          pointer-events: none;
          transition: opacity 0.3s;
          stroke: var(--theme-colour);
        }
      }
      .dpad-lineGroup {
        transition: opacity 0.4s linear;
      }
    }
    &.lines-hidden g.dpad-lines {
      opacity: 0;
      transition: opacity 0.3s;
    }
    &.numbers-hidden g.dpad-dots .dpad-text {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}
.circle-animation {
  animation: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) both circleBlip;
  .theme-light & {
    stroke: darken(@grey-3, 3%) !important;
  }
  .theme-dark & {
    stroke: @grey-8 !important;
  }
}
.transition-opacity-0 {
  opacity: 0 !important;
  transition: opacity 0.3s;
}
// =================================================================================================

@keyframes transitionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transitionScaleUp {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes transitionLineIn {
  0% {
    opacity: 0;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
    stroke-dasharray: 50% 0;
  }
}
@keyframes circleBlip {
  0%,
  100% {
    stroke-width: 0;
  }
  50% {
    stroke-width: 4;
  }
}

//This is the doodlepad pane
#doodle-pad,
#moveService_fromDoodle,
#moveService_toDoodle {
  @media screen and (max-height: 640px) {
    .avatar-group-row {
      + .primary-text {
        padding-top: 0;
      }
      .avatar-group {
        margin-top: 0;
        margin-bottom: 0;
        .login-avatar {
          .px(width,40px);
          .px(height,40px);
        }
      }
    }
    .doodlepad {
      .px(min-width,200px);
      .px(max-width,200px);
      width: 100%;
    }
    .doodlepad-buttons {
      flex-grow: 1;
      .icn-button-lrg .f-icn {
        .px(width,60px);
        .px(height,60px);
      }
    }
  }
  .text-button {
    width: 22%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
