.input {
  position: sticky;
  z-index: 4;
  top: @89px;
  height: @62px;
  flex-basis: @62px;
  padding: @9px @hori-margin;
  box-sizing: border-box;
  animation: fadeInSlideUp 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 100ms forwards;
  .theme-light .login-list-page & {
    background-color: @grey-3 !important;
  }
  .theme-dark .login-list-page & {
    background-color: @grey-5 !important;
  }
  @media @mobile {
    top: @60px;
  }
}

.input {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  .help-link {
    margin-left: @12px;
  }
  #pane-2 & {
    padding-top: @5px;
  }
}

/* Search field in a column */
.row > .column > .column-group > .input {
  flex-grow: 1;
}

input.input-box {
  height: @46px;
}

.textarea {
  position: sticky;
  z-index: 4;
  top: @89px;
  flex-basis: @198px;
  flex-grow: 1;
  padding: @9px @hori-margin;
  box-sizing: border-box;
  animation: fadeInSlideUp 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 100ms forwards;
  .theme-light .login-list-page & {
    background-color: @grey-3 !important;
  }
  .theme-dark .login-list-page & {
    background-color: @grey-5 !important;
  }
  @media @mobile {
    top: @60px;
  }

  display: flex;
  flex-direction: row;
  .help-link {
    margin-left: @12px;
  }
  #pane-2 & {
    padding-top: @5px;
  }
}
textarea.input-box {
  height: @198px
}


.input-box {
  box-sizing: border-box;
  padding-left: 0;
  padding-right: @12px;

  line-height: @46px;
  text-indent: @16px;
  transition: text-indent 200ms ease, background-position 300ms ease 100ms, background-color 300ms ease 100ms;
  border: solid 1px @grey-3;
  // We are using the "required" attribute on the input field to determine if the field is empty
  // for the purposes of hiding the magnifying glass if the field has a value in it
  // background-position-x: calc(100% - @12px);
  &:focus {
    box-shadow: inset 0 0 0 1pt darken(@grey-3, 10%);
    transition: text-indent 100ms ease, background-position 100ms ease;
  }
}
