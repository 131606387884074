.nav-bar--overlay-active {
  &.theme-light {
    background-color: #fff;
  }
  #body-inner {
    @media @mobile {
      .px(padding-bottom, 60px);
    }
  }
}
.nav-bar__wrapper {
  body:not(.menu-open) & {
    z-index: 499;
  }
  @media @mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    body:not(.nav-bar--overlay-active) {
      .px(height, 60px);
    }
  }
  @media @desktop {
    position: fixed;
    bottom: 0;
    right: calc((100% - 74px) * 0.55);
    left: 74px;
  }
  @media @defaultNav {
    right: calc((100% - var(--nav-width)) * 0.55);
    left: var(--nav-width);
  }
  @media (min-width: @desktopXLargeHoriBreak) {
    right: calc((100% - var(--nav-width)) * 0.5);
  }
  @maxBodyWidthBreak: @maxBodyWidth + 1px;
  @media screen and (min-width: @maxBodyWidthBreak) {
    right: auto;
    width: calc((@maxBodyWidth - var(--nav-width)) * 0.5);
  }
  &.nav-bar__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: auto;
    .nav-bar {
      @media @desktop {
        right: auto;
        width: calc(((100% - 74px) * 0.45) + 74px);
      }
      @media @defaultNav {
        width: calc(((100% - var(--nav-width)) * 0.45) + var(--nav-width));
      }
      @media (min-width: @desktopXLargeHoriBreak) {
        width: calc(((100% - var(--nav-width)) * 0.5) + var(--nav-width));
      }
      @media screen and (min-width: @maxBodyWidthBreak) {
        width: calc(((@maxBodyWidth - var(--nav-width)) * 0.5) + var(--nav-width));
      }
    }
  }
  .nav-bar__overlay__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 200ms ease;
    //z-index: 3;
    pointer-events: none;
    .nav-bar__overlay& {
      opacity: 1;
      .theme-light & {
        background-color: fade(@white-1, 95%);
      }
      .theme-dark & {
        background-color: fade(@grey-5, 95%);
      }
    }
  }
}
.nav-bar {
  left: 0;
  position: relative;
  bottom: 0;
  // height: @75px;
  right: 0;
  width: 100%;
  background-color: transparent;
  @media @mobile {
    .px(height, 60px);
  }
  @media @desktop {
    position: absolute;
    width: @110px;
    height: 0;
    left: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    .nav-bar__home,
    .nav-bar__groups,
    .nav-bar__healthcheck,
    .nav-bar__profile {
      display: none;
    }
  }
  @media @mobile {
    &[activecontext='login-list'] > .nav-bar__popup {
      .nav-bar__popup__item {
        &[data-template='joinGroup'],
        &[data-template='newGroup'],
        &[data-actionclick='newGroupGotoPremium'] {
          display: none;
        }
        &[data-actionclick='beginAddNewPIN'] {
          order: 3;
          transition-delay: 60ms;
        }
        &[data-actionclick='beginAddNewWord'] {
          order: 4;
          transition-delay: 90ms;
        }
        &[data-actionclick='beginAddNewService'] {
          order: 5;
          transition-delay: 120ms;
        }
      }
    }
    &[activecontext='groups-list'] > .nav-bar__popup {
      .nav-bar__popup__item {
        &[data-actionclick='beginAddNewService'],
        &[data-actionclick='beginAddNewPIN'],
        &[data-actionclick='beginAddNewWord'] {
          display: none;
        }
        &[data-template='joinGroup'] {
          order: 1;
          transition-delay: 60ms;
        }
        &[data-template='newGroup'],
        &[data-actionclick='newGroupGotoPremium'] {
          order: 2;
          transition-delay: 90ms;
        }
      }
    }
  }
  /*&[activecontext="healthcheck_welcome"],
  &[activecontext="profile-landing-page"] {
    > .nav-bar__popup  {
      .nav-bar__popup__item {
        &[data-actionclick="beginAddNewService"],
        &[data-actionclick="beginAddNewPIN"],
        &[data-actionclick="beginAddNewWord"] {
          display: none;
        }
      }
    }
  }*/
  //in light mode we want a light border around the navbar
  .theme-light & {
    background-color: @white-1;
    border-top: 1px solid lighten(@grey-1, 50%);
  }
  //except when we popup the navbar
  .nav-bar__overlay & {
    border: none;
    width: 100%;
    height: auto;
    // @media @desktop {
    justify-content: flex-end;
    flex-wrap: wrap;
    // }
    .theme-light & {
      background-color: transparent;
    }
  }
  .nav-bar__popup {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //margin-left: 5rem;
    //align-items: center;
    //transform: translateX(calc(-50% + @55px/2));
    opacity: 0;
    @media @desktop {
      padding-right: @hori-margin*1.5;
      padding-bottom: @80px + @hori-margin*1.75*2;
    }
    @media @mobile {
      position: fixed;
      //.px(height,60px);
      .px(bottom, -60px);
      right: 0;
      left: 0;
    }
    &.active {
      pointer-events: all;
      bottom: 0;
      //-webkit-animation: bounce-in-bottom 0.1s  both;
      //animation: bounce-in-bottom 0.1s  both;
      //animation-delay: 0.0s;
      //z-index: 1;
      opacity: 1;
      @media @mobile {
        flex-direction: column;
        justify-content: flex-end;
        //transform: translateY(50%);
        transform: translateY(-100px);
        align-items: center;
      }
      @media screen and (max-width: 380px), screen and (max-width: @mobileBreak) and (max-height: 450px) {
        transform: translateY(-80px);
      }
      [data-actionclick] {
        //-webkit-animation: bounce-in-bottom 0.1s  both;
        //-webkit-animation-delay: 0.1s;
        //animation: bounce-in-bottom 0.1s  both;
        //animation-delay: 0.1s;
        //z-index: 2;
      }
    }

    .nav-bar__popup__item {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: percentage(1/3);
      //margin: @5px;
      position: relative;
      opacity: 0;
      transition: transform 200ms ease~ ',' opacity 200ms ease 20ms;
      //@media @desktop {
      transform: none;
      margin: 0;
      .px(padding;5px;0);
      transform: translate(-20px, 0);
      cursor: pointer;
      border-radius: 0 999px 999px 0;
      .nav-bar__overlay & {
        transform: translate(0, 0);
      }
      &[data-template='joinGroup'] {
        order: 1;
        transition-delay: 60ms;
      }
      &[data-template='newGroup'],
      &[data-actionclick='newGroupGotoPremium'] {
        order: 2;
        transition-delay: 90ms;
      }
      &[data-actionclick='beginAddNewPIN'] {
        order: 3;
        transition-delay: 120ms;
      }
      &[data-actionclick='beginAddNewWord'] {
        order: 4;
        transition-delay: 150ms;
      }
      &[data-actionclick='beginAddNewService'] {
        order: 5;
        transition-delay: 180ms;
      }

      @media @mobile {
        transition: transform 200ms ease~ ',' opacity 200ms ease 20ms~ ',' background 350ms ease;
        flex-direction: row;
        .px(padding;5px;10px;7px;24px);
        margin-top: 10px;
        border-radius: 999px;
        .theme-light & {
          background-color: darken(@white-1, 2%);
        }
        .theme-dark & {
          background-color: darken(@grey-5, 2%);
        }
        .f-icn {
          width: 1rem;
          height: 1rem;
          background-size: 1.75rem 1.75rem;
          line-height: 1rem;
        }
      }
      @media screen and (min-width: @desktopBreak) and (max-height: 500px) {
        .f-icn {
          width: 1.2rem;
          height: 1.2rem;
          background-size: 1.75rem 1.75rem;
          line-height: 1rem;
        }
      }
      //}
      // @media @mobile {
      //   flex-direction: column;
      //   .px(min-width,120px);
      //   .px(max-width,120px);
      //   &[data-template="joinGroup"] {
      //     order: 1;
      //     //transform: translate(120px,0);
      //     transition-delay: 30ms;
      //     .nav-bar__overlay & {
      //       //transform: translate(0,-200%);
      //     }
      //   }
      //   &[data-actionclick="beginAddNewPIN"] {
      //     order: 4;
      //     //position: absolute;
      //     //transform: translate(0,0);
      //     transition-delay: 120ms;
      //     .nav-bar__overlay & {
      //       //transform: translate(0,-275%);
      //     }
      //   }
      //   &[data-actionclick="beginAddNewWord"] {
      //     order: 5;
      //     //position: absolute;
      //     //transform: translate(0,0);
      //     transition-delay: 150ms;
      //     min-width: max-content;
      //     .nav-bar__overlay & {
      //       //transform: translate(0,-400%);
      //     }

      //   }
      //   &[data-actionclick="beginAddNewService"] {
      //     order: 2;
      //     //transform: translate(0,0);
      //     transition-delay: 60ms;
      //     .nav-bar__overlay & {
      //       //transform: translate(0,-200%);
      //     }
      //   }
      //   &[data-template="newGroup"],
      //   &[data-actionclick="newGroupGotoPremium"] {
      //     order: 3;
      //     //transform: translate(-120px,0);
      //     transition-delay: 90ms;
      //     .nav-bar__overlay & {
      //       //transform: translate(0,-200%);
      //     }
      //   }
      // }
      @media screen and (max-width: 380px), screen and (max-width: @mobileBreak) and (max-height: 450px) {
        //.px(min-width,100px);
        //.px(max-width,100px);
        .px(padding;2px;6px;4px;20px);
        .f-icn {
          background-size: 1.5rem 1.5rem;
        }
      }
      &:hover {
        .theme-light & {
          background-color: darken(@white-1, 10%);
        }
        .theme-dark & {
          background-color: darken(@grey-5, 10%);
        }
        .nav-bar__popup__icon {
          .theme-light & {
            background-color: darken(@white-1, 10%);
          }
          .theme-dark & {
            background-color: darken(@grey-5, 10%);
          }
        }
      }
      .nav-bar__overlay & {
        opacity: 1;
      }
      .nav-bar__popup__icon {
        cursor: pointer;
        padding: 0.5rem;
        margin: 0.5rem;
        margin: 0rem;
        flex-grow: 0;
        border-radius: 50%;
        transition: background 350ms ease;
        //@media @desktop {
        transform: none;
        order: 2;
        //}
        @media @mobile {
          background: none !important;
        }
        .theme-light & {
          background-color: darken(@white-1, 2%);
        }
        .theme-dark & {
          background-color: darken(@grey-5, 2%);
        }
        .f-icn-sharing {
          margin-top: 0px;
        }
      }
      .nav-bar__popup__text {
        .px(font-size, 20px);
        transform: none;
        white-space: nowrap;
        order: 1;
        flex-grow: 1;
        text-align: center;
        @media @desktop {
          padding-right: @hori-margin;
          text-align: right;
        }
        @media screen and (max-width: 380px), screen and (max-width: @mobileBreak) and (max-height: 450px) {
          .px(font-size, 16px);
        }
        .theme-dark & {
          color: @white-1;
        }
        .theme-light & {
          color: @grey-5;
        }
      }
    }
    //Fan effect
    /*
    .nav-bar__popup__icon {
      cursor: pointer;
      padding: 0.5rem;
      //order: 1;
      margin: 0.5rem;
      margin: 0rem;
      flex-grow: 0;
      border-radius: 50%;
      transform:  translateX(calc(-@55px / 2));
      @media @desktop {
        transform: none;
        order: 2;
      }
      .theme-light & {
        background-color: darken( @white-1 ,2%);
      }
      .theme-dark & {
        background-color: darken( @grey-5 ,2%);
      }
      .f-icn-sharing {
        margin-top: 0px;
      }
    }
    .nav-bar__popup__item:last-of-type {
      margin-bottom: 1rem;
      flex-grow: 0;
    }
    .nav-bar__popup__text {
      transform:  translateX(calc(-@48px / 2));
      //margin: 0.5rem;
      @media @desktop {
        transform: none;
        white-space: nowrap;
        order: 1;
        flex-grow: 1;
        padding-right: @hori-margin;
        text-align: right;
      }
      .theme-dark & {
        color:  @white-1;
      }
      .theme-light & {
        color: @grey-5;
      }
    }*/
  }
  .nav-bar__button-group {
    @media @desktop {
      //position: absolute;
      //width: 0;
      //left: auto;
      width: 100%;
      height: 0;
      display: flex;
      background: none !important;
    }
    &.popup {
      background-color: transparent !important;
    }
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    //border-top: solid 1px @grey-2;
    //padding: @8px 0;
    //@media @desktop {
    //width: 100%;
    //height: @100px;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: flex-start;
    //}
  }
  li {
    position: relative;
    display: block;
    padding-bottom: @4px;
    opacity: 1 !important;
    text-align: center;
    color: @white-1;
    cursor: pointer;
    flex-grow: 1;
    flex-basis: percentage(1/5);
    //width: @64px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: opacity 0.2s ease;
      opacity: 0;
      //background-color: darken(@grey-1, 8%);
      //border-radius: @8px;
      .theme-colours(color);
    }
    &.active {
      &:before {
        .theme-colours(color);
      }
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
    button {
      margin: 0 auto;
      display: block;
    }
    .icn-button-name {
      display: block;
      color: @grey-2;
      font-size: @9px;
      position: relative;
      top: -@3px;
    }
  }
  .f-icn-home {
    .background-image-light(home);
  }
  .nav-bar__home {
    .nav-bar__overlay & {
      pointer-events: none;
      opacity: 0.1 !important;
    }
  
    &.active {
      .icn-button-name {
        .theme-colours(color);
      }
      .f-icn-home {
        .background-image-colour(home);
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .icn-button-name {
          .theme-colours(color);
        }
        .f-icn-home {
          .background-image-colour(home);
        }
      }

    }
  }
  .f-icn-sharing {
    .background-image-light(sharing);
  }
  .nav-bar__popup__icon .f-icn-sharing {
    .background-image(sharing);
  }
  .nav-bar__groups {
    .nav-bar__overlay & {
      pointer-events: none;
      opacity: 0.1 !important;
    }
    &.active {
      .icn-button-name {
        .theme-colours(color);
      }
      .f-icn-sharing {
        .background-image-colour(sharing);
      }
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .icn-button-name {
          .theme-colours(color);
        }
        .f-icn-sharing {
          .background-image-colour(sharing);
        }
      }
    }
  }
  .f-icn-healthcheck {
    .background-image-light(healthcheck);
  }
  .nav-bar__healthcheck {
    .nav-bar__overlay & {
      pointer-events: none;
      opacity: 0.1 !important;
    }
    &.active {
      .icn-button-name {
        .theme-colours(color);
      }
      .f-icn-healthcheck {
        .background-image-colour(healthcheck);
      }
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .icn-button-name {
          .theme-colours(color);
        }
        .f-icn-healthcheck {
          .background-image-colour(healthcheck);
        }
      }
    }
    
  }
  .f-icn-profile {
    .background-image-light(profile);
  }
  .nav-bar__profile {
    .nav-bar__overlay & {
      pointer-events: none;
      opacity: 0.1 !important;
    }
    &.active {
      .icn-button-name {
        .theme-colours(color);
      }
      .f-icn-profile {
        .background-image-colour(profile);
      }
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .icn-button-name {
          .theme-colours(color);
        }
        .f-icn-profile {
          .background-image-colour(profile);
        }

      }
    }
    
  }
  #nav-bar-add {
    position: relative;
    //bottom: @16px;
    .px(width, 50px);
    .px(height, 50px);
    //margin-bottom: -20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 1px solid;
    //background-color:  var(--theme-colour);
    border-radius: 999px;
    @media @desktop {
      .px(height, 80px);
      .px(width, 80px);
      margin: 0;
      position: absolute;
      right: @hori-margin*1.5;
      bottom: @hori-margin*1.75;
      transition: transform 350ms ease;
      transform: scale(1);
    }
    &:before {
      display: none;
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: none;
        @media (min-width: @desktopBreak) {
          transform: scale(1.25);
        }
      }
      &:before {
        opacity: 0;
      }
    }
    button {
      .px(width, 50px);
      .px(height, 50px);
      transition: transform 200ms ease;
      border-radius: 999px;
      @media @desktop {
        .px(height, 80px);
        .px(width, 80px);
      }
      .nav-bar__overlay & {
        transform: rotate(45deg);
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--theme-colour);
        border-radius: 999px;
      }
      .f-icn-add {
        position: relative;
        .background-image-white(add);
        height: 2rem;
        width: 2rem;
        .theme-dark & {
          .theme-colour-green&,
          .theme-colour-yellow& {
            background-image: url('../img/icons/colour-1/forghetti-ui_add_normal.svg');
          }
        }
        @media @desktop {
          width: @36px;
          height: @36px;
          background-size: contain;
        }
      }
    }
  }
}
@-webkit-keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
