// Styles for all of the onboarding pages (incl. Doodle Tutorial pages)
/*[id^="onboarding-"].section-pane {
  .primary-heading {
    font-size: @24px;
    line-height: @32px;
  }
  .third-heading {
    max-height: 1.4rem;
  }
  .column.info-text {
    padding: 0;
  }
  .nav-link.nav-right,
  .column.text-button:not(.cta-button) {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-right: 0.5rem;
    text-align: right;
  }
  transition: transform 100ms cubic-bezier(0.62, 0.02, 0.34, 1) 50ms;
  &.left {
    transform: translateX(-100%);
    transition: transform 200ms cubic-bezier(0.62, 0.02, 0.93, 0.82) 50ms;
  }
}*/

@onboardingMobile: ~'screen and (max-height: 829px)';
@onboardingDesktop: ~'screen and (min-height: 830px) and (min-width: 481px)';

// Styles for all of the onboarding pages (incl. Doodle Tutorial pages)
.onboarding {
  &.no-title {
    .section-pane-header {
      display: none;
    }
  }


  .table-view {
    .px(padding-left,25px);
    .px(padding-right,25px);
    .carousel& {
      padding-left: 0;
      padding-right: 0;
    }
  
  }
  .column {
    padding: 0;
  }

  .primary-heading {
    padding-top: 0;
    .px(max-width,320px);
  }
  .align-left .primary-heading {
    align-self: flex-start;
  }

  .column.info-text {
    padding: 0;
    line-height: normal;
    .theme-dark & {
      color: @white-1;
    }
  }
  .onboarding-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    z-index: 200;
    .f-icn-add {
      transform: rotateZ(45deg);
      z-index: 200;
    }
  }
  // Styling for images displayed in onboarding pages
  .onboarding-image {
    flex-grow: 1;
    flex-basis: 60%;
    display: flex;
    align-items: center;
    //.px(padding-bottom,10px);
    img,
    svg {
      display: block;
      width: 100%;
      max-width: 75%;
      height: auto;
      max-height: 35vh;
      margin: 0 auto;
      object-fit: contain;
      object-position: center;
      #onboarding_stage2& {
        max-width: 68%;
      }
      #onboarding_existingUser& {
        max-width: 100%;
        .px(max-height,314px);
        @media @onboardingMobile {
          max-width: 90%;
          max-height: 35vh;
        }
      }
    }
    > .column {
      .px(padding-top,10px);
      .px(padding-bottom,10px);
    }
  }

    // Styling for images displayed in onboarding pages
    .banner-image {
      //flex-grow: 1;
      flex-basis: 60%;
      display: flex;
      align-items: center;
      //.px(padding-bottom,10px);
      img,
      svg {
        display: block;
        width: 100%;
       
        height: auto;
        max-height: 35vh;
        margin: 0 auto;
        object-fit: contain;
        object-position: center;
        #onboarding_stage2& {
          max-width: 68%;
        }
        #onboarding_existingUser& {
          max-width: 100%;
          .px(max-height,314px);
          @media @onboardingMobile {
            max-width: 90%;
            max-height: 35vh;
          }
        }
      }
      > .column {
        .px(padding-top,10px);
        .px(padding-bottom,10px);
      }
    }



  #onboarding_stage1& .slick-slide:first-child .onboarding-image img {
    max-width: 56%;
  }
  .onboarding-text {
    flex-basis: 100% - 70%;

    display: flex;
    flex-direction: column;
    .px(min-height,150px);
    //.px(padding-bottom,12px);
    #onboarding_stage2& {
      .px(min-height,50px);
    }
    + .has-cta-button {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 100% - 90%;
      // flex-basis: auto;
      // min-height: auto;
    }

    > .column {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      > .primary-heading {
        flex-grow: 0;
      }
      > .column-group {
        flex-grow: 1;
        flex-direction: column;
      }
    }
  }
  .section-pane-inner {
    position: relative;
    height: 100%;
    .table-view {
      .px(padding-top,20px);
      //.px(padding-bottom,25px);
      > .slick-arrows {
        position: absolute;
        top: 0;
        .px(right,-50px);
        bottom: 0;
        .px(left,-50px);
        display: flex;
        align-items: center;
        .slick-arrow {
          flex-grow: 1;
          flex-basis: 50%;
          display: flex;
          align-items: center;
          &.slick-arrow-prev {
            justify-content: flex-start;
          }
          &.slick-arrow-next {
            justify-content: flex-end;
          }
        }
      }
      > .row > .column:not(.justify-content-start) {
        justify-content: center;
      }
    }
  }
  .slick-slider {
    flex-grow: 1;
    display: flex;
    .slick-list {
      flex-grow: 1;
      display: flex;
      .slick-track {
        flex-grow: 1;
        display: flex;
        .slick-slide {
          flex-grow: 1;
          display: flex;
          outline: none;
          > div {
            flex-grow: 1;
            display: flex;
            > div {
              flex-grow: 1;
              display: flex !important;
              flex-direction: column;
              .px(padding-right,25px);
              .px(padding-bottom,25px);
              .px(padding-left,25px);
              .onboarding-text > .column {
                //justify-content: center;
                .px(padding-top,10px);
              }
            }
          }
        }
      }
    }
  }
  .has-cta-button {
    &:first-child:not(:last-child) {
      align-items: flex-end;
    }
  }
  .watch-again {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .px(margin-top,30px);
  }
  .suggested-presets {
  }
  label {
    background-color: transparent !important;
  }
}

// Styles for all of the "Doodle Tutorial" pages
[id^='onboarding-doodle'].section-pane {
  .row > .column > .primary-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .row > .column .secondary-heading {
    text-align: center;
  }
  .column.text-button {
    text-align: center !important;
  }
}

// Make the title for this page fill the screen
/*#onboarding-doodletutorial1 {
  .row > .column > .primary-heading {
    height: 70vh;
  }
}*/

.onboard__list {
  padding-left: 2rem;
  opacity: 0.6;

  &.checked {
    opacity: 1;
  }
  .f-icn-check-circle {
    .background-image-colour(check-circle);
  }
}

// Styling for legacy onboarding pages
.onboarding .table-view .centered-text .column-group {
  padding-bottom: 0px;
}
#create-account-onboarding-5 .primary-text-content {
  white-space: normal;
}

.overlay {
  &.theme-dark {
    background: @grey-9;
  }
  //Onboarding centered
  > .section-pane-wrapper > .section-pane {
    display: flex;
    align-items: center;
    @media @onboardingDesktop {
      background: @grey-9;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    .section-pane-inner {
      @media @onboardingDesktop {
        .px(max-height,700px);
        .px(min-height,700px);
        border-radius: 1rem;
        background: @grey-5;
      }
    }
  }
}

//Onboarding centered
/*#onboarding_stage1,
#onboarding-doodletutorial1,
#onboarding_stage2,
#addService_search-onboarding,
#add-service-group-onboarding,
#addService_username-onboarding,
#doodle-pad-onboarding,
#addServicePassword_loading-onboarding,
#onboard_complete {
  .section-pane-inner > .table-view {
    .px(padding-top,20px);
    .px(padding-bottom,25px);
  }
}*/

//Verify Mobile Number
#verify-device-1 {
  .row {
    border: none !important;
  }
}

//Onboarding Stage 1
#onboarding_stage1 .section-pane-inner {
  .slick-slide {
    //Second slide
    &[data-slick-index='1'] {
      .onboarding-image img {
        max-width: 65%; //Make it a bit smaller
      }
    }
  }
}

//Onboarding Stage 1
#onboarding-doodletutorial1 {
  .section-pane-header {
    display: none;
  }
  .table-view > .row:nth-of-type(2) {
    > .column:first-child {
      flex-grow: 1;
      .primary-heading {
        height: auto;
      }
      .column-group {
        display: none;
      }
    }
  }
}

//Onboarding Stage 2
#onboarding_stage2 {
  .section-pane-inner {
    height: 100%;
  }
  .table-view {
    > .third-heading {
      flex-grow: 1;
      display: flex;
      .column {
        flex-grow: 1;
        align-items: center;
      }
    }
    > .slick-slider {
      flex-basis: 75%;
    }
  }
  .onboarding-image {
    img,
    svg {
      max-width: 68%;
    }
  }
  .onboarding-text {
    .px(min-height,50px);
    .primary-heading {
      padding-top: 0;
      .px(padding-bottom,15px);
    }
  }
  .watch-again {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .px(margin-top,30px);
  }
}

//Adding first service
#addService_search-onboarding {
  @media @onboardingMobile {
    align-items: flex-start;
  }
  .section-pane-inner {
    overflow: auto;
    &::-webkit-scrollbar-button {
      display: none;
    }
    /*position: relative;
    background: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 1rem;
      background: @grey-5;
    }*/
    .section-pane-header {
      display: none;
    }
    > .table-view {
      position: relative;
      //Reset the padding
      .column:not(.icn-button):not(.text-group),
      .search {
        padding-left: 0;
        padding-right: 0;
      }
      .search {
        position: relative;
        top: auto;
        height: auto;
        .px(padding-bottom,20px);
        .search-input {
          .px(height,60px);
          &:focus {
            box-shadow: inset 0 0 0 1pt @grey-11;
            color: @white-1;
          }
        }
        .search-input-clear {
          .px(width,60px);
          .px(height,60px);
          .px(top,9px);
          right: 0;
        }
      }
      > section:nth-of-type(1) {
        .column-group {
          .px(padding-top,10px);
          .px(padding-bottom,20px);
        }
        .info-text {
          .h2;
          color: @white-1;
        }
      }
      .suggested-add-button {
        .px(padding-left,12px);
        .px(padding-right,12px);
        padding-left: 0;
        padding-right: 0;

        .column > .column-group > .icn-button {
          background: var(--theme-colour);

          .f-icn-add {
            background-image: url('../img/icons/colour-1/forghetti-ui_add_normal.svg');
          }
        }

        &:hover > .column {
          background: none !important;
          > .column-group {
            .primary-text-content {
              text-decoration: underline;
            }
          }
        }
        > .column > .column-group > .icn-button:first-child {
          margin: 0;
        }
      }
    }
  }
}

//Choose group to add to
#add-service-group-onboarding {
  @media @onboardingMobile {
    align-items: flex-start;
  }
  .section-pane-inner {
    .section-pane-header {
      display: none;
    }
    > .table-view {
      //Reset the padding
      .column:not(.icn-button):not(.text-group) {
        padding-left: 0;
        padding-right: 0;
      }
      > section:nth-of-type(1) {
        border: 0;
        .px(padding-bottom,40px);
      }
      > section:nth-of-type(2),
      > section:nth-of-type(3),
      > section:nth-of-type(4),
      > section:nth-of-type(5) {
        .column-group {
          .px(padding-top,10px);
          .px(padding-bottom,20px);
          .login-avatar-wrapper {
            margin: 0;
            min-width: 25%;
            max-width: 25%;
            flex-basis: 25%;
            flex-shrink: 0;
            .login-avatar-label {
              .truncate;
            }
          }
        }
      }
      > section:nth-of-type(n + 2) {
        .px(margin-left,-16px);
        .px(margin-right,-16px);
      }
    }
  }
}

//Choose group to add to
#addService_username-onboarding {
  @media @onboardingMobile {
    align-items: flex-start;
  }
  .section-pane-inner {
    .section-pane-header {
      display: none;
    }
    > .table-view {
      overflow: hidden;
      //Reset the padding
      .column:not(.icn-button):not(.text-group) {
        padding-left: 0;
        padding-right: 0;
      }
      > section:nth-of-type(1) {
        .px(padding-bottom,40px);
        .input {
          padding-left: 0;
          padding-right: 0;
          height: auto;
          &.focussed .input-box {
            color: @white-1;
          }
          .input-box {
            .px(height,60px);
            background: @grey-8;
            border: 0;
            .px(text-indent,16px);
            &:focus {
              box-shadow: inset 0 0 0 1pt @grey-11;
              color: @white-1;
            }
          }
        }
      }
    }
  }
  //[data-actionclick="addService_submitUsername"] {
  //  transition: opacity 350ms ease;
  //  opacity: 0;
  //  &.active {
  //    opacity: 1;
  //  }
  //}
  [data-actionclick='addService_submitUsername'],
  [data-actionclick='addService_submitUsername'] .f-icn {
    position: relative;
    margin: 0;
    transform: none !important;
  }
}

//Select an icon
#webSearchForServiceIcon.onboarding {
  @media @onboardingMobile {
    align-items: flex-start;
  }
  .section-pane-inner {
    > .table-view {
      justify-content: flex-start;
      //Reset the padding
      .column:not(.icn-button):not(.text-group) {
        padding-left: 0;
        padding-right: 0;
      }
      > section:nth-of-type(1) {
        .column-group {
          .px(padding-top,10px);
          .px(padding-bottom,20px);
        }
        .info-text {
          .h1;
          color: @white-1;
          .px(font-size,28px);
        }
      }
      .search-result-row {
        + .search-result-row .column-group {
          padding-top: 0;
        }
        .column-group {
          flex-grow: 1;
          min-width: 0;
          .px(max-width,320px);
          .login-avatar-wrapper {
            flex-grow: 1;
            margin: 0;
          }
        }
      }
    }
  }
}

//Doodlepad first try
#doodle-pad-onboarding,
#doodle-pad-onboarding-confirm {
  .section-pane-inner {
    .section-pane-header {
      display: none;
    }
    > .table-view {
      justify-content: center;
      //Reset the padding
      .column:not(.icn-button):not(.text-group) {
        padding-left: 0;
        padding-right: 0;
      }
      > section:nth-of-type(1) {
        //flex-grow: 1;
        display: flex;
        align-items: center;
        .column-group {
          .px(padding-top,5px);
        }
        .info-text {
          .h2;
          color: @white-1;
        }
      }
      > section:nth-of-type(3) {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
      > section:nth-of-type(5) {
        flex-grow: 1;
        .column-group {
          padding: 0;
        }
      }
      > section:last-of-type {
        .button-group {
          padding: 0;
          @media @onboardingDesktop {
          }
        }
      }
      .avatar-group {
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        //margin-top: 0;
        .login-avatar {
          border: 0;
        }
        .login-avatar-label {
          .px(margin-top,5px);
        }
        .f-icn {
          transform: none;
        }
      }
    }
  }
}

//First Service Overview
#view-service-onboarding {
  display: flex;
  align-items: center;
  @media @onboardingDesktop {
    background: @grey-9;
  }
  @media @onboardingMobile {
    align-items: flex-start;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  .section-pane-inner {
    .section-pane-header .section-pane-nav {
      flex-wrap: wrap;
      .nav-link {
        min-width: 50%;
        box-sizing: border-box;
        &.nav-left {
          order: 1;
        }
        &.nav-right {
          order: 2;
        }
      }
      .section-pane-nav__title {
        order: 3;
      }
    }
    > .table-view {
      .px(padding-top,20px);
      .avatar-group {
        margin: 0 auto;
        padding: 0;
        .login-avatar {
          border: 0;
        }
        .login-avatar-label {
          .px(margin-top,5px);
        }
      }
      .info-text {
        line-height: normal;
      }
      > section:nth-of-type(1) {
        flex-grow: 1;
        display: flex;
        align-items: center;
        width: 100%;
        &.avatar-group-row {
          flex-grow: 0;
        }
      }
      > section:nth-of-type(5) {
        flex-grow: 1;
        display: flex;
        align-items: center;
        transform-origin: 50% 50%;
        animation: 500ms ease transitionScaleUp;
        animation-fill-mode: both;
        animation-delay: 800ms;
        > .column {
          flex-grow: 1;
          box-sizing: border-box;
          min-width: 100%;
          .info-text {
            .px(font-size,20px);
          }
        }
      }
    }
  }
}

//Onboarding Complete
#onboard_complete {
  @media @onboardingMobile {
    align-items: flex-start;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  .section-pane-inner {
    .section-pane-header {
      display: none;
    }
    > .table-view {
      //.px(padding-top,20px);
      //Reset the padding
      .column:not(.icn-button):not(.text-group) {
        padding-left: 0;
        padding-right: 0;
      }
      .info-text {
        line-height: normal;
      }
      > section:nth-of-type(1) {
        border: 0;
        > .column > .column-group {
          .px(padding-bottom,24px);
        }
        .text-group .primary-heading {
          text-align: left;
          line-height: 1.2;
          max-width: 100%;
          @media screen and (max-width: 370px) {
            .px(font-size,24px);
          }
        }
      }
      .onboard__list {
        .px(padding-left,5px);
        @media screen and (max-width: 370px) {
          padding-left: 0;
        }
        &.checked .info-text {
          color: @white-1;
          .theme-light & {
            color: @grey-1;
          }
          .theme-dark & {
            color: @white-1;
          }
        }
        .column-group {
          .px(padding-top,12px);
          .px(padding-bottom,12px);
          .info-text {
            .px(font-size,20px);
            .px(padding-left,5px);
            @media screen and (max-width: 370px) {
              .px(font-size,18px);
            }
          }
        }
      }
      > .primary-text {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: 50% 50%;
        animation: 500ms ease transitionScaleUp;
        animation-fill-mode: both;
        animation-delay: 800ms;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            display: block;
          }
        }
      }
      > section:last-of-type {
        flex-grow: 0;
        display: flex;
        align-items: flex-end;
        > .column {
          flex-grow: 1;
          box-sizing: border-box;
          min-width: 100%;
        }
      }
    }
  }
}
