// Styling for images displayed in onboarding pages
.row.lochy {
  margin: auto;
  .column {
    padding: 0px;
    img {
      border-radius: 25px;
      object-fit: contain;
      object-position: center;
      margin: auto;

      height: 35vh;
      width: 100%;
    }
    img.small {
      height: 25vh;
    }
  }
}
/*[data-lottie-animation] {
  height: 35vh;
  object-fit: contain;
  object-position: center;
  margin: auto;
  width: 100%;

}*/
