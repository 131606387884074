.image-uploader {
	display: none;
	
	&__header {
		display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
	}
	
	&__main {
		background-color: white;
		box-shadow: 0px 0px 40px rgba(0,0,0,0.4);
		width: 400px;
		display:flex;
		flex-direction: column;
		align-items:center;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		background-color: rgba(255,255,255,0.7);
	}
	
	&__container,
	&__container.croppie-container {
		width: 300px;
		height: 340px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__image {
		width: 300px;
		height: 300px;
	}
}