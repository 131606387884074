@keyframes fadeInSlideUp {
  0% {
      opacity: 0;
      transform: translateY(10px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeInSlideDown {
  0% {
      opacity: 0;
      transform: translateY(-10px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes fadeInSlideDownAlert {
  0% {
      opacity: 0;
      transform: translateY(-10px) translateX(-50%);
  }
  10% {
      opacity: 1;
      transform: translateY(0) translateX(-50%);
  }
  90% {
      opacity: 1;
      transform: translateY(0) translateX(-50%);
  }
  100% {
      opacity: 0;
      transform: translateY(-10px) translateX(-50%);
  }
}
@keyframes transitionFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transitionLineIn {
  0% {
    stroke-dashoffset: 25%;
    stroke-dasharray: 0% 50%;
    stroke-width: 5;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 50% 0%;
    stroke-width: 5;
  }
}
@keyframes circleBlip {
  0% {
    stroke-width: 0;
  }
  50% {
    stroke-width: 2;
  }
  100% {
    stroke-width: 0;
  }
}
@keyframes revealPassword {
  0% {
    //min-width: 0;
    .px(height,27px);
  }
  50% {
    //.px(min-width,21px);
    .px(height,27px);
  }
  100% {
    //.px(min-width,21px);   
    .px(height,52px);
  }
}